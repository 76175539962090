import {connect} from 'react-redux'

import Transferencias from '../../components/TransferirServicios/Transferencias'

const mapStateToProps = ({transferencias, login}) => {
    return {
        transferencias,
        login
    }
}

export default connect(mapStateToProps)(Transferencias)
