import React, { Component } from "react";
import ListaSanitizaciones from "./ListaSanitizaciones";
import FiltrosBusquedaSanitizaciones from "./FiltrosBusquedaSanitizaciones";
import { Container } from "semantic-ui-react";

class Sanitizaciones extends Component {

  render() {
    const {
      clientes, unidades, sanitizaciones, requestClientes, requestUnidades, requestSanitizaciones, invalidarSanitizaciones,
    } = this.props;

    return (
        <Container fluid style={{ padding: "10px" }}>
            <FiltrosBusquedaSanitizaciones
                clientes={clientes}
                unidades={unidades}
                requestClientes={requestClientes}
                requestUnidades={requestUnidades}
                requestSanitizaciones={requestSanitizaciones}
                invalidarSanitizaciones={invalidarSanitizaciones}
            />
            <ListaSanitizaciones
                sanitizaciones={sanitizaciones}
            />
      </Container>
    );
  }
}

export default Sanitizaciones;
