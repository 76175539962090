import React, { Component } from "react";
import { Segment, Form, Select, Button, Message } from "semantic-ui-react";
import axios from "axios";

import { ENDPOINT_CLIENTES, ENDPOINT_ENCUESTA_OPERADORES } from '../../constants/endpoints'

class ActivarEncuesta extends Component {
    constructor(props){
        super(props)

        this.initialValues = ({
            loading: false,
            encuestaActivada: false,
            encuestaDesactivada: false,
            idCliente: null,
            nombreCliente: ''

        })

        this.state = ({
            ...this.initialValues
        })
    }

    componentDidMount(){
        const url = `${ENDPOINT_CLIENTES}?activo=true&id_categoria=1&or(id=5)&sort(+nombre_empresa)`;

        this.setState({
            loading: true
        })

        return(
            axios.get(url)
                .then((response) => {
                    if(response.status === 200){
                        const clientes = response.data.map(cliente => {
                            return { key: cliente.data.id, text: cliente.data.attributes.nombreEmpresa, value:  cliente.data.id }
                        })

                        return this.setState({
                            clientes: clientes,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        )
    }

    obtener_array = (array, id) => {
        for (var n in array) {
          if(id === array[n].value){ 
            return (
                this.setState({
                    nombreCliente: array[n].text
                })
            )
        }
      }
    }

    handleChangeValue = (e, data) => {
        this.obtener_array(this.state.clientes, data.value)
        this.setState({ [data.name]: data.value})
    }

    activarEncuesta = () => {
        const id_cliente = this.state.idCliente
        
        if(id_cliente){

            const url = `${ENDPOINT_ENCUESTA_OPERADORES}?activo=1&modulo=ENCUESTA_OPERADORES&plataforma=SERVICIOS&id_cliente=${id_cliente}`;

            this.setState({
                loading: true
            })

            return(
                axios.post(url)
                    .then((response) => {
                        if(response.status === 200){
                            return this.setState({
                                encuestaActivada: response.data.success,
                                loading: false
                            });
                            
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .then(() => {
                        setTimeout(() => {
                            this.setState({ ...this.initialValues});
                        }, 3000)
                    })
            )
        }
    }

    desactivarEncuesta = () => {
        const id_cliente = this.state.idCliente

        if(id_cliente){
            const url = `${ENDPOINT_ENCUESTA_OPERADORES}?activo=0&modulo=ENCUESTA_OPERADORES&plataforma=SERVICIOS&id_cliente=${id_cliente}`;

            this.setState({
                loading: true
            })

            return(
                axios.post(url)
                    .then((response) => {
                        if(response.status === 200){
                            return (
                                this.setState({
                                    encuestaDesactivada: response.data.success,
                                    loading: false
                                })
                            );
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .then(() => {
                        setTimeout(() => {
                            this.setState({ ...this.initialValues});
                          }, 3000)
                    })
            )
        }
    }

  render() {
    const clientes = this.state?.clientes;
    const { idCliente, encuestaActivada, encuestaDesactivada, 
        nombreCliente, loading } = this.state;

    return (
        <>
        <Segment>
            <h3>Activar o desactivar encuesta</h3>
            <Form inline="true">
                <Form.Group widths='equal' className='form-group-inputs'>
                    <Form.Field
                    control={Select}
                    options={clientes}
                    placeholder='Selecciona un cliente'
                    search
                    value={idCliente}
                    name='idCliente'
                    onChange={this.handleChangeValue}
                    loading={loading}
                    />
                </Form.Group>
                <Form.Group widths='equal' style={{justifyContent: 'flex-end'}}>
                    <Button className='form-button' color='orange' onClick={this.activarEncuesta}>Activar</Button>
                    <Button className='form-button' onClick={this.desactivarEncuesta}>Desactivar</Button>
                </Form.Group>
                </Form>
        </Segment>

        { encuestaActivada ?
            <Message info style={{textAlign: 'left'}}>
                <Message.Header>Encuesta activada para {nombreCliente} escaneando código QR</Message.Header>
            </Message>
        :<></>
        }

        { encuestaDesactivada ?
            <Message info style={{textAlign: 'left'}}>
                <Message.Header>Encuesta desactivada para {nombreCliente} escaneando código QR</Message.Header>
            </Message>
        :<></>
        }
        </>
    );
  }
}

export default ActivarEncuesta;