import React from "react";
import PropTypes from "prop-types";
import { validarStorage } from "../../helpers";
import { Table, Message, Icon, Item, Container, Segment, Form } from "semantic-ui-react";

// import {
//   Form,
//   Container,
//   Message,
//   Segment,
//   Item,
//   Label,
//   Icon,
// } from "semantic-ui-react";

import BaseComponent from "../BaseComponent";
//import FormaCargarHorariosRuta from "./FormaCargarPrecios";
import FormaCargarPrecios from "./FormaCargarPrecios";
class ActualizarPrecios extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.requestClientes([
      "activo=true",
      "id_categoria=1",
      "or(id=5)",
      "sort(+nombre_empresa)",
    ]);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.estructuras.received_date) {
      const horarios = nextProps.estructuras.all;
      const acc_horarios = {};

      horarios.forEach((item) => {
        acc_horarios[`${item.id}_0`] = item.pagoOperador
          ? item.pagoOperador
          : 0;
        acc_horarios[`${item.id}_1`] = item.pagoAliado ? item.pagoAliado : 0;
        acc_horarios[`${item.id}_2`] = item.precioCliente
          ? item.precioCliente
          : 0;
      });

      this.setState(acc_horarios);
    }
  }

  getFields = () => {
    const { estructuras , login} = this.props;
    const user = login.user;
    const permisos = validarStorage(user).permisos;

    return estructuras.all.map(function (item, idx) {

      return (
        <>
         {(permisos?.cambiarPrecios === true && permisos?.consultarPrecios === true )&&
          <Table celled  key={idx}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Ruta</Table.HeaderCell>
                <Table.HeaderCell>Pago Operador</Table.HeaderCell>
                <Table.HeaderCell>Pago Aliado</Table.HeaderCell>
                <Table.HeaderCell>Pago Cliente</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell><Icon color='orange' name="bus" /> {item.turno} - {item.tipo} </Table.Cell>
                <Table.Cell>{item.pagoOperador}</Table.Cell>
                <Table.Cell>{item.pagoAliado}</Table.Cell>
                <Table.Cell>{item.precioCliente}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        }
        {/* {(permisos.cambiarPrecios === false && permisos.consultarPrecios === false )&&
        <Form.Group inline key={idx} widths={12}>
          <Label color="orange" ribbon>
            {item.turno} - {item.tipo}
          </Label>{" "}
          <Icon name="bus" />
          <Form.Input
            fluid
            type={"number"}
            ribbon={"Operador"}
            step={"0.1"}
            name={`${item.id}_0`}
            value={this.state[`${item.id}_0`]}
            onChange={this.onChangeValue}
            label={'Pago Operador'}
          />
          <Form.Input
            fluid
            type={"number"}
            ribbon={"Aliado"}
            step={"0.1"}
            name={`${item.id}_1`}
            value={this.state[`${item.id}_1`]}
            onChange={this.onChangeValue}
            label={'Pago Aliado'}
          />
          <Form.Input
            fluid
            type={"number"}
            ribbon={"Cliente"}
            step={"0.1"}
            name={`${item.id}_2`}
            value={this.state[`${item.id}_2`]}
            onChange={this.onChangeValue}
             label={'Pago Cliente'}
          />
        </Form.Group>
        } */}
        </>
      );
    }, this);
  };

  updatePrecios = (ruta, desde, hasta, login) => {

    const precios = {};

    Object.keys(this.state).forEach(function (key, idx) {
      const idPrecio = key.split("_")[0];

      if (idPrecio in precios) {
        precios[idPrecio].push(parseFloat(this.state[key]));
      } else {
        precios[idPrecio] = [];
        precios[idPrecio].push(parseFloat(this.state[key]));
      }
    }, this);

    const params = {
      id_ruta: ruta,
      desde: desde,
      hasta: hasta,
      horarios: precios,
      user: login,
    };

    this.props.requestPreciosUpdate(params);

    setTimeout(() => {
      this.props.requestEstructuras([`id_ruta=${ruta}`, "activa=true"]);
    }, 500);
  };

  render() {
    const {
      clientes,
      rutas,
      estructuras: { all, fetching },
      horarios: { precios },
      login,
    } = this.props;

    const {
      invalidarRutas,
      requestRutas,
      requestEstructuras,
      invalidarEstructuras,
    } = this.props;

    const user = login.user;
    const permisos = validarStorage(user).permisos;

    return (
      <Container fluid style={{ padding: "10px" }}>
        <h3 style={{ textAlign: "left" }}>Precios</h3>

        <FormaCargarPrecios
          clientes={clientes}
          rutas={rutas}
          login={login}
          precios={precios}
          estructurasFetching={fetching}
          horariosFetching={precios.fetching}
          onInvalidarRutas={invalidarRutas}
          onRequestRutas={requestRutas}
          onRequestEstructuras={requestEstructuras}
          onUpdatePrecios={this.updatePrecios}
          onInvalidarEstructuras={invalidarEstructuras}
        />

        {precios.received_date ? (
          <Message positive>
            <Message.Header>
              Precios actualizados, se realizaron los siguientes cambios:
            </Message.Header>
            <Item.Group>
              {Object.keys(precios.all).map(function (key) {
                return (
                  <Item key={key}>
                    {key.replace("_", " ")} - {precios.all[key]} bitácoras
                  </Item>
                );
              })}
            </Item.Group>
          </Message>
        ) : (
          <Message positive style={{ display: "none" }}></Message>
        )}

            {all.length ? (
              <Segment container="true">
                <Form widths="equal">{this.getFields()}</Form>
                {(permisos?.cambiarPrecios === false && permisos?.consultarPrecios === false )&&
                  <Message info>
                  <Message.Header>
                    No cuenta con permisos para ver esta información
                  </Message.Header>
                  <p>Si requiere algun dato comuniquese con su supervisor</p>
                </Message>
                }
              </Segment>
            ) : (
              <Segment container="true" style={{ display: "none" }}></Segment>
            )}
      </Container>
    );
  }
}

ActualizarPrecios.propTypes = {
  estructuras: PropTypes.object.isRequired,
  clientes: PropTypes.object.isRequired,
  rutas: PropTypes.object.isRequired,
  horarios: PropTypes.object.isRequired,
  login: PropTypes.object.isRequired,
  requestClientes: PropTypes.func.isRequired,
  requestPreciosUpdate: PropTypes.func.isRequired,
  requestEstructuras: PropTypes.func.isRequired,
  invalidarEstructuras: PropTypes.func.isRequired,
  invalidarRutas: PropTypes.func.isRequired,
  requestRutas: PropTypes.func.isRequired,
};

export default ActualizarPrecios;
