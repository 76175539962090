import React, { Component } from "react";
import { Form, Select, Button, Message, TextArea } from "semantic-ui-react";
import axios from "axios";
import { ENDPOINT_CLIENTES, ENDPOINT_RUTAS, ENDPOINT_COLABORADORES, ENDPOINT_CONTACTOS_AGREGAR, ENDPOINT_BUSCAR_CONTACTOS } from '../../constants/endpoints'

class ContactoInterno extends Component {
    constructor(props){
        super(props)

        this.initialValues = ({
            loading: false,
            activo: 0,
            registrado: false,
            idCliente: null,
            idColaborador: null,
            idRuta: null,
            datosFaltantes: false,
            horario: '',
            orden: 0,
            puestoMensaje: '',
            cliente: [],
            ruta: [],
            colaboradorSeleccionado: '',
            error: ''
        })

        this.state = ({
            ...this.initialValues
        })
    }

    getClientes(){
        const url = `${ENDPOINT_CLIENTES}?activo=true&id_categoria=1&or(id=5)&sort(+nombre_empresa)`;

        this.setState({
            loading: true
        })

        return(
            axios.get(url)
                .then((response) => {
                    if(response.status === 200){
                        const cliente = response.data.map(cliente => {
                            return { key: cliente.data.id, text: cliente.data.attributes.nombreEmpresa, value: cliente.data.id }
                        })

                        return this.setState({
                            cliente: cliente,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
            })
        )
    }

    getRutas(valor){
        const url = `${ENDPOINT_RUTAS}?id_cliente=${valor}&sort(+nombre)&activa=true`;

        this.setState({
            loading: true
        })

        return(
            axios.get(url)
                .then((response) => {
                    if(response.status === 200){
                        const ruta = response.data.map(ruta => {
                            return { key: ruta.data.id, text: ruta.data.attributes.nombre, value: ruta.data.id }
                        })

                        return this.setState({
                            ruta: ruta,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
            })
        )
    }

    getColaboradores(){
        const url = `${ENDPOINT_COLABORADORES}`;

        this.setState({
            loading: true
        })

        return(
            axios.post(url)
                .then((response) => {
                    if(response.status === 200){

                        const colaborador = response.data.data.map(colaborador => {
                            return { key: colaborador.ID_USUARIO, text: colaborador.NOMBRE, value: colaborador.ID_USUARIO, puesto: colaborador.PUESTO }
                        })

                        return this.setState({
                            colaborador: colaborador,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        )
    }

    getContactos(cliente){
        const url = `${ENDPOINT_BUSCAR_CONTACTOS}?id_cliente=${cliente}`;

        return(
            axios.get(url)
                .then((response) => {
                    if(response.status === 200 && response.data.data){
                        return this.setState({
                            listaContactos: response.data.data,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        )
    }


    componentDidMount(){
        this.getClientes()
        this.getColaboradores()
    }

    handleChangeValue = (e, data) => {
        if(data.name === 'idColaborador'){
            let puesto = e.currentTarget.getAttribute('puesto')
            let colaboradorSeleccionado = e.target.innerText
            this.setState({ 'puestoMensaje': puesto, 'colaboradorSeleccionado': colaboradorSeleccionado})
        }
        
        if(data.name === 'idCliente'){
            this.getRutas(data.value)
            this.getContactos(data.value)
        }

        this.setState({ [data.name]: data.value })
    }

    onSubmit = () => {
        const { idCliente, idColaborador, horario, orden, idRuta } = this.state
        let url = ''

        if(!idColaborador || !idCliente || !orden){
            this.setState({datosFaltantes: true})
            return
        }else{
            this.setState({
                loading: true,
                datosFaltantes: false
            })

            let registrar = false
            for(let i = 0; i < this.state.listaContactos.length; i++){
                if(idColaborador !== this.state.listaContactos[i].ID_COLAB){
                    registrar = true
                }
                break;
            }

            if(registrar === true){
                console.log(registrar)
                if(idRuta){
                    url = `${ENDPOINT_CONTACTOS_AGREGAR}?id_colaborador=${idColaborador}&externo=${0}&id_cliente=${idCliente}&orden=${orden}&ruta=${idRuta}&horario=${horario}`;
                }else{
                    url = `${ENDPOINT_CONTACTOS_AGREGAR}?id_colaborador=${idColaborador}&externo=${0}&id_cliente=${idCliente}&orden=${orden}&horario=${horario}`;
                }
        
                return(
                    axios.post(url)
                        .then((response) => {
                            if(response.status === 200 && response.data.success === true){
                                return this.setState({
                                    registrado: response.data.success,
                                    loading: false,
                                });
                            }
    
                            if(response.status === 200 && response.data.success === false){
                                return this.setState({
                                    registrado: response.data.success,
                                    error: response.data.mensaje,
                                    loading: false,
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .then(() => {
                            setTimeout(() => {
                                this.resetState()
                                }, 3000)
                        })
                )
            }
        }    
    }
    

    resetState = () => {
        return (
            this.setState({ 
                ...this.initialValues,
            }),
            this.getClientes(),
            this.getColaboradores()
        )
        
    }

  render() {

    const { cliente, ruta, colaborador, registrado, idCliente, idRuta, orden,
        idColaborador, datosFaltantes, puestoMensaje, error } = this.state;
    
    return (
        <>
            { puestoMensaje ?
                <Message info size='small' color='orange'>
                    <Message.Header> 
                    <p>Puesto del colaborador seleccionado: {puestoMensaje}</p>
                    </Message.Header>
                </Message>
                :
                <></>
            }
            <Form>
                <Form.Group widths='equal' className='form-group-inputs'>
                    <Form.Field
                        label='Empresa a la que se asignara el contacto'
                        control={Select}
                        options={cliente}
                        placeholder='Cliente'
                        search
                        value={idCliente}
                        name='idCliente'
                        onChange={this.handleChangeValue}
                        loading={this.state.loading}
                    />
                    <Form.Field
                        label='Ruta a la que se asignara el contacto (opcional)'
                        control={Select}
                        options={ruta}
                        placeholder='Rutas'
                        search
                        value={idRuta}
                        name='idRuta'
                        onChange={this.handleChangeValue}
                        loading={this.state.loading}
                    />
                    <Form.Field
                        label='Colaborador'
                        control={Select}

                        options={colaborador}
                        placeholder='Colaborador'
                        search
                        value={idColaborador}
                        name='idColaborador'
                        onChange={this.handleChangeValue}
                        loading={this.state.loading}
                    />
                </Form.Group>
                <Form.Group widths='equal' className='form-group-inputs'>
                    <Form.Field>
                      <label>Horario y días que labora</label>
                      <TextArea
                        label="Ingresa el horario y días que labora"
                        name="horario"
                        rows={3}
                        onChange={this.handleChangeValue}
                      />
                    </Form.Field>
                    <Form.Input
                        fluid 
                        label='Prioridad de contacto' 
                        placeholder='Ingresa el número de prioridad' 
                        name='orden'
                        value={orden}
                        type='number'
                        required
                        onChange={this.handleChangeValue}
                    />
                </Form.Group>
                <Button loading={this.state.loading} className='form-button' color='orange' type="reset" onClick={this.onSubmit}>Guardar</Button>
                <Button loading={this.state.loading} className='form-button'  type="reset" onClick={this.resetState}>Limpiar</Button>
            </Form>

            { registrado === true && !error ?
                <Message positive>
                    <Message.Header> Contacto registrado correctamente </Message.Header>
                </Message>
                :
                <></>
            }

            { registrado === false && error ?
                <Message error>
                    <Message.Header> {error} </Message.Header>
                </Message>
                :
                <></>
            }

            { datosFaltantes && registrado === false ?
                <Message info>
                    <Message.Header> Datos faltantes, ingresa toda la información </Message.Header>
                </Message>
                :
                <></>
            }     
        </>
    );
  }
}

export default ContactoInterno;