import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from 'axios'
import {
  Segment,
  Icon,
  Container,
  Popup,
  Tab,
  Grid,
  Dimmer,
  Loader,
  Button,
  Message
} from "semantic-ui-react";

import FiltrosMonitorServicios from "./FiltrosMonitorServicios";
import MonitorListadoServicios from "./MonitorListadoServicios";
import InformacionMonitoreo from "./InformacionMonitoreo";

import Reloj from "../Reloj";
import IndicadoresMonitor from "./Indicadores";
import './monitorServicios.css'

import {ENDPOINT_SERVICIOS_POR_TERMINAR} from '../../constants/endpoints'
class MonitorServicios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: -1,
      active: true,
      loading: false,
      serviciosPorTerminar: []
    };
  }

  componentDidMount() {
    this.props.iniciarReloj();
    this.props.iniciarMonitor();

   // this.timerID = setTimeout(() => this.loading(), 12000); //2 segundos
   this.timerID = setTimeout(() => this.loading(), 18000); //18 segundos
  }

  componentWillMount() {
    this.props.requestClientes([
      "activo=true",
      "id_categoria=1",
      "or(id=5)",
      "sort(+nombre_empresa)",
    ]);

    this.props.requestProveedores([
      "activo=true",
      "id_categoria=8",
      "or(id=1)",
      "sort(+empresa)",
    ]);

    this.props.requestOperadores(["activo=true", "sort(+nombre)"])
  }

  componentWillUnmount() {
    this.props.detenerReloj();
    this.props.invalidarServicios();
    this.props.limpiarSeleccion();
    clearInterval(this.timerID);
  }

  getIcon(item) {
    const {
      posicionUnidades: { all },
    } = this.props;

    if (
      all &&
      all[item.itemIdWialon] !== undefined &&
      all[item.itemIdWialon] > 0 &&
      (item.estatus === "servicio-en-ruta" ||
        item.estatus === "servicio-por-comenzar")
    ) {
      return (
        <Popup trigger={<Icon name="arrow alternate circle right" inverted />}>
          {" "}
          {item.detalle} {item.nombreOperador}{" "}
        </Popup>
      );
    }

    if (item.idProveedor !== 1) {
      return (
        <Popup trigger={<Icon name="eye slash" inverted />}>
          {" "}
          {item.detalle} {item.nombreOperador}{" "}
        </Popup>
      );
    }

    if (!item.celularOperador) {
      return (
        <Popup trigger={<Icon name="meh outline" inverted />}>
          {" "}
          {item.detalle} {item.nombreOperador}{" "}
        </Popup>
      );
    }

    if (!item.confirmado && !item.terminado) {
      return (
        <Popup trigger={<Icon name="question" inverted />}>
          {" "}
          {item.detalle} {item.nombreOperador}{" "}
        </Popup>
      );
    }

    if (item.terminado) {
      return (
        <Popup trigger={<Icon name="clipboard outline" inverted />}>
          {" "}
          {item.detalle} {item.nombreOperador}{" "}
        </Popup>
      );
    }

    if (item.confirmado) {
      return (
        <Popup trigger={<Icon name="check" inverted />}>
          {" "}
          {item.detalle} {item.nombreOperador}{" "}
        </Popup>
      );
    }

    if (!item.confirmado) {
      return (
        <Popup trigger={<Icon name="times" inverted />}>
          {" "}
          {item.detalle} {item.nombreOperador}{" "}
        </Popup>
      );
    }
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleChange = (e, data) => this.setState(data);

  loading() {
    this.setState({
      active: false,
    });
  }

  serviciosPorFinalizar (){
     this.setState({
          loading: true
      })

      const url = `${ENDPOINT_SERVICIOS_POR_TERMINAR}`
      return(
            axios.get(url)
            .then((response) => {
                if(response.status === 200){
                    return this.setState({
                        serviciosPorTerminar: response.data.servicios,
                        loading: false
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    )
  }

  renderList = () => {
    const { serviciosPorTerminar } = this.state;
    const { posicionUnidades } = this.props;

    return (
              <MonitorListadoServicios
                  servicios={serviciosPorTerminar}
                  posicionUnidades={posicionUnidades.all}
                  idServicioCancelar={-1}
                  onClickCancelarServicio={() => {
                    console.log("Noop");
                  }}
                  onClickTransferencia={() => {
                    console.log("Noop");
                  }}
                />
        );
  }

  renderMessage = () => {

      return (
            <Message warning>
              <Message.Header>No hay servicios terminados con un rango de 10 min.</Message.Header>
                <Message.List>
                    <Message.Item>Por el momento no contamos con bitácoras que hayan terminado hace 10 min.</Message.Item>
                </Message.List>
            </Message>
          )
  }

  validateRender = () => {
    const { serviciosPorTerminar } = this.state;
    return serviciosPorTerminar.length === 0 ? this.renderMessage() :  this.renderList()
  }


  render() {
    const {
      servicios,
      serviciosSeleccionados,
      clientes,
      reloj,
      posicionUnidades,
    } = this.props;
    const { stats } = servicios;
    // const { activeIndex } = this.state;

    // Muestra los servicios filtrados o todos los servicios
    let rows = {};
    if (serviciosSeleccionados) {
      rows =
        serviciosSeleccionados.length > 0
          ? serviciosSeleccionados
          : servicios.all;
    }

    let confirmados = [];
    let sin_confirmar = [];
    let terminado_cancelado = [];
    let totalConfirmados = 0;
    let totalSinConfirmarSuma = 0;
    let totalSinConfirmarAliado = 0;
    let totalTerminados = 0;

    if (rows.length > 0) {
      confirmados = rows.filter(function (item) {
        if (item.confirmado === true && item.idProveedor === 1) {
          totalConfirmados += 1;
        }

        return item.confirmado === true && item.terminado === false; //confirmado: true
      });

      sin_confirmar = rows.filter(function (item) {
        if (item.confirmado === false && item.idProveedor === 1) {
          totalSinConfirmarSuma += 1;
        } else {
          totalSinConfirmarAliado += 1;
        }

        return item.confirmado === false && item.terminado === false; //confirmado: false
      });

      terminado_cancelado = rows.filter(function (item) {
        if (
          (item.confirmado === true && item.terminado === true) ||
          item.cancelado === true
        ) {
          totalTerminados += 1;
        }
        return (
          (item.confirmado === true && item.terminado === true) ||
          item.cancelado === true
        );
      });
    }

    /*const cancelados = rows.filter(function (item) {
            return item.cancelado === true //cancelado: true
        })*/
        //{ !this.serviciosPorFinalizar.servicios ? this.renderList():this.renderMessage()}
const panes = {
      'paneles':[
        {
          'menuItem': 'Sin confirmar y programados',
          render: () => (
            <MonitorListadoServicios
              servicios={sin_confirmar}
              posicionUnidades={posicionUnidades.all}
              idServicioCancelar={-1}
              onClickCancelarServicio={() => {
                console.log("Noop");
              }}
              onClickTransferencia={() => {
                console.log("Noop");
              }}
            />
          ),
        },
        {
          'menuItem': 'En ruta y confirmados',
          render: () => (
            <MonitorListadoServicios
              servicios={confirmados}
              posicionUnidades={posicionUnidades.all}
              idServicioCancelar={-1}
              onClickCancelarServicio={() => {
                console.log("Noop");
              }}
              onClickTransferencia={() => {
                console.log("Noop");
              }}
            />
          ),
        },
        {
          'menuItem': 'Terminados rango 10min',
          render: () => (
              <>
              <Grid columns={2}>
                <Grid.Row stretched verticalAlign='bottom'>
                  <Grid.Column width={8} style={{display: 'flex', justifyContent:'left', padding: '20px'}}>
                    <Button size='medium' loading={this.state.loading} content='Actualizar listado de servicios terminados' color='teal' onClick={() => this.serviciosPorFinalizar()}  />
                  </Grid.Column>
                  <Grid.Column  width={8} style={{display: 'flex', justifyContent:'right', padding: '20px'}}>
                        <Message info>
                          <Icon name="arrow left" />
                        Da click en el boton para mostrar el listado de servicios terminados con rango de 10 min
                        </Message>
                    </Grid.Column>
                  </Grid.Row>
              </Grid>

                { this.serviciosPorFinalizar && this.validateRender()}
              </>
           )
        },
        {
          'menuItem': 'Terminados y cancelados',
          render: () => (
            <MonitorListadoServicios
              servicios={terminado_cancelado}
              posicionUnidades={posicionUnidades.all}
              idServicioCancelar={-1}
              onClickCancelarServicio={() => {
                console.log("Noop");
              }}
              onClickTransferencia={() => {
                console.log("Noop");
              }}
            />
          ),
        },
        {
          'menuItem': 'Códigos de color',
          render: () => <InformacionMonitoreo />,
        }
      ],
      "grid": {
        "paneWidth": 16,
        "tabWidth": 3
      },
      "menu": {
        "attached": true,
        "tabular": true,
         "color": "orange",
         "pointing": true
      },
      "renderActiveOnly": true,
      "activeIndex": 0,
    }

    return (
      <Container fluid style={{ padding: "10px" }}>
          <Dimmer active={this.state.active} page inverted>
                <Loader>Cargando información ... </Loader>
          </Dimmer>
        <Grid padded columns="equal">
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={3}>
              <h3 style={{ textAlign: "left" }}> Monitoreo </h3>
            </Grid.Column>
            <Grid.Column
              textAlign="right"
              mobile={16}
              tablet={16}
              computer={13}
            >
              <Reloj tiempo={reloj.tiempo} horasDesde={1} horasHasta={1} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid padded columns="equal">
          <Grid.Row stretched>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <IndicadoresMonitor
                stats={stats}
                confirmados={totalConfirmados}
                sinConfirmarSuma={totalSinConfirmarSuma}
                terminados={totalTerminados}
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <FiltrosMonitorServicios
                servicios={servicios}
                clientes={clientes}
                onLimpiarSeleccion={this.props.limpiarSeleccion}
                onAgregarASeleccion={this.props.agregarASeleccion}
              />
            </Grid.Column>
          </Grid.Row>

          {rows.length > 0 && (
            <Grid.Row columns={1}>
              <Grid.Column only="mobile">
                <Segment loading={servicios.fetching && !servicios.all.length}>
                  <Tab
                    menu={{ color: "orange", pointing: true, attached: true ,  className: "wrapped"}}
                    panes={panes.paneles}
                    renderActiveOnly={panes.renderActiveOnly}
                    defaultActiveIndex={panes.activeIndex}
                    grid={panes.grid}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column only="tablet computer">
                <Segment loading={servicios.fetching && !servicios.all.length}>
                  <Tab
                    menu={{ color: "orange", pointing: true, attached: true ,  className: "wrapped"}}
                    panes={panes.paneles}
                    renderActiveOnly={panes.renderActiveOnly}
                    defaultActiveIndex={panes.activeIndex}
                    grid={panes.grid}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Container>
    );
  }
}

MonitorServicios.propTypes = {
  reloj: PropTypes.shape({
    tiempo: PropTypes.object.isRequired,
  }),
  invalidarServicios: PropTypes.func.isRequired,
  iniciarReloj: PropTypes.func.isRequired,
  iniciarMonitor: PropTypes.func.isRequired,
  servicios: PropTypes.object.isRequired,
  detenerReloj: PropTypes.func.isRequired,
  requestClientes: PropTypes.func.isRequired,
  limpiarSeleccion: PropTypes.func.isRequired,
  serviciosSeleccionados: PropTypes.array,
  clientes: PropTypes.object.isRequired,
  agregarASeleccion: PropTypes.func.isRequired,
  removerDeSeleccion: PropTypes.func.isRequired,
};

export default MonitorServicios;
