import React from 'react'
import {Button, Form, Grid, Segment, Message, Image} from 'semantic-ui-react'
import PropTypes from 'prop-types'

import logo from '../../styles/iconos/logo_suma_4.png'
import { tipoUsuario, idUsuarios } from '../../helpers'

const LoginForm = ({requestLogin, login: {user, fetching, error, received_date}, onEo}) => {

    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [usuario] = React.useState(sessionStorage.getItem('usuario') || null)
    
    React.useEffect(() => {
        if (user) {
            idUsuarios(tipoUsuario, user)
            sessionStorage.setItem('usuario', JSON.stringify(user))
        }
    })

    React.useEffect(() => {
        if (user || usuario) {
            onEo()
        }
    })

    const onSubmit = () => {
        const params = {
            email,
            password
        }

        requestLogin(params)
    }

    const onChangeEmail = event => setEmail(event.target.value)
    const onChangePassword = event => setPassword(event.target.value)

    return (
        <div className='login-form'>
            <style>{`
            body > div,
            body > div > div,
            body > div > div > div.login-form {
                height: 100%;
            }
            `}
            </style>
            <Grid columns={1} textAlign='center' style={{height: '100%'}} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <Grid.Row>
                        <Image src={logo} size='small' style={{margin: "0 35%"}}/>
                    </Grid.Row>
                    <Grid.Row>
                        <Form size='large'>
                            <Segment stacked>
                                <Form.Input name="email" fluid icon='user' iconPosition='left'
                                            placeholder='Correo electrónico'
                                            value={email} onChange={onChangeEmail}/>

                                <Form.Input name="password" fluid icon='lock' iconPosition='left' placeholder='Password'
                                            type='password' value={password} onChange={onChangePassword}/>

                                <Button loading={fetching} color={"orange"} fluid size='large' onClick={onSubmit}>
                                    Entrar
                                </Button>
                            </Segment>
                        </Form>
                        {
                            error && received_date &&
                            <Message negative>
                                <Message.Header>Acceso denegado ({error.status})</Message.Header>
                                {error.data.mensaje}
                            </Message>
                        }
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        </div>
    )
}

LoginForm.propTypes = {
    requestLogin: PropTypes.func.isRequired,
    login: PropTypes.object.isRequired,
    onEo: PropTypes.func.isRequired
}

export default LoginForm