import React, { Component } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import { Menu, Grid, Dropdown } from "semantic-ui-react";
import moment from "moment";
//import { tipoUsuario } from "../helpers";

import LoginForm from "./LoginForm/LoginFormContainer";
import MonitorServicios from "./MonitorServicios/MonitorServiciosContainer";
import ProgramacionServicios from "./ProgramacionServicios/ProgramacionServiciosContainer";
import TransferirServicios from "./TransferirServicios/TransferenciasContainer";
import VistaProgramados from "./VistaProgramaciones/VistaProgramacionesContainer";
import RegistroExcepciones from "../components/Excepciones/RegistroExcepciones";
import ListarBitacorasContainer from "./CapturarBitacoras/CapturaBitacorasContainer";
import EsquemasTrabajoClientesContainer from "./EsquemasClientes/EsquemasTrabajoClientesContainer";
import ActualizarPrecios from "./ActualizarPrecios/ActualizarPreciosContainer";
import AdministrarSanitizaciones from "./Sanitizaciones/Sanitizaciones";
import Auditorias from "./Auditorias/Auditorias";
import Revisiones from "./Revisiones/Revisiones";
import Home from "../components/Home/Home";
import Apoyos from "./Apoyos/Apoyos";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu"

import "../styles/core.scss";
//import logo from "../styles/iconos/logo_suma_4.png";
import { REQUEST_LOGIN_SUCCESS } from "../constants/action_types";
import Encuestas from "../components/Encuestas/Encuestas";
import Contactos from "../components/Contactos/Contactos";
//import { API_ADDRESS } from "../constants/endpoints";

class AppContainer extends Component {
  constructor(props) {
    super(props);

    this.testEo = this.testEo.bind(this);
  }

  componentDidUpdate() {
    const usuario = sessionStorage.getItem("usuario");

    if (usuario) {
      this.props.store.dispatch({
        type: REQUEST_LOGIN_SUCCESS,
        payload: {
          data: JSON.parse(usuario),
        },
      });
    }
  }

  testEo() {
    this.forceUpdate();
  }

  logout() {
    sessionStorage.removeItem("usuario");
    window.location.href = "/";
  }

  render() {
    const { store } = this.props;
    const usuario = store.getState().login.user;
    const debug = false;

    const tipoUsuario = JSON.parse(sessionStorage.getItem("usuario"));

    /*Cerrando sesión al cambio de fecha */
    const endTimeLogin = setInterval(function () {
      const fechaLocalStorage = JSON.parse(sessionStorage.getItem("usuario"));
      const fechaLogin = fechaLocalStorage.fechaLogin;
      const validacionFecha = moment(fechaLogin).isSame(
        moment().format("YYYY-MM-DD")
      );

      if (validacionFecha === false) {
        sessionStorage.removeItem("usuario");
        window.location.href = "/";
      }
    }, 2700000);

    if (sessionStorage.getItem("usuario") === null) {
      clearInterval(endTimeLogin);
    }

    //const usuarioInplant = tipoUsuario.filter(tipo => tipo.tipo === 'InPlant')

    return (
      <Provider store={store}>
        <Router>
          {usuario || debug ? (
            <Grid centered padded>
              <Grid.Row stretched centered>
                <HeaderMenu  usuario={usuario} />
              </Grid.Row>

              <Grid.Row stretched columns={1}>
                <Grid.Column computer={16} tablet={16} only="tablet computer">
                  <Menu stackable>
                  {tipoUsuario.typeUser !== 'Mecanicos' ?
                    <>
                    <Dropdown text="Monitoreo" pointing className="link item">
                      <Dropdown.Menu>
                        <Dropdown.Item to="/monitor" as={NavLink} key="monitor" exact>
                          Monitoreo de servicios
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/transferir-servicios"
                          key="transferir"
                          as={NavLink}
                          exact
                        >
                          Transferir servicios
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/vista-programados"
                          key="programados"
                          as={NavLink}
                          exact
                        >
                          Vista programados
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/listar-bitacoras"
                          key="bitacoras"
                          as={NavLink}
                          exact
                        >
                          Bitácoras
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown text="Programación" pointing className="link item">
                      <Dropdown.Menu>
                        <Dropdown.Item
                          to="/programar-servicios"
                          key="programar"
                          as={NavLink}
                          exact
                        >
                          Programación servicios
                        </Dropdown.Item>
                        
                        <Dropdown.Item
                          to="/registrar-excepcion"
                          key="excepcion"
                          as={NavLink}
                          exact
                        >
                          Excepciones
                        </Dropdown.Item>

                        <Dropdown.Item
                          to="/validar-esquemas"
                          key="esquemas"
                          as={NavLink}
                          exact
                        >
                          Esquemas
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/apoyo-operadores"
                          key="apoyos"
                          as={NavLink}
                          exact
                        >
                          Apoyo operadores
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown
                      text="Auditorías y Revisiones"
                      pointing
                      className="link item"
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          to="/auditorias"
                          key="auditorias"
                          as={NavLink}
                          exact
                        >
                          Auditorias
                        </Dropdown.Item>
                        <Dropdown.Item to="/revision" key="revision" as={NavLink} exact>
                          Revisión
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/reporte-sanitizaciones"
                          key="sanitizaciones"
                          as={NavLink}
                          exact
                        >
                          Sanitizaciones
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    { tipoUsuario.typeUser !== 'Mecanicos' ||
                    tipoUsuario.typeUser === 'InPlant' || 
                    tipoUsuario.typeUser === 'Developer' || 
                    tipoUsuario.typeUser === 'InPlantEsp' ?
                      <Dropdown text="Encuestas y contactos" pointing className="link item">
                        <Dropdown.Menu>
                          <Dropdown.Item
                            to="/encuestas"
                            key="encuestas"
                            as={NavLink}
                            exact
                          >
                            Encuestas
                          </Dropdown.Item>
                          <Dropdown.Item
                            to="/contactos"
                            key="contactos"
                            as={NavLink}
                            exact
                          >
                            Contactos
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      :<></>
                    }
                    <Menu.Item
                        to="/actualizar-precios"
                        key="precios"
                        as={NavLink}
                        exact
                      >
                        Precios
                      </Menu.Item>
                    </>
                    :<>
                    <Dropdown
                      text="Auditorías y Revisiones"
                      pointing
                      className="link item"
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          to="/auditorias"
                          key="auditorias"
                          as={NavLink}
                          exact
                        >
                          Auditorias
                        </Dropdown.Item>
                        <Dropdown.Item to="/revision" key="revision" as={NavLink} exact>
                          Revisión
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/reporte-sanitizaciones"
                          key="sanitizaciones"
                          as={NavLink}
                          exact
                        >
                          Sanitizaciones
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    </>
                  }
                  </Menu>
                </Grid.Column>

                <Grid.Column mobile={16} only="mobile">
                  <Menu compact>
                    <Dropdown item text="Módulos" style={{ position: "unset" }}>
                      <Dropdown.Menu>
                      {tipoUsuario.typeUser !== 'Mecanicos' ?
                      <>
                      <Dropdown.Item to="/monitor" as={NavLink} key="monitor" exact>
                          Monitoreo de servicios
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/transferir-servicios"
                          key="transferir"
                          as={NavLink}
                          exact
                        >
                          Transferir servicios
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/vista-programados"
                          key="programados"
                          as={NavLink}
                          exact
                        >
                          Vista programados
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/listar-bitacoras"
                          key="bitacoras"
                          as={NavLink}
                          exact
                        >
                          Bitácoras
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/programar-servicios"
                          key="programar"
                          as={NavLink}
                          exact
                        >
                          Programación servicios
                        </Dropdown.Item>

                        <Dropdown.Item
                          to="/registrar-excepcion"
                          key="excepcion"
                          as={NavLink}
                          exact
                        >
                          Excepciones
                        </Dropdown.Item>

                        <Dropdown.Item
                          to="/validar-esquemas"
                          key="esquemas"
                          as={NavLink}
                          exact
                        >
                          Esquemas
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/apoyo-operadores"
                          key="apoyos"
                          as={NavLink}
                          exact
                        >
                          Apoyo operadores
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/auditorias"
                          key="auditorias"
                          as={NavLink}
                          exact
                        >
                          Auditorias
                        </Dropdown.Item>
                        <Dropdown.Item to="/revision" key="revision" as={NavLink} exact>
                          Revisión
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/reporte-sanitizaciones"
                          key="sanitizaciones"
                          as={NavLink}
                          exact
                        >
                          Sanitizaciones
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/actualizar-precios"
                          key="precios"
                          as={NavLink}
                          exact
                        >
                          Precios
                        </Dropdown.Item>
                        { tipoUsuario.typeUser === 'InPlant' || 
                          tipoUsuario.typeUser === 'Developer' || 
                          tipoUsuario.typeUser === 'InPlantEsp' ?
                          <>
                            <Dropdown.Item
                              to="/encuestas"
                              key="encuestas"
                              as={NavLink}
                              exact
                            >
                              Encuestas
                            </Dropdown.Item>
                            <Dropdown.Item
                              to="/contactos"
                              key="contactos"
                              as={NavLink}
                              exact
                            >
                              Contactos
                            </Dropdown.Item>
                            </>
                        :<></>
                        }
                        </>
                      :<>
                      <Dropdown.Item
                          to="/auditorias"
                          key="auditorias"
                          as={NavLink}
                          exact
                        >
                          Auditorias
                        </Dropdown.Item>
                        <Dropdown.Item to="/revision" key="revision" as={NavLink} exact>
                          Revisión
                        </Dropdown.Item>
                        <Dropdown.Item
                          to="/reporte-sanitizaciones"
                          key="sanitizaciones"
                          as={NavLink}
                          exact
                        >
                          Sanitizaciones
                        </Dropdown.Item>
                      </>
                      }
    
                        
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu>
                </Grid.Column>

              </Grid.Row>

              <Route path="/" exact component={Home} />
              <Route path="/monitor" component={MonitorServicios} />
              <Route
                path="/transferir-servicios"
                component={TransferirServicios}
              />
              <Route path="/vista-programados" component={VistaProgramados} />
              <Route
                path="/listar-bitacoras"
                component={ListarBitacorasContainer}
              />
              <Route
                path="/programar-servicios"
                component={ProgramacionServicios}
              />
              <Route
                path="/registrar-excepcion"
                component={RegistroExcepciones}
              />
              <Route
                path="/validar-esquemas"
                component={EsquemasTrabajoClientesContainer}
              />
              <Route path="/apoyo-operadores" component={Apoyos} />
              <Route path="/actualizar-precios" component={ActualizarPrecios} />
              <Route
                path="/reporte-sanitizaciones"
                component={AdministrarSanitizaciones}
              />
              <Route path="/auditorias" component={Auditorias} />
              <Route path="/revision" component={Revisiones} />
              <Route path="/encuestas" component={Encuestas} />
              <Route path="/contactos" component={Contactos} />
            </Grid>
          ) : (
            <LoginForm onEo={this.testEo} />
          )}
        </Router>
      </Provider>
    );
  }
}

AppContainer.propTypes = {
  store: PropTypes.object.isRequired,
};

export default AppContainer;
