import React, { Component } from "react";
import { Segment, Form, Select, Button } from "semantic-ui-react";
import axios from "axios";

import { ENDPOINT_CLIENTES } from '../../constants/endpoints'

class LinkForm extends Component {
    constructor(props){
        super(props)

        this.state = ({
            loading: false
        })
    }

    componentDidMount(){
        const url = `${ENDPOINT_CLIENTES}?activo=true&id_categoria=1&or(id=5)&sort(+nombre_empresa)`;

        this.setState({
            loading: true
        })

        return(
            axios.get(url)
                .then((response) => {
                    if(response.status === 200){

                        const cliente = response.data.map(cliente => {
                            return { key: cliente.data.id, text: cliente.data.attributes.nombreEmpresa, value: cliente.data.attributes.iniciales }
                        })

                        return this.setState({
                            cliente: cliente,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        )
    }

    crearLink = () => {
        const empresa = this.state.inicialesCliente;
        const url = `http://servicios.sumaenlinea.mx/Encuesta/?cliente=${empresa}`

        return (
            this.setState({
                link: url
            })
        )
    }

    handleChangeValue = (e, data) => {
        this.setState({ [data.name]: data.value })
    }

  render() {
    const link = this.state?.link;
    const cliente = this.state?.cliente;
    let { value } = this.state;
    return (
        <Segment.Group horizontal>
            <Segment>
                <h3>Selecciona el cliente</h3>
                <Form className='form-evaluacion-especial'>
                    <Form.Group widths='equal' className='form-group-inputs'>
                        <Form.Field
                            control={Select}
                            options={cliente}
                            placeholder='Cliente'
                            search
                            value={value}
                            name='inicialesCliente'
                            onChange={this.handleChangeValue}
                            loading={this.state.loading}
                        />
                    </Form.Group>
                    <Button  className='form-button' color='orange' onClick={this.crearLink}>Crear Link</Button>
                </Form>
            </Segment>
            <Segment>
                <h3>Link para compartir con el cliente</h3>
                { link ?
                    <a href={link}>{link}</a>:<></>
                }
            </Segment>
        </Segment.Group>
    );
  }
}

export default LinkForm;
