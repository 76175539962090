import _ from "lodash";
import React, { useEffect } from "react";
import { Segment, Table, Icon, Button, Label, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import moment from "moment";
import ReactTooltip from "react-tooltip";

import ModalIncidenteServicio from "./ModalIncidenteServicio";
import { validarStorage } from "../../helpers/index";
class ListadoBitacoras extends BaseComponent {
  render() {
    const {
      bitacoras,
      onCambioIndividualHorario,
      onDesconfirmarBitacora,
      onCancelarServicio,
      onIniciarRescate,
      onIniciarIncidente,
      login: { user },
      usuario,
      incidente,
      causas,
      efectos,
      onFinalizarIncidente,
      onIncidenteServicio,
      onFetchCausas,
      onFetchEfectos,
    } = this.props;

    
    const permisos = validarStorage(user).permisos;
    //const permisoId = validarStorage(user).id;
    
    const aplicarRescate = function (bitacora) {
      onIniciarRescate(bitacora);
    };

    const aplicarIncidente = function (bitacora) {
      onIniciarIncidente(bitacora);
    };


    
   
    const tablas = _.map(bitacoras.all, (bitacora) => {
      const fechaComparacion = moment();
      const fechaBitacora = moment(bitacora.fecha);
      const diferenciaDias = fechaComparacion.diff(fechaBitacora, "days");
      let fechaValida;

      if (diferenciaDias > 7) {
        fechaValida = false;
      } else {
        fechaValida = true;
      }
      
      const horaInicio = bitacora.tiempoInicial.substring(11, 19);
      const horaFin = bitacora.tiempoFinal.substring(11, 19);
      return (
        <Table.Row
          key={bitacora.id}
          negative={!bitacora.hasDatosCompletos()}
          textAlign="center"
        >
          <Table.Cell>
            {bitacora.attributes.cancelado && (
              <Label ribbon color="red">
                Cancelada
              </Label>
            )}
            {bitacora.attributes.excepcion && (
              <Label ribbon color="orange">
                Excepción
              </Label>
            )}

            {bitacora.attributes.transferido === true && (
              <Popup
                content={bitacora.attributes.motivoTransferencia}
                key={bitacora.attributes.id}
                header='Notas de transferencia'
                trigger={<Label as='a' color='teal' ribbon>
                  Transferido
                </Label>}
              />)
            }

            {bitacora.id}
          </Table.Cell>
          <Table.Cell>
            <p>{bitacora.getFecha()}</p>
            <p>
              {horaInicio} a {horaFin}
            </p>
          </Table.Cell>
          <Table.Cell>
            <Icon name="circle" color={bitacora.getEstructura().color()} />
            {`${bitacora.getNombreRuta()}`}
            {bitacora.attributes.idProveedor === 1 ?
              <p>{`${bitacora.getOperador()?.nombre}`}</p>
              :
              <p>Sin operador</p>
            }


            {bitacora.attributes.tipoBitacora && (
              <p>
                <Icon name="circle" color="orange" size="small" />
                <span style={{ fontWeight: "600" }}>Tipo: </span>
                {`${bitacora.attributes.tipoBitacora}`}
              </p>
            )}
          </Table.Cell>
          <Table.Cell>
            <Icon name="user" color="blue" />
            {bitacora.numeroPersonas}
          </Table.Cell>
          <Table.Cell>{`U${bitacora.getUnidad().id} - ${bitacora.getUnidad().tipo
            }`}</Table.Cell>
          <Table.Cell>
            <Icon
              name={bitacora.confirmado ? "checkmark" : "remove"}
              color={bitacora.confirmado ? "green" : "red"}
            />
          </Table.Cell>
          <Table.Cell>
            <Icon
              name={bitacora.terminado ? "checkmark" : "remove"}
              color={bitacora.terminado ? "green" : "red"}
            />
          </Table.Cell>
          <Table.Cell textAlign={"center"}>
            {permisos?.abrirModalIncidente == true ?
              <>
                <Button
                  basic
                  color={"orange"}
                  icon={"warning sign"}
                  data-tip="Ver Incidentes"
                  onClick={() => aplicarIncidente(bitacora)}
                />
                <ReactTooltip />
              </>
              : <></>
            }

            {permisos?.rescatarServcio == true && bitacora.rescatable ?
              <>
                <Button
                  basic
                  color={"grey"}
                  icon={"lightning"}
                  data-tip="Aplicar rescate"
                  onClick={() => aplicarRescate(bitacora)}
                />
                <ReactTooltip />
              </>
              : <></>
            }

            {permisos?.cambioIndividualHorario == true ?
              <>
                <Button
                  basic
                  color={"grey"}
                  icon={"clock outline"}
                  data-tip="Cambio de horario"
                  onClick={() => onCambioIndividualHorario(bitacora)}
                />
                <ReactTooltip />
              </>
              : <></>
            }

            {permisos?.cancelarBitacora == true ?
              <>
                <Button
                  basic
                  color={"grey"}
                  icon={"circle times"}
                  data-tip="Cancelar servicio"
                  onClick={() => onCancelarServicio(bitacora)}
                />
                <ReactTooltip />
              </>
              : <></>
            }
            
            {permisos?.desconfirmarBitacoras == true && bitacora.confirmado && fechaValida === true ?
              <>
                <Button
                  basic
                  color={"orange"}
                  icon={"undo"}
                  data-tip="Desconfirmar bitácora"
                  onClick={() => onDesconfirmarBitacora(bitacora)}
                />
                <ReactTooltip />
              </>
              : <></>
            }
          </Table.Cell>
        </Table.Row>
      );
    });
    console.log("bitacoras",bitacoras);
    return (
      <>
        <Segment basic loading={bitacoras.fetching}>
          <Table celled selectable>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Fecha</Table.HeaderCell>
                <Table.HeaderCell>Ruta</Table.HeaderCell>
                <Table.HeaderCell>Aforo</Table.HeaderCell>
                <Table.HeaderCell>Unidad</Table.HeaderCell>
                <Table.HeaderCell>C</Table.HeaderCell>
                <Table.HeaderCell>T</Table.HeaderCell>
                <Table.HeaderCell>Acciones</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{tablas}</Table.Body>
          </Table>
        </Segment>

        {incidente.bitacora && permisos?.abrirModalIncidente === true ? (
          <ModalIncidenteServicio
            usuario={usuario}
            incidente={incidente}
            causas={causas}
            efectos={efectos}
            onFinalizarIncidente={onFinalizarIncidente}
            onIncidenteServicio={onIncidenteServicio}
            onFetchCausas={onFetchCausas}
            onFetchEfectos={onFetchEfectos}
          />
        ) : (<></>)
        }
      </>
    );
  }
}

ListadoBitacoras.propTypes = {
  bitacoras: PropTypes.object.isRequired,
  onCambioIndividualHorario: PropTypes.func.isRequired,
  onDesconfirmarBitacora: PropTypes.func.isRequired,
  onCancelarServicio: PropTypes.func.isRequired,
  onIniciarRescate: PropTypes.func.isRequired,
  onIniciarIncidente: PropTypes.func.isRequired,
};

export default ListadoBitacoras;
