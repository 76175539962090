import React from "react";
import { Button, List } from "semantic-ui-react";


const ListaContactos = ({ item, onDesactivar, onAbrirModal, onDesactivarExterno, onActivar, onActivarExterno, loading }) => {
  
  return (
    <List.Item key={item.ID}>
        <List.Icon name='address card outline' size='large' verticalAlign='middle' color='orange'/>
        <List.Content>
            <List.Header>{item.NOMBRE}</List.Header>
            <List.Description>
            <span style={{fontWeight: '900' }}>Puesto:</span> {item.PUESTO} <span style={{color: '#f2711c', fontWeight: 'bold' }}> | </span>
            <span style={{fontWeight: '900' }}>Cel:</span> {item.CELULAR} <span style={{color: '#f2711c', fontWeight: 'bold' }}> | </span>
            <span style={{fontWeight: '900' }}>Correo:</span> {item.CORREO_ELECTRONICO} <span style={{color: '#f2711c', fontWeight: 'bold' }}> | </span>
            <span style={{fontWeight: '900' }}>Asignado a la ruta:</span> {item.RUTA > 0 ? item.RUTA : 'Sin ruta asignada'} <span style={{color: '#f2711c', fontWeight: 'bold' }}> | </span>
            <span style={{fontWeight: '900' }}>Contacto:</span> {item.EXTERNO === 0 ? 'Interno':'Externo'} <span style={{color: '#f2711c', fontWeight: 'bold' }}> | </span>
            <span style={{fontWeight: '900' }}>Horario:</span> {item.HORARIO}
            </List.Description>
        </List.Content>

        { onDesactivar && onAbrirModal &&
          <>
          <Button floated='right' loading={loading} className='form-button' color='orange' size='tiny' onClick={() => onAbrirModal(item)}>Editar</Button>
          <Button floated='right' loading={loading} className='form-button' size='tiny' onClick={() => onDesactivar(item)}>Desactivar</Button>
          </>
        }

        { onAbrirModal && onDesactivarExterno &&
          <>
          <Button floated='right' loading={loading} className='form-button' color='orange' size='tiny' onClick={() => onAbrirModal(item)}>Editar</Button>
          <Button floated='right' loading={loading} className='form-button' size='tiny' onClick={() => onDesactivarExterno(item)}>Desactivar</Button>
          </>
        }

        { onActivar && onAbrirModal &&
          <>
          <Button floated='right' loading={loading} className='form-button' color='orange' size='tiny' onClick={() => onAbrirModal(item)}>Editar</Button>
          <Button floated='right' color='orange' loading={loading} className='form-button' size='tiny' onClick={() => onActivar(item)}>Activar</Button>
          </>
        }

        { onAbrirModal && onActivarExterno &&
          <>
          <Button floated='right' loading={loading} className='form-button' color='orange' size='tiny' onClick={() => onAbrirModal(item)}>Editar</Button>
          <Button floated='right' loading={loading} className='form-button' size='tiny' onClick={() => onActivarExterno(item)}>Activar</Button>
          </>
        }
    </List.Item>
  )
}
 
export default ListaContactos;