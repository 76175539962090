import React from "react";
import { Button, Form, Segment } from "semantic-ui-react";
import moment from "moment";
import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
class FormaBuscarRevisiones extends BaseComponent {
  constructor(props) {
    super(props);

    this.initialValues = {
      desde: moment().startOf("week").format("YYYY-MM-DD"),
      hasta: moment().endOf("week").format("YYYY-MM-DD"),
      fecha: "",
    };

    this.state = {
      ...this.initialValues,
    };
  }

  onChangeValue = (event, data) => {
    if (data) {
      this.setState({ [data.name]: data.value });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  onSubmit = () => {
    const params = [];
    
    if (this.state.desde) {
      params.push(`fecha=ge=${this.state.desde} 00:00:00`);
    }

    if (this.state.hasta) {
      params.push(`fecha=le=${this.state.hasta} 23:59:00`);
    }

    this.props.onRequestRevisiones(params);
  };

  startCreateRevision = (e) => {
    e.preventDefault(e);
    this.props.startCreateRevisiones();
  };

  render() {
    const { permisos } = this.props

    return (
      <Segment color="orange" container="true">
        <h3 style={{ textAlign: "left" }}>Buscar Revisiones</h3>
        <Form onSubmit={this.onSubmit}>
          <Form.Group widths="equal" inline>
            <Form.Input
              fluid
              label="Desde"
              name={"desde"}
              type="date"
              value={this.state.desde}
              onChange={this.onChangeValue}
            />
            <Form.Input
              fluid
              label="Hasta"
              name={"hasta"}
              type="date"
              value={this.state.hasta}
              onChange={this.onChangeValue}
            />
          </Form.Group>
          <Form.Group unstackable fluid="true">
          { permisos?.verAuditorias ?
              <Button
                fluid
                color="orange"
                loading={this.props.revisiones.fetching}
              >
                Buscar revisión
              </Button>
              :<></>
            }
            { permisos?.crearAuditorias ?
              <Button fluid onClick={this.startCreateRevision}>
                Nueva revisión
              </Button>
              :<></>
            }
          </Form.Group>
        </Form>
      </Segment>
    );
  }
}

FormaBuscarRevisiones.propTypes = {
  revisiones: PropTypes.object.isRequired,
  onRequestRevisiones: PropTypes.func.isRequired,
  startCreateRevisiones: PropTypes.func,
};

export default FormaBuscarRevisiones;
