import React, { Component } from "react";
import { List, Button, Table } from "semantic-ui-react";
import { downloadExcelEncuestas } from '../../helpers'

class ListaEncuestas extends Component {
    constructor(props){
        super(props)

        this.state = ({

        })
    }

  render() {
    const listaEncuestas = this.props;
    const cliente = listaEncuestas.listaEncuestas[0].CLIENTE
    const totalEncuestas = listaEncuestas.listaEncuestas.length
    const downloadFile = downloadExcelEncuestas;

    return (
        <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
              <h3 style={{marginBottom: '20px'}}>Resultados de encuesta aplicada en <span style={{color: '#f2711c'}}>{cliente}</span></h3>
              <h3 style={{margin: '0'}}>Total de empleados que contestaron: <span style={{color: '#f2711c'}}>{totalEncuestas}</span></h3>
            </div>
            <Button onClick={() => downloadFile(listaEncuestas.listaEncuestas, 'encuestas')} color='orange' style={{height: 'fit-content'}}>
                <List.Icon name='file excel outline' size='large' verticalAlign='middle' />
                Descargar resultados
            </Button>
        </div>
        <Table celled structured>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell rowSpan='2' textAlign='center'>Id</Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2' textAlign='center'>Fecha</Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2' textAlign='center'>Bitácora</Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2' textAlign='center'>Ruta</Table.HeaderCell>
                    <Table.HeaderCell colSpan='12' textAlign='center'>Evaluación por preguntas</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell textAlign='center'>1</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>2</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>3</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>4</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>5</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>6</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>7</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>8</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>9</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>10</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>11</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Total</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
            { listaEncuestas.listaEncuestas.map((item) => {
                let total = item.ESTADO_UNIDAD + item.ITINERARIO + item.LIMPIEZA_COORDINADOR +
                item.LIMPIEZA_IMPLANT + item.LIMPIEZA_OPERADOR + item.LIMPIEZA_UNIDADES +
                item.SEGURIDAD + item.SERVICIO

                return (
                  <Table.Row key={item.ID}>
                    <Table.Cell>{item.ID}</Table.Cell>
                    <Table.Cell>{item.FECHA}</Table.Cell>
                    <Table.Cell>{!item.BITACORA ? 'Sin información':item.BITACORA}</Table.Cell>
                    <Table.Cell textAlign='center'>{item.RUTA}</Table.Cell>
                    <Table.Cell textAlign='center'>{item.ROLA_TURNOS}</Table.Cell>
                    <Table.Cell textAlign='center'>{item.SERVICIO}</Table.Cell>
                    <Table.Cell textAlign='center'>{item.SEGURIDAD}</Table.Cell>
                    <Table.Cell textAlign='center'>{item.LIMPIEZA_OPERADOR}</Table.Cell>
                    <Table.Cell textAlign='center'>{item.LIMPIEZA_COORDINADOR}</Table.Cell>
                    <Table.Cell textAlign='center'>{item.LIMPIEZA_IMPLANT}</Table.Cell>
                    <Table.Cell textAlign='center'>{item.LIMPIEZA_UNIDADES}</Table.Cell>
                    <Table.Cell textAlign='center'>{item.CONOCE_ITINERARIO}</Table.Cell>
                    <Table.Cell textAlign='center'>{item.ITINERARIO}</Table.Cell>
                    <Table.Cell textAlign='center'>{item.ESTADO_UNIDAD}</Table.Cell>
                    <Table.Cell textAlign='center'>{item.UTILIZA_MAPAS}</Table.Cell>
                    <Table.Cell textAlign='center'>{total} de 40</Table.Cell>
                  </Table.Row>
                )
            })}
            </Table.Body>
        </Table>
        </div>
    );
  }
}

export default ListaEncuestas;