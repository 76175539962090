import React from "react";
import PropTypes from "prop-types";
import { Container, Modal } from "semantic-ui-react";
import BaseComponent from "../BaseComponent";
import FormaCapturarAuditoria from "./FormaCapturarAuditoria";
import FormaBuscarAuditorias from "./FormaBuscarAuditorias";
import ListaAuditorias from "./ListaAuditorias";
import { validarStorage } from "../../helpers/index";

class AdministrarAuditorias extends BaseComponent {
  componentDidMount() {
    this.props.requestAuditorias([]);

    this.props.requestProveedores([
      "activo=true",
      "id_categoria=8",
      "or(id=1)",
      "sort(+empresa)",
    ]);

    this.props.requestOperadores(["activo=true", "sort(+nombre)"]);

    this.props.requestUnidades(["activa=true"]);
  }

  render() {
    const {
      auditorias,
      login,
      operadores,
      proveedores,
      unidades,
      requestAuditorias,
      requestCreateAuditoria,
      startCreateAuditoria,
      endCreateAuditoria,
    } = this.props;

    const { creating } = auditorias;
    const openModal = creating.openModal;
    const sending = creating.sending;
    const user = login.user;
    const permisos = validarStorage(user).permisos;

    return (
      <Container fluid style={{ padding: "10px" }}>
        <div>
          <FormaBuscarAuditorias
            auditorias={auditorias}
            onRequestAuditorias={requestAuditorias}
            startCreateAuditoria={startCreateAuditoria}
            operadores={operadores}
            unidades={unidades}
            login={login}
            permisos={permisos}
          />

          <ListaAuditorias auditorias={auditorias} />

          {openModal && (
            <Modal size={"large"} open={openModal}>
              <Modal.Header>Agregar auditoría</Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <FormaCapturarAuditoria
                    onRequestCreateAuditoria={requestCreateAuditoria}
                    operadores={operadores}
                    proveedores={proveedores}
                    unidades={unidades}
                    endCreateAuditoria={endCreateAuditoria}
                    login={login}
                    sending={sending}
                    error={creating.error}
                  />
                </Modal.Description>
              </Modal.Content>
            </Modal>
          )}
        </div>
      </Container>
    );
  }
}

AdministrarAuditorias.propTypes = {
  auditorias: PropTypes.object.isRequired,
  requestAuditorias: PropTypes.func.isRequired,
  requestCreateAuditoria: PropTypes.func.isRequired,
  operadores: PropTypes.object,
  proveedores: PropTypes.object,
  unidades: PropTypes.object,
};

export default AdministrarAuditorias;
