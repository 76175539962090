import moment from "moment";
import React, { Component } from "react";
import {
  Button,
  Form,
  Segment,
} from "semantic-ui-react";
import PropTypes from "prop-types";

import "../../styles/FiltrosBusquedaSanitizaciones.css";

import {
  extraerOpcionesClientes,
  extraerOpcionesUnidades,
  extraerParametrosBusqueda,
} from "../../helpers";

class FiltrosBusquedaSanitizaciones extends Component {
  state = {
    desde: moment().startOf("week").format("YYYY-MM-DD"),
    hasta: moment().endOf("week").format("YYYY-MM-DD"),
    cliente_id: -1,
    unidad_id: -1,
  };

  paramsMap = {
    desde: "fecha=ge",
    hasta: "fecha=le",
    cliente_id: "cliente_id",
    unidad_id: "unidad_id",
  };

  // Falta loading animation & Mensaje de no encontrados

  componentDidMount() {
    this.props.requestClientes([
      "activo=true",
      "id_categoria=1",
      "or(id=5)",
      "sort(+nombre_empresa)",
    ]);
    this.props.requestUnidades(["activa=true"]);
    this.props.requestSanitizaciones(
      extraerParametrosBusqueda(this.state, this.paramsMap)
    );
  }

  onChangeCliente = (event, data) => {
    this.setState({ cliente_id: data.value });
  };

  onChangeUnidad = (event, data) => {
    this.setState({ unidad_id: data.value });
  };

  onChangeFechaInicial = (event, data) => {
    this.setState({ desde: data.value });
  };

  onChangeFechaFinal = (event, data) => {
    this.setState({ hasta: data.value });
  };

  // Al limpiar todavia tarda dos click en hacer el cambio de estado
  onLimpiarBusqueda = () => {
    this.setState({
      desde: moment().startOf("week").format("YYYY-MM-DD"),
      hasta: moment().endOf("week").format("YYYY-MM-DD"),
      cliente_id: -1,
      unidad_id: -1,
    });
    this.props.invalidarSanitizaciones();
  };

  onSubmit = () => {
    extraerParametrosBusqueda(this.state, this.paramsMap);
    this.props.requestSanitizaciones(
      extraerParametrosBusqueda(this.state, this.paramsMap)
    );
  };

  render() {
    const { hasta, desde, cliente_id, unidad_id } = this.state;
    return (
            <Segment color="orange" container="true">
               <h3 style={{ textAlign: "left" }}>Sanitizaciones</h3>
              <Form onSubmit={this.onSubmit}>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid={true}
                    value={desde}
                    onChange={this.onChangeFechaInicial}
                    type="date"
                    name="desde"
                    label="Desde"
                  />
                  <Form.Input
                    fluid={true}
                    value={hasta}
                    onChange={this.onChangeFechaFinal}
                    type="date"
                    name="hasta"
                    label="Hasta"
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Select
                      placeholder="Cliente"
                      fluid
                      value={cliente_id}
                      onChange={this.onChangeCliente}
                      selection
                      search
                      options={this.props.clientes.all.map(extraerOpcionesClientes)}
                    />
                    <Form.Select
                      placeholder="Unidad"
                      fluid
                      value={unidad_id}
                      onChange={this.onChangeUnidad}
                      selection
                      search
                      options={this.props.unidades.all.map(extraerOpcionesUnidades)}
                    />
                  </Form.Group>
                  <Form.Group unstackable fluid="true">
                      <Button color={"orange"} fluid={true} type="submit" onClick={this.onSubmit}> Buscar</Button>
                      <Button fluid={true} type="submit" onClick={this.onLimpiarBusqueda}>Limpiar</Button>
                  </Form.Group>
              </Form>
        </Segment>
    );
  }
}

FiltrosBusquedaSanitizaciones.propTypes = {
  clientes: PropTypes.object.isRequired,
  unidades: PropTypes.object.isRequired,
  invalidarSanitizaciones: PropTypes.func.isRequired,
  requestClientes: PropTypes.func.isRequired,
  requestUnidades: PropTypes.func.isRequired,
  requestSanitizaciones: PropTypes.func.isRequired,
};

export default FiltrosBusquedaSanitizaciones
