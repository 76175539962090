import React, { Component } from "react";
import { Segment, Form, Select, Button, Message } from "semantic-ui-react";
import axios from "axios";
import ListaEncuestas from "./ListaEncuestas";

import { ENDPOINT_CLIENTES, ENDPOINT_ENCUESTA_REPORTE } from '../../constants/endpoints'

class ResultadosForm extends Component {
    constructor(props){
        super(props)

        this.state = ({
            faltanValores: true,
            loading: false,
            mensaje: false,
        })
    }

    componentDidMount(){
        const url = `${ENDPOINT_CLIENTES}?activo=true&id_categoria=1&or(id=5)&sort(+nombre_empresa)`;

        this.setState({
            loading: true
        })

        return(
            axios.get(url)
                .then((response) => {
                    if(response.status === 200){

                        const cliente = response.data.map(cliente => {
                            return { key: cliente.data.id, text: cliente.data.attributes.nombreEmpresa, value:  cliente.data.id }
                        })

                        return this.setState({
                            cliente: cliente,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        )
    }

    handleChangeValue = (e, data) => {
        this.setState({ [data.name]: data.value })
    }

    verEncuestas = () => {
        const params = {
            id_cliente: this.state.idEmpresa,
            desde: this.state.desde,
            hasta: this.state.hasta
        }

        if(!params.id_cliente || !params.desde || !params.hasta){
            return this.setState({
                mensaje: true
            })
        }

        const url = `${ENDPOINT_ENCUESTA_REPORTE}?id_cliente=${params.id_cliente}&desde=${params.desde}&hasta=${params.hasta}`;

        this.setState({
            loading: true
        })

        return(
            axios.get(url)
                .then((response) => {
                    if(response.status === 200 && response.data.data && response.data.success === true){
                        return this.setState({
                            listaEncuestas: response.data.data,
                            mostrarLista: true,
                            sinResultados: false,
                            loading: false
                        });
                    }else{
                        return this.setState({
                            sinResultados: response.data,
                            mostrarLista: false,
                            loading: false
                        }); 
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        )
    }

  render() {
    const cliente = this.state?.cliente;
    const mostrarLista = this.state?.mostrarLista;
    const listaEncuestas = this.state?.listaEncuestas;
    const mensaje = this.state?.mensaje;
    const sinResultados = this.state?.sinResultados;
    let { value } = this.state;

    return (
        <>
        <Segment.Group>
            <Segment>
                <h3>Buscar resultados de encuestas aplicadas</h3>
                <Form>
                    <Form.Group widths='equal' className='form-group-inputs'>
                        <Form.Field
                        control={Select}
                        options={cliente}
                        label='Cliente'
                        placeholder='Cliente'
                        search
                        value={value}
                        name='idEmpresa'
                        onChange={this.handleChangeValue}
                        loading={this.state.loading}
                        />
                        <Form.Input
                        fluid={true}
                        label="Desde"
                        name='desde'
                        type="date"
                        value={value}
                        onChange={this.handleChangeValue}
                        />
                        <Form.Input
                        fluid={true}
                        label="Hasta"
                        name='hasta'
                        type="date"
                        value={value}
                        onChange={this.handleChangeValue}
                        />
                    </Form.Group>
                    <Button  className='form-button' color='orange' onClick={this.verEncuestas}>Ver resultados</Button>
                </Form>
            </Segment>
        </Segment.Group>

        { mostrarLista && listaEncuestas ?
        <ListaEncuestas listaEncuestas={listaEncuestas}/>
        :<></>
        }

        { sinResultados ?
            <Message info style={{textAlign: 'left'}}>
                <Message.Header>{sinResultados.data}</Message.Header>
            </Message>
            : <></>
        }

        { mensaje && !mostrarLista && !sinResultados && !listaEncuestas ?
            <Message info style={{textAlign: 'left'}}>
                <Message.Header>Datos faltantes</Message.Header>
                <p>Es necesario seleccionar todos los datos</p>
                <p>Por favor selecciona el cliente y el rango de fechas a consultar</p>
            </Message>
            : <></>
        }
        </>
    );
  }
}

export default ResultadosForm;