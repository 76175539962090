import React from 'react'
import { Icon, Container, Image, Grid, Header } from 'semantic-ui-react'
import logo from "../../styles/iconos/logo_suma_4.png"
import { API_ADDRESS } from "../../constants/endpoints";

const logout = () => {
  sessionStorage.removeItem("usuario");
    window.location.href = "/";
}

const HeaderMenu = ({usuario}) => (
  <Container textAlign='center'>
    <Grid textAlign='center' verticalAlign='middle' columns={3}>
       <Grid.Row>
          <Grid.Column style={{boxShadow: '0px !important'}}>
              <Image src={logo} size="small" />
          </Grid.Column>
          <Grid.Column>
            <Header as='h3' style={{ color: "grey"}}>Panel Monitor</Header>
          </Grid.Column>

        {API_ADDRESS.includes("tapi") ? (
          <Grid.Column style={{display: 'flex', justifyContent: 'center', flexDirection: 'row', textAlign: 'center', verticalAlign: 'center', boxShadow: '0px !important'}}>
            <Header as='h4' style={{color: "#db2828", fontWeight: 'bold', textAlign: 'right'}}>Testing</Header>
             &nbsp;
              <Icon color={"red"} name="cogs" size="large" />
              &nbsp;
              <Icon
                size="large"
                color={"orange"}
                name="log out"
                style={{ marginLeft: "10px", cursor: "pointer" }}
                onClick={() => logout()}
              />
            </Grid.Column>
        ):(
          <Grid.Column style={{display: 'flex', justifyContent: 'center', flexDirection: 'row', textAlign: 'center', verticalAlign: 'center', boxShadow: '0px !important'}}>
              <Header as='h4' textAlign='center' style={{color: "grey", fontWeight: 'bolder', marginTop: '0px'}}>{usuario.iniciales} &nbsp;</Header>
                    &nbsp;
              <Icon color={"orange"} name="user" size="large" />
               <Icon
                      size="large"
                      color={"orange"}
                      name="log out"
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={() => logout()}
                    />
          </Grid.Column>
        )}
    </Grid.Row>
    </Grid>
  </Container>
)

export default HeaderMenu
