import React, {Component} from 'react';
import {Container, Table, TableRow, TableCell, Segment, Message, Button, Grid} from 'semantic-ui-react';
import PropTypes from 'prop-types'

class ListaSanitizaciones extends Component {
    getListadoSanitizaciones() {
        return (
            <Segment textAlign={"left"} container="true">
               <h3>Listado de sanitizaciones</h3>
               <Grid padded>
                  <Grid.Row only="computer tablet">
                    <Grid.Column style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <Table celled>
                          <Table.Header>
                              <Table.Row textAlign="center">
                                  <Table.HeaderCell>Cliente</Table.HeaderCell>
                                  <Table.HeaderCell>Unidad</Table.HeaderCell>
                                  <Table.HeaderCell>Operador</Table.HeaderCell>
                                  <Table.HeaderCell>Fecha - Sanitizacion</Table.HeaderCell>
                                  <Table.HeaderCell>Evidencia</Table.HeaderCell>
                              </Table.Row>
                          </Table.Header>
                          <Table.Body>
                              {this.props.sanitizaciones.all.map((sanitizacion, idx) => {
                                  return (
                                      <TableRow key={idx} textAlign="center">
                                          <TableCell>
                                              {sanitizacion.getCliente().getAttribute('nombreEmpresa')}
                                          </TableCell>
                                          <TableCell>
                                              {sanitizacion.getUnidad().getAttribute('numeroEconomico')}
                                          </TableCell>
                                          <TableCell>
                                              {sanitizacion.getOperador().getAttribute('nombre')}
                                          </TableCell>
                                          <TableCell>
                                              {sanitizacion.getFecha('dddd D MMMM YYYY')}
                                          </TableCell>
                                          <TableCell>
                                              <Button color={"orange"} circular icon='play'/>
                                          </TableCell>
                                      </TableRow>
                                  )
                              })}
                          </Table.Body>
                      </Table>
                    </Grid.Column>
                  </Grid.Row>
              </Grid>
            </Segment>

        )
    }

    mensajeNohayDatos() {
        return (
            <Message
                negative
                color="red"
                icon="warning sign"
                header="No se encontraron sanitizaciones"
                content="Intenta un nuevo filtro"
            />

        )
    }

    render() {
        return (
            <Container>
                {!this.props.sanitizaciones.all.length ? this.mensajeNohayDatos() : this.getListadoSanitizaciones()}
            </Container>
        )
    }
}

ListaSanitizaciones.propTypes = {
    sanitizaciones: PropTypes.object.isRequired
}

export default ListaSanitizaciones
