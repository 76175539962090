import axios from "axios";
import { takeEvery, call, put } from 'redux-saga/effects'

import {
  REQUEST_APOYOS,
  REQUEST_CREATE_APOYO,
} from "../constants/action_types";

import {
  ENDPOINT_APOYO,
  ENDPOINT_CREAR_APOYO,
} from "../constants/endpoints";

import {
  onRequestApoyosSuccess,
  onRequestApoyosFailed,
  onRequestCreateApoyoSuccess,
  onRequestCreateApoyoFailed,
} from "../actions/apoyos";

function* fetchApoyos({ payload: { params } }) {
  try {
    const url = `${ENDPOINT_APOYO}` + (params.length ? `?${params.join('&')}` : '')

    const response = yield call(axios.get, url)
    yield put(onRequestApoyosSuccess(response))
  } catch (e) {
    yield put(onRequestApoyosFailed, (e.response))
  }
}

function* fetchCreateApoyo({ payload: { params } }) {

  try {
    const response = yield call(axios.post, ENDPOINT_CREAR_APOYO, params)

    yield put(onRequestCreateApoyoSuccess(response))
  } catch (e) {
    yield put(onRequestCreateApoyoFailed(e.response))
  }
}


export function* watchRequestApoyos() {
  yield takeEvery(REQUEST_APOYOS, fetchApoyos)
}

export function* watchCreateApoyo() {
  yield takeEvery(REQUEST_CREATE_APOYO, fetchCreateApoyo)
}
