import React, { useEffect, useState } from 'react'
import { Grid, Message } from 'semantic-ui-react'

import FormaProgramarExcepcionServicio from '../../containers/Excepciones/FormaProgramacionExcepcionContainer'
import { tipoUsuario } from '../../helpers';


const RegistroExcepciones = () => {
    
    const [acceso, setAcceso] = useState(false)
    useEffect(() => {
        tipoUsuario.map((item, key) => {
            if (item.tipo == 'Monitoristas') {
                console.log("item", item.ids);
                item.ids.map((id, index) => {
                    if (JSON.parse(sessionStorage.getItem("usuario")).id == id) {
                        console.log("no tiene axeso");
                        setAcceso(false)
                    }
                    else {
                        console.log("no tiene axeso");
                        setAcceso(true)
                    }
                })
            }
        })
    }, [])
    return (
        <>
            <Grid.Column mobile={16} tablet={16} computer={16}>
                {
                    acceso ?
                        <>
                            <h3 style={{ textAlign: "left" }}>Excepciones</h3>

                            <FormaProgramarExcepcionServicio />
                        </> :
                        <>
                            <Message warning>
                                <Message.Header>No cuenta con permisos para realizar programaciones</Message.Header>
                                <p>Si requieres acceso, favor de validar con Sistemas</p>
                            </Message>
                        </>
                }

            </Grid.Column>
        </>)
}



export default RegistroExcepciones
