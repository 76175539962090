import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Icon, Label, Table, Button} from "semantic-ui-react";

import {validarStorage} from '../../helpers/index'
class DetalleBitacoras extends Component {

    render() {
        const {bitacoras, user, onIniciarCambioPrecio, bitacoras: {cambioIndividualPrecio}} = this.props
        const permisos = validarStorage(user).permisos
        const permisosPrecios = validarStorage(user).id

        return bitacoras.listado.all.map((bitacora) => {

            return (
                <Table.Row key={bitacora.id}>
                    <Table.Cell>
                        {bitacora.attributes.cancelado && <Label ribbon color="red">Cancelada</Label>}
                        {bitacora.attributes.excepcion && <Label ribbon color="orange">Excepción</Label>}
                        {bitacora.id}
                    </Table.Cell>
                    <Table.Cell textAlign={'center'}><p>{bitacora.getFecha()}</p><p>({bitacora.getHorario()})</p>
                    </Table.Cell>
                    <Table.Cell textAlign={'center'}>
                        <p>
                            <Icon name='circle' color={bitacora.getEstructura().color()}/>
                            {`${bitacora.getNombreRuta()}`}
                        </p>

                        { 
                            bitacora.attributes.tipoBitacora &&
                            <p>
                                <Icon name='circle' color="orange" size='small'/>
                                <span style={{fontWeight: "600"}}>Tipo: </span>{`${bitacora.attributes.tipoBitacora }`}
                            </p>
                        }

                        <p>
                            {bitacora.getOperador() ? bitacora.getOperador().nombre : 'Sin definir'} ({bitacora.getProveedor().nombre})
                        </p>
                    </Table.Cell>
                    <Table.Cell><Icon name='user' color='blue'/>{bitacora.numeroPersonas}</Table.Cell>
                    <Table.Cell>
                        {bitacora.getUnidad() ? `U${bitacora.getUnidad().id} - ${bitacora.getUnidad().tipo}` : 'Sin definir'}
                    </Table.Cell>
                    <Table.Cell>
                        <Icon name={bitacora.confirmado ? 'checkmark' : 'remove'}
                              color={bitacora.confirmado ? 'green' : 'red'}/>
                    </Table.Cell>
                    <Table.Cell>
                        <Icon name={bitacora.terminado ? 'checkmark' : 'remove'}
                              color={bitacora.terminado ? 'green' : 'red'}/>
                    </Table.Cell>
                    <Table.Cell>
                        <Icon name={bitacora.pagarServicio ? 'money' : 'ban'}
                              color={bitacora.pagarServicio ? 'green' : 'red'}/>
                    </Table.Cell>
                    <Table.Cell>
                        {bitacora.pagoOperador}
                    </Table.Cell>
                    <Table.Cell>
                        {bitacora.pagoAliado}
                    </Table.Cell>
                    <Table.Cell>
                        {permisos?.verFacturacion === true ? bitacora.precioCliente : '---'}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                        {permisos?.cambiarPrecios === true  && permisosPrecios === 3966 &&
                            <Button basic
                                    color={cambioIndividualPrecio.bitacora && bitacora.id === cambioIndividualPrecio.bitacora.id ? "green" : "grey"}
                                    icon={'money bill alternate outline'}
                                    onClick={() => onIniciarCambioPrecio(bitacora)}/>
                        }

                        {permisos.cambiarPrecios === true  && permisosPrecios === 1445 && bitacora.attributes.excepcion === true &&
                            <Button basic
                                    color={cambioIndividualPrecio.bitacora && bitacora.id === cambioIndividualPrecio.bitacora.id ? "green" : "grey"}
                                    icon={'money bill alternate outline'}
                                    onClick={() => onIniciarCambioPrecio(bitacora)}/>
                        }
                    </Table.Cell>
                </Table.Row>
            )
        })
    }
}

DetalleBitacoras.propTypes = {
    bitacoras: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onIniciarCambioPrecio: PropTypes.func.isRequired
}

export default DetalleBitacoras
