import React from "react";
import { Statistic, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";

import { porcentaje } from "../../helpers";

const IndicadoresGeneral = ({
  suma,
  confirmados,
  sinConfirmar,
  aliados,
  terminados,
}) => {
  const total = suma + aliados + terminados;

  return (
    <Segment textAlign="center">
      <Statistic size="mini">
        <Statistic.Label>Total</Statistic.Label>
        {total ? (
          <Statistic.Value>{total}</Statistic.Value>
        ) : (
          <Statistic.Value>0</Statistic.Value>
        )}
      </Statistic>
      <Statistic size="mini">
        <Statistic.Label>Suma</Statistic.Label>
        {suma ? (
          <Statistic.Value>{suma}</Statistic.Value>
        ) : (
          <Statistic.Value>0</Statistic.Value>
        )}
      </Statistic>
      <Statistic size="mini">
        <Statistic.Label>Aliados</Statistic.Label>
        {aliados ? (
          <Statistic.Value>{aliados}</Statistic.Value>
        ) : (
          <Statistic.Value>0</Statistic.Value>
        )}
      </Statistic>
      <Statistic size="mini">
        <Statistic.Label>Confirmados</Statistic.Label>
        {confirmados ? (
          <Statistic.Value>
            {confirmados} ({porcentaje(confirmados, total)}%)
          </Statistic.Value>
        ) : (
          <Statistic.Value>0 (0%)</Statistic.Value>
        )}
      </Statistic>
      <Statistic size="mini">
        <Statistic.Label>Sin confirmar</Statistic.Label>
        {sinConfirmar ? (
          <Statistic.Value>
            {sinConfirmar} ({porcentaje(sinConfirmar, total)}%)
          </Statistic.Value>
        ) : (
          <Statistic.Value>0 (0%)</Statistic.Value>
        )}
      </Statistic>
      <Statistic size="mini">
        <Statistic.Label>Finalizados Suma</Statistic.Label>
        {terminados ? (
          <Statistic.Value>
            {terminados} ({porcentaje(terminados, total)}%)
          </Statistic.Value>
        ) : (
          <Statistic.Value>0 (0%)</Statistic.Value>
        )}
      </Statistic>
    </Segment>
  );
};

IndicadoresGeneral.propTypes = {
  confirmados: PropTypes.number,
  sinConfirmar: PropTypes.number,
  terminados: PropTypes.number,
};

export default IndicadoresGeneral;
