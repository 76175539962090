import {connect} from "react-redux";

import {
    requestSanitizaciones,
    invalidarSanitizaciones,
} from "../../actions/sanitizaciones";

import AdministrarSanitizaciones   from "../../components/Sanitizaciones/AdministrarSanitizaciones"
import {requestClientes} from "../../actions/clientes";
import {requestUnidades} from "../../actions/unidades";


const mapStateToProps = ({ clientes, unidades, sanitizaciones }) => {
  return { clientes, unidades, sanitizaciones };
};

const mapActionToProps = {
  requestClientes,
  requestUnidades,
  requestSanitizaciones,
  invalidarSanitizaciones,
};

export default connect(mapStateToProps, mapActionToProps)(AdministrarSanitizaciones)
