import React from 'react'
import {Container, Image, Header} from 'semantic-ui-react'

import logo from '../../styles/iconos/logo_suma_4.png'

const Home = () => {

    var usuarioId = JSON.parse(sessionStorage.getItem("usuario"));

    return (
        <Container style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '5%'}}>
            <Image src={logo}  verticalAlign='bottom' size='medium'/>
            <Header as='h3' style={{color: '#5e6867'}}>{usuarioId.nombre}</Header>
        </Container>
    )
    
}

export default Home