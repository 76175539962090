import React, { Component } from "react";
import { Form, Select, Button, Message } from "semantic-ui-react";
import axios from "axios";

import { ENDPOINT_CLIENTES, ENDPOINT_CONTACTOS_AGREGAR } from '../../constants/endpoints'


class ContactoExterno extends Component {
    constructor(props){
        super(props)

        this.initialValues = ({
            loading: false,
            nombre: '',
            puesto: '',
            telefono: '', 
            correo: '', 
            activo: 0,
            registrado: false,
            idCliente: null,
            datosFaltantes: false,
            orden: 0,
            cliente: [],
            error: ''
        })

        this.state = ({
            ...this.initialValues
        })
    }

    getClientes(){
        const url = `${ENDPOINT_CLIENTES}?activo=true&id_categoria=1&or(id=5)&sort(+nombre_empresa)`;

        this.setState({
            loading: true
        })

        return(
            axios.get(url)
                .then((response) => {
                    if(response.status === 200){
                        const cliente = response.data.map(cliente => {
                            return { key: cliente.data.id, text: cliente.data.attributes.nombreEmpresa, value: cliente.data.id }
                        })

                        return this.setState({
                            cliente: cliente,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
            })
        )
    }

    componentDidMount(){
        this.getClientes()
    }

    handleChangeValue = (e, data) => {
        this.setState({ [data.name]: data.value })
    }

    onSubmit = () => {
        const { idCliente, nombre, telefono, correo, puesto, orden } = this.state

        if(!nombre || !telefono || !correo || !idCliente || !puesto || !orden){
            this.setState({datosFaltantes: true})
            return
        }else{
            this.setState({
                loading: true,
                datosFaltantes: false
            })

            const url = `${ENDPOINT_CONTACTOS_AGREGAR}?externo=${1}&nombre=${nombre.toLocaleUpperCase()}&celular=${telefono}&correo=${correo}&id_cliente=${idCliente}&puesto=${puesto}&orden=${orden}`;

            return(
                axios.post(url)
                    .then((response) => {
                        if(response.status === 200 && response.data.success === true){
                            return this.setState({
                                registrado: response.data.success,
                                loading: false,
                            });
                        }

                        if(response.status === 200 && response.data.success === false){
                            return this.setState({
                                registrado: response.data.success,
                                error: response.data.mensaje,
                                loading: false,
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .then(() => {
                        setTimeout(() => {
                            this.resetState()
                          }, 3000)
                    })
            )
        }
    }

    resetState = () => {
        return (
            this.setState({ 
                ...this.initialValues,
            }),
            this.getClientes()
        )
    }

  render() {

    const { cliente, registrado, idCliente, nombre, puesto, telefono, correo, orden, datosFaltantes, error } = this.state;

    return (
        <>
            <Form className='form-evaluacion-especial'>
                <Form.Group widths='equal' className='form-group-inputs'>
                    <Form.Field
                        label='Empresa a la que se asignara el contacto'
                        control={Select}
                        options={cliente}
                        placeholder='Cliente'
                        search
                        value={idCliente}
                        name='idCliente'
                        onChange={this.handleChangeValue}
                        loading={this.state.loading}
                    />
                    <Form.Input
                        fluid 
                        label='Nombre' 
                        placeholder='Ingresa el nombre' 
                        name='nombre'
                        value={nombre}
                        type='text'
                        required
                        onChange={this.handleChangeValue}
                    />
                    <Form.Input 
                        fluid 
                        label='Puesto' 
                        placeholder='Ingresa el puesto' 
                        name='puesto'
                        value={puesto}
                        type='text'
                        required
                        onChange={this.handleChangeValue}
                    />
                </Form.Group>
                <Form.Group widths='equal' className='form-group-inputs'>
                    <Form.Input 
                        fluid 
                        label='Teléfono' 
                        placeholder='Ingresa el número de teléfono' 
                        name='telefono'
                        value={telefono}
                        type='text'
                        required
                        onChange={this.handleChangeValue}
                    />
                    <Form.Input 
                        fluid 
                        label='Correo' 
                        placeholder='Ingresa el correo' 
                        name='correo'
                        value={correo}
                        type='email'
                        required
                        onChange={this.handleChangeValue}
                    />
                    <Form.Input 
                        fluid 
                        label='Prioridad de contacto' 
                        placeholder='Ingresa el número de prioridad' 
                        name='orden'
                        value={orden}
                        type='number'
                        required
                        onChange={this.handleChangeValue}
                    />
                </Form.Group>
                <Button loading={this.state.loading} className='form-button' color='orange' onClick={this.onSubmit}>Guardar</Button>
                <Button loading={this.state.loading} className='form-button'  type="reset" onClick={this.resetState}>Limpiar</Button>
            </Form>

            { registrado === true && !error ?
                <Message positive>
                    <Message.Header> Contacto registrado correctamente </Message.Header>
                </Message>
                :
                <></>
            }

            { registrado === false && error ?
                <Message error>
                    <Message.Header> {error} </Message.Header>
                </Message>
                :
                <></>
            }

            { datosFaltantes && registrado === false ?
                <Message info>
                    <Message.Header> Datos faltantes, ingresa toda la información </Message.Header>
                </Message>
                :
                <></>
            } 
        </>
    );
  }
}

export default ContactoExterno;