import React from "react";
import { Segment, Form, Button, List, Grid, Divider } from "semantic-ui-react";
import moment from "moment";
import PropTypes from "prop-types";
import axios from 'axios'

import { ENDPOINT_OPERADORES_DISPONIBLES } from '../../constants/endpoints'

import {
  extraerOpcionesProveedores,
  extraerOpcionesClientes,
  extraerOpcionesRutas,
  extraerOpcionesEstructuras,
  validarStorage,
  getDias,
} from "../../helpers";

import BaseComponent from "../BaseComponent";
import Resultados from './Resultados'
import ListadoBitacorasProgramadas from './ListadoBitacorasProgramadas'


class FormaProgramacionServicios extends BaseComponent {
  constructor(props) {
    super(props);

    this.invalidDays = {
      lunes: true,
      martes: true,
      miercoles: true,
      jueves: true,
      viernes: true,
      sabado: true,
      domingo: true,
    };

    this.days = {
      lunes: false,
      martes: false,
      miercoles: false,
      jueves: false,
      viernes: false,
      sabado: false,
      domingo: false,
    };

    this.state = {
      loading: false,
      //desde: moment().startOf("week").format("YYYY-MM-DD"),
      //hasta: moment().endOf("month").format("YYYY-MM-DD"),
      proveedor: 1,
      cliente: -1,
      ruta: -1,
      estructura: -1,
      operador: -1,
      force: false,
      valid: false,
      invalidDays: {
        lunes: true,
        martes: true,
        miercoles: true,
        jueves: true,
        viernes: true,
        sabado: true,
        domingo: true,
      },
      received_date: null,
    };
  }

  componentWillMount() {
    this.props.requestClientes([
      "activo=true",
      "id_categoria=1",
      "or(id=5)",
      "sort(+nombre_empresa)",
    ]);

    this.props.requestProveedores([
      "activo=true",
      "id_categoria=8",
      "or(id=1)",
      "sort(+empresa)",
    ]);

    // this.props.requestOperadores(["activo=true", "sort(+nombre)"]);
    //this.props.requestOperadores(["activo=true", "sort(+nombre)"]);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.received_date !== nextProps.programacion.received_date) {
      this.setState({
        received_date: nextProps.programacion.received_date,
      });

      const { proveedor, operador, desde, hasta } = this.state;

      const params = [
        `hora_arranque=ge=${desde} 00:00:00`,
        `hora_arranque=le=${hasta} 23:59:59`,
        `id_operador=${operador}`,
        `id_proveedor=${proveedor}`,
        "sort(+hora_arranque)",
      ];

      this.props.requestBitacoras(params);
    }
  }

  triggerClienteChange = (event, data) => {
    const { invalidarRutas, invalidarEstructuras, requestRutas } = this.props;

    invalidarRutas();
    invalidarEstructuras();

    this.setState({
      ruta: -1,
      estructura: -1,
    });

    requestRutas([`id_cliente=${data.value}`, "sort(+nombre)", "activa=true"]);

    this.setState({ invalidDays: this.invalidDays });
    this.setState({ ...this.days });

    this.onChangeValue(event, data);
  };

  triggerRutaChange = (event, data) => {
    this.props.invalidarEstructuras();

    this.setState({
      estructura: -1,
    });

    this.props.requestEstructuras([
      `id_ruta=${data.value}`,
      "sort(+hora_inicio)",
      "activa=true",
    ]);

    this.setState({ invalidDays: this.invalidDays });
    this.setState({ ...this.days });

    this.onChangeValue(event, data);
  };

  triggerProveedorChange = (event, data) => {
    this.setState({
      operador: -1,
    });

    if (this.state.proveedor !== 1) {
      this.props.requestOperadoresSubcontratados([
        "activo=true",
        "sort(+nombre)",
        `id_proveedor=${data.value}`,
      ]);
    }

    this.onChangeValue(event, data);
  };

  triggerEstructuraChange = (event, data) => {
    const { estructuras } = this.props;

    const estructura = estructuras.all.filter(function (e) {
      return e.id === data.value;
    });

    let invalidDays = {
      lunes: true,
      martes: true,
      miercoles: true,
      jueves: true,
      viernes: true,
      sabado: true,
      domingo: true,
    };

    let days = {
      lunes: false,
      martes: false,
      miercoles: false,
      jueves: false,
      viernes: false,
      sabado: false,
      domingo: false,
    };

    estructura[0].dias.map(function (dia) {
      switch (dia) {
        case 1:
          invalidDays.lunes = false;
          days.lunes = true;
          break;
        case 2:
          invalidDays.martes = false;
          days.martes = true;
          break;
        case 3:
          invalidDays.miercoles = false;
          days.miercoles = true;
          break;
        case 4:
          invalidDays.jueves = false;
          days.jueves = true;
          break;
        case 5:
          invalidDays.viernes = false;
          days.viernes = true;
          break;
        case 6:
          invalidDays.sabado = false;
          days.sabado = true;
          break;
        case 7:
          invalidDays.domingo = false;
          days.domingo = true;
          break;
        default:
          break;
      }
      return dia;
    });

    this.setState({ invalidDays: invalidDays });
    this.setState({ ...days });

    this.onChangeValue(event, data);
  };

  onSubmit = () => {
    const { estructura, proveedor, operador, desde, hasta, cliente, force } =
      this.state;

    const { login } = this.props;

    const payload = {
      idEstructura: estructura,
      idProveedor: proveedor,
      idOperador: operador,
      idCliente: cliente,
      fechaInicial: desde,
      fechaFinal: hasta,
      dias: getDias(this.state),
      usuarioId: login.user.id,
      force: force,
    };
    
      this.props.requestProgramarServicios({
        params: payload,
      });
    
    
  };

  getOperadores = () => {   
    const desde = this.state.desde?.replaceAll('-', '/')
    const hasta = this.state.hasta?.replaceAll('-', '/')

    const url = `${ENDPOINT_OPERADORES_DISPONIBLES}?activo=true&fecha_inicio=${desde}&fecha_fin=${hasta}`;
    this.setState({
        loading: true,
        operador: ''
    })

    return(
        axios.get(url)
            .then((response) => {
                if(response.status === 200){

                    const respuesta = response.data.data
                    respuesta.sort( (a, b) => {
                      if (a.NOMBRE.toLowerCase() < b.NOMBRE.toLowerCase()) {
                        return -1;
                      }
                      if (a.NOMBRE.toLowerCase() > b.NOMBRE.toLowerCase()) {
                        return 1;
                      }
                      return 0;
                    });

                    const operadores = respuesta.map(operador => {
                      return { key: operador.ID, text: operador.NOMBRE, value:  operador.ID }
                    })

                    return this.setState({
                        operadores: operadores,
                        loading: false
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() => {
              setTimeout(() => {
                  this.setState({ loading: false });
              }, 2000)
          })
    )
  }

  onChangeDateValue = (event, data) => {
    this.setState({ [data.name]: data.value }, () => {
      this.getOperadores()
  });
  }


  render() {
    const {
      desde,
      hasta,
      proveedor,
      cliente,
      ruta,
      estructura,
      operadores,
      operador,
      force,
      loading
    } = this.state;

    const {
      clientes,
      rutas,
      estructuras,
      proveedores,
      //operadores,
      // subcontratados,
      login: { user },
      arrayOperadores,
      arrayUnidades,
      programacion,
      bitacoras
    } = this.props;

    //const opcionesOperadores = proveedor === 1 ? operadores : subcontratados;
    const permisos = validarStorage(user).permisos;
    const hoy = moment().format('DD/MMMM/YYYY');

    return (
      <Segment color="orange" container="true">
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column width={11}>
              <Form form={"formaProgramacion"} onSubmit={this.onSubmit}>
                <Form.Group widths="equal">
                  <Form.Input
                    name="desde"
                    label="Desde"
                    type="date"
                    value={desde}
                    onChange={this.onChangeDateValue}
                  />
                  <Form.Input
                    name="hasta"
                    label="Hasta"
                    type="date"
                    value={hasta}
                    onChange={this.onChangeDateValue}
                  />

                  <Form.Select
                    name="cliente"
                    label="Cliente"
                    fluid
                    search
                    selection
                    loading={clientes.fetching}
                    options={clientes.all.map(extraerOpcionesClientes)}
                    value={cliente}
                    onChange={this.triggerClienteChange}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Select
                    name="ruta"
                    label="Ruta"
                    fluid
                    search
                    selection
                    loading={rutas.fetching}
                    options={rutas.all.map(extraerOpcionesRutas)}
                    value={ruta}
                    onChange={this.triggerRutaChange}
                  />

                  <Form.Select
                    name="estructura"
                    label="Estructura"
                    fluid
                    search
                    selection
                    loading={estructuras.fetching}
                    options={estructuras.all.map(extraerOpcionesEstructuras)}
                    value={estructura}
                    onChange={this.triggerEstructuraChange}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Días</label>
                    <Form.Group widths="equal">
                      <Form.Checkbox
                        name="lunes"
                        label="L"
                        checked={this.state.lunes}
                        disabled={this.state.invalidDays.lunes}
                        onChange={this.onChangeValue}
                      />{" "}
                      &nbsp;
                      <Form.Checkbox
                        name="martes"
                        label="M"
                        checked={this.state.martes}
                        disabled={this.state.invalidDays.martes}
                        onChange={this.onChangeValue}
                      />{" "}
                      &nbsp;
                      <Form.Checkbox
                        name="miercoles"
                        label="M"
                        checked={this.state.miercoles}
                        disabled={this.state.invalidDays.miercoles}
                        onChange={this.onChangeValue}
                      />{" "}
                      &nbsp;
                      <Form.Checkbox
                        name="jueves"
                        label="J"
                        checked={this.state.jueves}
                        disabled={this.state.invalidDays.jueves}
                        onChange={this.onChangeValue}
                      />{" "}
                      &nbsp;
                      <Form.Checkbox
                        name="viernes"
                        label="V"
                        checked={this.state.viernes}
                        disabled={this.state.invalidDays.viernes}
                        onChange={this.onChangeValue}
                      />{" "}
                      &nbsp;
                      <Form.Checkbox
                        name="sabado"
                        label="S"
                        checked={this.state.sabado}
                        disabled={this.state.invalidDays.sabado}
                        onChange={this.onChangeValue}
                      />{" "}
                      &nbsp;
                      <Form.Checkbox
                        name="domingo"
                        label="D"
                        checked={this.state.domingo}
                        disabled={this.state.invalidDays.domingo}
                        onChange={this.onChangeValue}
                      />
                    </Form.Group>
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Select
                    name="proveedor"
                    label="Proveedor"
                    fluid
                    search
                    selection
                    loading={proveedores.fetching}
                    options={proveedores.all.map(extraerOpcionesProveedores)}
                    value={proveedor}
                    onChange={this.triggerProveedorChange}
                  />

                  <Form.Select
                    name="operador"
                    label="Operador"
                    fluid
                    search
                    selection
                    loading={loading}
                    options={operadores}
                    value={operador}
                    onChange={this.onChangeValue}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  {permisos?.forzarTransferencia === true && (
                    <Form.Field>
                      <Form.Checkbox
                        toggle
                        name="force"
                        label="Forzar programación"
                        checked={force}
                        onChange={this.onChangeValue}
                      />
                    </Form.Field>
                  )}
                  <Form.Field>
                    <label>&nbsp;</label>
                    <Button
                      color={"orange"}
                      fluid
                      type="submit"
                      disabled={this.props.programacion.fetching}
                      loading={this.props.programacion.fetching}
                    >
                      Programar
                    </Button>
                  </Form.Field>
                </Form.Group>
              </Form>
              <Divider />
              <Resultados programacion={programacion}/>
              <Divider />
              <ListadoBitacorasProgramadas bitacoras={bitacoras} programacion={programacion}/>
            </Grid.Column>
            <Grid.Column width={5}>
            <h4 style={{textAlign: "center"}}>Datos actualizados al {hoy}</h4>
            { arrayOperadores ?
              <Grid.Row>
                <h4 style={{textAlign: "left", textDecoration: 'underline'}}>Operadores no disponibles</h4>
                <List divided relaxed style={{fontSize: '.89rem'}}>
                  {arrayOperadores.map(item => {
                    return (
                    <List.Item key={item.ID}>
                        <List.Icon name='user' size='small' color='orange' verticalAlign='middle' />
                        <List.Content>
                          <List.Header>{item.NOMBRE} &nbsp;
                            <List.Icon name='long arrow alternate right' size='small' color='grey' verticalAlign='middle'/> &nbsp;
                            <span style={{color: '#db3b3b', fontWeight: '500'}}>{item.ESTATUS}</span></List.Header>
                        </List.Content>
                      </List.Item>
                    )})
                  }
                </List>
            </Grid.Row>:<></>
            }
            <Divider hidden />
            { arrayUnidades ?
            <Grid.Row >
                <h4 style={{textAlign: "left", textDecoration: 'underline'}}>Unidades no disponibles</h4>
                <List divided relaxed style={{fontSize: '.89rem'}}>
                  {arrayUnidades.map(item => {
                    return (
                      <List.Item key={item.ID}>
                        <List.Icon name='bus' size='small' color='orange' verticalAlign='middle' />
                        <List.Content>
                          <List.Header>U-{item.ID} &nbsp; | &nbsp; {item.DESCRIPCION} &nbsp;
                            <List.Icon name='long arrow alternate right' size='small' color='grey' verticalAlign='middle'/> &nbsp;
                            <span style={{color: '#db3b3b', fontWeight: '500'}}>{item.U_DISPONIBLE}</span></List.Header>
                        </List.Content>
                      </List.Item>
                    )})
                  }
                </List>
              </Grid.Row>:<></>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

FormaProgramacionServicios.propTypes = {
  clientes: PropTypes.object.isRequired,
  rutas: PropTypes.object.isRequired,
  estructuras: PropTypes.object.isRequired,
  proveedores: PropTypes.object.isRequired,
  operadores: PropTypes.object.isRequired,
  subcontratados: PropTypes.object.isRequired,
  programacion: PropTypes.object.isRequired,
  login: PropTypes.object.isRequired,
  invalidarRutas: PropTypes.func.isRequired,
  invalidarEstructuras: PropTypes.func.isRequired,
  requestRutas: PropTypes.func.isRequired,
  requestEstructuras: PropTypes.func.isRequired,
  requestOperadoresSubcontratados: PropTypes.func.isRequired,
  requestProgramarServicios: PropTypes.func.isRequired,
  requestClientes: PropTypes.func.isRequired,
  requestProveedores: PropTypes.func.isRequired,
  requestOperadores: PropTypes.func.isRequired,
  requestBitacoras: PropTypes.func.isRequired,
};

export default FormaProgramacionServicios;
