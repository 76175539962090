import moment from "moment";
import React from "react";
import {
  Button,
  Form,
  Modal,
  Segment,
  TextArea,
  Message,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import axios from "axios";

import BaseComponent from "../BaseComponent";

import {
  ENDPOINT_VALIDAR_INCIDENTE,
  ENDPOINT_UPDATE_INCIDENTE,
  ENDPOINT_INCIDENTE_INCLEMENCIAS_CONDICIONES,
} from "../../constants/endpoints";

import {
  extraerOpcionesInclemencias,
  extraerOpcionesCondiciones,
  extraerOpcionesCausas,
  extraerOpcionesEfectos,
  validarStorage,
} from "../../helpers";


class ModalIncidenteServicio extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      fecha: moment().format("YYYY-MM-DD"),
      afecto_servicio: true,
      responsabilidad_suma: false,
      facturar: true,
      causa_id: -1,
      efecto_id: -1,
      detalle: "",
      nota_facturacion: "",
      tieneIncidente: [],
      mensajeUpdate: false,
      id_bitacora: "",
      id_incidente_bitacora: "",
      detalle_editado: "",
      nota_factura: "",
      email: {},
      loading: false,
      incidente_uso: false,
      mensajeEnUso: "",
      id_unidad: "",
      inclemencias_id: -1,
      condiciones_id: -1
    };
  }

  componentDidMount() {
    this.props.onFetchCausas();
    this.props.onFetchEfectos();

    const url = `${ENDPOINT_VALIDAR_INCIDENTE}?id_bitacora=${this.props.incidente.bitacora.id}`;
    axios
      .post(url)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data.success === true) {
            return this.setState({
              tieneIncidente: response.data.data,
              incidente_uso: response.data.data.incidente.incidente_uso
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

      const urlIncicente = `${ENDPOINT_INCIDENTE_INCLEMENCIAS_CONDICIONES}`;
      axios
        .get(urlIncicente)
        .then((response) => {
          if (response.status === 200) {
            return this.setState({
              inclemencias: response.data.inclemencias,
              condiciones: response.data.condiciones
            })
          }
        })
        .catch((error) => {
          console.log(error);
        });
  }
 
  onSubmit = () => {
    const {
      incidente: { bitacora },
    } = this.props;
    const idUsuario = this.props.usuario.id;

    this.setState({
      loading: true
    })
   
    
    const {
      causa_id,
      efecto_id,
      afecto_servicio,
      responsabilidad_suma,
      facturar,
      detalle,
      nota_facturacion,
      fecha,
      inclemencias_id,
      condiciones_id,
      id_unidad = bitacora.data.relations.unidad?.data.id
      
      
    } = this.state;

    this.props.onIncidenteServicio(bitacora.id, {
      detalle: detalle + "- Registró: " + this.props.usuario.nombre,
      nota_facturacion: nota_facturacion + "- Registró: " + this.props.usuario.nombre,
      usuario_id: idUsuario,
      causa_id: causa_id,
      efecto_id: efecto_id,
      responsabilidad_suma: responsabilidad_suma,
      afecto_servicio: afecto_servicio,
      facturar_servicio: facturar,
      fecha: fecha +' 00:00:00',
      inclemencias_id: inclemencias_id,
      condiciones_id: condiciones_id,
      id_unidad: bitacora.data.relations.unidad?.data.id
    });
  }

  detalleHandleChange = (event) => {
    this.setState({
      detalle: event.target.value,
    });
  };

  nota_facturacionHandleChange = (event) => {
    this.setState({
      nota_facturacion: event.target.value,
    });
  };

  onUpdate = () => {
    const idUsuario = this.props.usuario.id;

    this.setState({
      detalle: this.state.detalle,
      nota_facturacion: this.state.nota_facturacion,
      id_bitacora: this.props.incidente.bitacora.id,
      id_incidente_bitacora: this.state.tieneIncidente.incidente.id_incidente,
      detalle_editado:
        this.state.tieneIncidente.incidente.detalle +
        " / " +
        this.state.detalle +
        " - Editó: " +
        this.props.usuario.nombre,
      nota_factura:
        this.state.tieneIncidente.incidente.nota_facturacion +
        " / " +
        this.state.nota_facturacion +
        " - Editó: " +
        this.props.usuario.nombre,
      causa_id_editado: this.state.causa_id,
      efecto_id_editado: this.state.efecto_id,
      afecto_servicio_editado: this.state.afecto_servicio,
      responsabilidad_suma_editado: this.state.responsabilidad_suma,
      facturar_editado: this.state.facturar,
      loading: true,
      mensajeEnUso: this.state.mensajeEnUso,
      // inclemencias_id: this.state.inclemencias_id,
      // condiciones_id: this.state.condiciones_id
    });

    const id_bitacora = this.props.incidente.bitacora.id;
    const id_incidente_bitacora = this.state.tieneIncidente.incidente.id_incidente;
    const detalle = this.state.tieneIncidente.incidente.detalle + " / " + this.state.detalle + " - Editó: " + this.props.usuario.nombre;
    const nota_factura = this.state.tieneIncidente.incidente.nota_facturacion + " / " + this.state.nota_facturacion + " - Editó: " + this.props.usuario.nombre;
    const causa_id = this.state.causa_id;
    const efecto_id = this.state.efecto_id;
    const afecto_servicio = this.state.afecto_servicio;
    const responsabilidad = this.state.responsabilidad_suma;
    const facturar_editado = this.state.facturar;
    const force = 1;
    const id_unidad = this.state.id_unidad
    //const mensajeEnUso = this.state.mensajeEnUso;
    // const inclemencias_id = this.state.inclemencias_id;
    // const condiciones_id = this.state.condiciones_id;

    // const url = `${ENDPOINT_UPDATE_INCIDENTE}?id_usuario=${idUsuario}&id_bitacora=${id_bitacora}&id_incidente=${id_incidente_bitacora}&detalle=${detalle}&nota_facturacion=${nota_factura}&id_inclemencia=${inclemencias_id}&id_camino=${condiciones_id}&id_causa=${causa_id}&id_efecto=${efecto_id}&afecto_servicio=${afecto_servicio}&responsabilidad=${responsabilidad}&facturar_servicio=${facturar_editado}&force=${force}`;
    const url = `${ENDPOINT_UPDATE_INCIDENTE}?id_usuario=${idUsuario}&id_bitacora=${id_bitacora}&id_incidente=${id_incidente_bitacora}&detalle=${detalle}&nota_facturacion=${nota_factura}&id_causa=${causa_id}&id_efecto=${efecto_id}&afecto_servicio=${afecto_servicio}&responsabilidad=${responsabilidad}&facturar_servicio=${facturar_editado}&force=${force}&id_unidad=${id_unidad}`;

    axios
      .post(url)
      .then((response) => {
        const successMessage = response.data
        const successData = response.data.data
        const validandoData = successData ? successData : successMessage;

        if (successMessage) {
          this.setState({
            mensajeEnUso: successMessage.mensaje,
            loading: false,
          });
        }

        if (successData.success && successData.success === true) {
          this.setState({
            mensajeUpdate: response.data.data.success,
            email: response.data.email,
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onChangeInput = (event, data) => {
    if (data) {
      this.setState({
        [data.name]: data.value
      })
    } else {
      this.setState({
        [event.target.name]: event.target.value
      })
    }
  }

  render() {
    const {
      incidente: { bitacora, error, done }, usuario,
      causas, efectos,
    } = this.props;
    const { onFinalizarIncidente } = this.props;
    const { fecha, inclemencias_id, condiciones_id, causa_id, efecto_id, tieneIncidente, incidente_uso, 
      mensajeUpdate, email, mensajeEnUso, loading, inclemencias, condiciones } =
      this.state;
    const permisos = validarStorage(usuario).permisos;

    if (!bitacora) {
      return null;
    }

    const opcionesCausas = causas.all.map(extraerOpcionesCausas).sort( (a, b) => {
      if (a.text.toLowerCase() < b.text.toLowerCase()) {
        return -1;
      }
      if (a.text.toLowerCase() > b.text.toLowerCase()) {
        return 1;
      }
      return 0;
    })

    const opcionesEfectos = efectos.all.map(extraerOpcionesEfectos).sort( (a, b) => {
      if (a.text.toLowerCase() < b.text.toLowerCase()) {
        return -1;
      }
      if (a.text.toLowerCase() > b.text.toLowerCase()) {
        return 1;
      }
      return 0;
    })

    const opcionesInclemencias = inclemencias?.map(extraerOpcionesInclemencias).sort( (a, b) => {
      if (a.text.toLowerCase() < b.text.toLowerCase()) {
        return -1;
      }
      if (a.text.toLowerCase() > b.text.toLowerCase()) {
        return 1;
      }
      return 0;
    })

    const opcionesCondiciones = condiciones?.map(extraerOpcionesCondiciones).sort( (a, b) => {
      if (a.text.toLowerCase() < b.text.toLowerCase()) {
        return -1;
      }
      if (a.text.toLowerCase() > b.text.toLowerCase()) {
        return 1;
      }
      return 0;
    })

    return (
      <>
      {(mensajeEnUso === true ) || (incidente_uso === 1) ? (
          <Modal open={true} size="large">
            <Modal.Header>
              <p>Bitácora: {bitacora.id}</p>
              <p>{bitacora.getNombreRuta()}</p>
              <p>
                {bitacora.getOperador()
                  ? bitacora.getOperador().nombre
                  : "Sin definir"}
              </p>
            </Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Message warning>
                  <Message.Header>Bitácora en uso</Message.Header>
                  <p>{mensajeEnUso}</p>
                  <p>Intentalo en 5 min. más</p>
                </Message>
              </Modal.Description>
              <Button.Group style={{ marginTop: '20px' }}>
                <Button onClick={() => onFinalizarIncidente()}>Cerrar</Button>
              </Button.Group>
            </Modal.Content>
          </Modal>
        ):<></>
      }
      <Modal open={true} size="large">
        <Modal.Header>
          {tieneIncidente.success === true ? (
            <p>Editar incidente en bitácora: {bitacora.id}</p>
          ) : (
            <p>Registrar incidente en bitácora: {bitacora.id}</p>
          )}
          {mensajeUpdate === false && (
            <>
              <p>{bitacora.getNombreRuta()}</p>
              <p>
                {bitacora.getOperador()
                  ? bitacora.getOperador().nombre
                  : "Sin definir"}
              </p>
            </>
          )}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {error && (
              <Message negative>
                <Message.Header>{error.mensaje}</Message.Header>
                <p>Ingrese todos los datos</p>
              </Message>
            )}
            {tieneIncidente.length === 0 ? 
              (permisos?.crearIncidentes === true ?
                (incidente_uso !== 1 && mensajeUpdate === false  && !done ?
                  <Segment>
                    <Form from={"formaIncidenteServicio"}>
                      <Form.Group>
                        <Form.Field>
                          <Form.Group widths="sixteen" style={{ justifyContent: "center", marging: "1rem" }}>
                            <Form.Field>
                              <Form.Input
                                name="fecha"
                                type="date"
                                label="Fecha"
                                value={fecha}
                                onChange={this.onChangeValue}
                              />
                            </Form.Field>
                            <Form.Field>
                              <label>&nbsp;</label>
                              <Form.Checkbox
                                name="afecto_servicio"
                                label="Afectó el servicio?"
                                checked={this.state.afecto_servicio}
                                onChange={this.onChangeValue}
                              />
                            </Form.Field>
                            <Form.Field>
                              <label>&nbsp;</label>
                              <Form.Checkbox
                                name="responsabilidad_suma"
                                label="Es responsabilidad de Suma?"
                                checked={this.state.responsabilidad_suma}
                                onChange={this.onChangeValue}
                              />
                            </Form.Field>
                            <Form.Field>
                              <label>&nbsp;</label>
                              <Form.Checkbox
                                name="facturar"
                                label="Se factura el servicio?"
                                checked={this.state.facturar}
                                onChange={this.onChangeValue}
                              />
                            </Form.Field>
                          </Form.Group>
                        </Form.Field>
                      </Form.Group>

                      <Form.Group widths="equal">
                        <Form.Select
                          name="causa_id"
                          label="Causa"
                          fluid
                          search
                          selection
                          loading={causas.fetching}
                          options={opcionesCausas}
                          value={causa_id}
                          onChange={this.onChangeValue}
                        />

                        <Form.Select
                          name="efecto_id"
                          label="Efecto"
                          fluid
                          search
                          selection
                          loading={efectos.fetching}
                          options={opcionesEfectos}
                          value={efecto_id}
                          onChange={this.onChangeValue}
                        />
                      </Form.Group>

                      { causa_id === 35 || causa_id === 3 ||
                        causa_id === 36 || causa_id === 5 || causa_id === 34 ?
                        <Form.Group widths="equal">
                        <Form.Select
                          name="inclemencias_id"
                          label="Inclemencias del tiempo"
                          fluid
                          search
                          selection
                          //loading={inclemencias.fetching}
                          options={opcionesInclemencias}
                          value={inclemencias_id}
                          onChange={this.onChangeValue}
                        />

                        <Form.Select
                          name="condiciones_id"
                          label="Condiciones de camino"
                          fluid
                          search
                          selection
                          //loading={camino.fetching}
                          options={opcionesCondiciones}
                          value={condiciones_id}
                          onChange={this.onChangeValue}
                        />
                      </Form.Group>
                        :<></>
                      }

                      <Form.Group widths="equal">
                        <Form.Field required>
                          <label>Detalle</label>
                          <TextArea
                            label="Detalle"
                            name="detalle"
                            rows={3}
                            onChange={this.onChangeValue}
                          />
                        </Form.Field>

                        <Form.Field required>
                          <label>Nota para facturación</label>
                          <TextArea
                            label="Nota para facturación"
                            name="nota_facturacion"
                            rows={3}
                            onChange={this.onChangeValue}
                          />
                        </Form.Field>
                      </Form.Group>

                      <Form.Group widths="equal">
                        <Form.Field>
                          <Button.Group>
                            <Button onClick={() => onFinalizarIncidente()}>
                              Cancelar
                            </Button>
                            <Button.Or text="O" />
                            <Button
                              color="orange"
                              onClick={this.onSubmit}
                              loading={this.state.loading}
                            >
                              Registrar
                            </Button>
                          </Button.Group>
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </Segment>
                  :
                  <></>
                )
                :<>
                  <Message warning>
                    <Message.Header>Usuario sin permiso para crear incidentes</Message.Header>
                  </Message>
                  <Button.Group style={{ marginTop: '20px' }}>
                    <Button onClick={() => onFinalizarIncidente()}>Cerrar</Button>
                  </Button.Group>
                </>
              )
              :
              (permisos?.editarIncidentes === true && !done ?
                (incidente_uso !== 1 && mensajeUpdate === false ?
                  <Segment>
                    <Form from={"formaIncidenteServicio"}>
                      <Form.Group>
                        <Form.Field>
                          <Form.Group widths="sixteen" style={{ justifyContent: "center", marging: "1rem" }}>
                            <Form.Field>
                              <Form.Input
                                name="fecha"
                                type="text"
                                label="Fecha captura"
                                value={tieneIncidente.incidente ? tieneIncidente.incidente.fecha_captura:'' }
                                disabled
                                onChange={this.onChangeValue}
                              />
                            </Form.Field>
                            <Form.Field>
                              <label>&nbsp;</label>
                              <Form.Checkbox
                                name="afecto_servicio"
                                label="Afectó el servicio?"
                                disabled
                                checked={this.state.afecto_servicio}
                                onChange={this.onChangeValue}
                              />
                            </Form.Field>
                            <Form.Field>
                              <label>&nbsp;</label>
                              <Form.Checkbox
                                name="responsabilidad_suma"
                                disabled
                                label="Es responsabilidad de Suma?"
                                checked={this.state.responsabilidad_suma}
                                onChange={this.onChangeValue}
                              />
                            </Form.Field>
                            <Form.Field>
                              <label>&nbsp;</label>
                              <Form.Checkbox
                                name="facturar"
                                label="Se factura el servicio?"
                                disabled
                                checked={this.state.facturar}
                                onChange={this.onChangeValue}
                              />
                            </Form.Field>
                          </Form.Group>
                        </Form.Field>
                      </Form.Group>

                      <Form.Group widths="equal">
                        <Form.Select
                          name="causa_id"
                          label="Causa"
                          fluid
                          search
                          selection
                          disabled
                          loading={causas.fetching}
                          options={opcionesCausas}
                          value={tieneIncidente.incidente ? tieneIncidente.incidente.id_causa : ''}
                          onChange={this.onChangeValue}
                        />

                        <Form.Select
                          name="efecto_id"
                          label="Efecto"
                          fluid
                          search
                          selection
                          disabled
                          loading={efectos.fetching}
                          options={opcionesEfectos}
                          value={tieneIncidente.incidente ? tieneIncidente.incidente.id_efecto:''}
                          onChange={this.onChangeValue}
                        />
                      </Form.Group>

                      { tieneIncidente.incidente ?
                      <Form.Group widths="equal">
                        <Form.Select
                          name="inclemencias_id"
                          label="Inclemencias del tiempo"
                          fluid
                          search
                          selection
                          disabled
                          options={opcionesInclemencias}
                          value={tieneIncidente.incidente ? tieneIncidente.incidente.ID_INCIDENTE_INCLEMENCIAS:''}
                          onChange={this.onChangeValue}
                        />

                        <Form.Select
                          name="condiciones_id"
                          label="Condiciones de camino"
                          fluid
                          search
                          selection
                          disabled
                          options={opcionesCondiciones}
                          value={tieneIncidente.incidente ? tieneIncidente.incidente.ID_INCIDENTE_CONDICION_CAMINO:''}
                          onChange={this.onChangeValue}
                        />
                      </Form.Group>
                        :<></>
                      }

                      <Form.Group widths="equal">
                        {tieneIncidente.incidente && tieneIncidente.incidente.detalle && (
                          <Form.Field required>
                            <Form.Input
                              name="detalle_anterior"
                              type="text"
                              label="Detalle Anterior:"
                              value={tieneIncidente.incidente.detalle}
                              disabled
                            />
                          </Form.Field>
                        )}
                        {tieneIncidente.incidente && tieneIncidente.incidente.nota_facturacion && (
                          <Form.Field required>
                            <Form.Input
                              name="nota_facturacion_anterior"
                              type="text"
                              label="Nota anterior para facturación:"
                              value={
                                tieneIncidente.incidente.nota_facturacion
                              }
                              disabled
                            />
                          </Form.Field>
                        )}
                      </Form.Group>

                      <Form.Group widths="equal">
                        <Form.Field required>
                          <label>Agregar detalle</label>
                          <TextArea
                            label="Detalle"
                            name="detalle"
                            rows={3}
                            onChange={this.detalleHandleChange}
                          />
                        </Form.Field>

                        <Form.Field required>
                          <label>Agregar nota para facturación</label>
                          <TextArea
                            label="Nota para facturación"
                            name="nota_facturacion"
                            rows={3}
                            onChange={this.nota_facturacionHandleChange}
                          />
                        </Form.Field>
                      </Form.Group>

                      <Form.Group widths="equal">
                        <Form.Field>
                          <Button.Group>
                            <Button onClick={() => onFinalizarIncidente()}>
                              Cancelar
                            </Button>
                            <Button.Or text="O" />
                            <Button
                              color="orange"
                              onClick={this.onUpdate}
                              loading={this.state.loading}
                            >
                              Editar
                            </Button>
                          </Button.Group>
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </Segment>
                  :<></>
                )
                :
                <>
                  <Message warning>
                    <Message.Header>Usuario sin permiso para editar incidentes</Message.Header>
                  </Message>
                  <Button.Group style={{ marginTop: '20px' }}>
                    <Button onClick={() => onFinalizarIncidente()}>Cerrar</Button>
                  </Button.Group>
                </>
              )
            }
            {/* {tieneIncidente.length === 0 && done && (
              <Segment>
                <Message success header={done.mensaje} list={done.detalles} />

                <Form form={"incidenteSuccess"}>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Button.Group>
                        <Button positive onClick={() => onFinalizarIncidente()}>
                          YYCerrar
                        </Button>
                      </Button.Group>
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Segment>
            )} */}
            {(mensajeUpdate === true)|| done ? (
              <Segment>
                {done ?
                  <Message success header={done.mensaje} list={done.detalles} />:<></>
                }
                {mensajeUpdate === true ?
                  <Message success header={email.mensaje} list={email.detalles} />:<></>
                }
              
                <Form form={"incidenteSuccess"}>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Button.Group>
                        <Button positive onClick={() => onFinalizarIncidente()}>
                          Cerrar
                        </Button>
                      </Button.Group>
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Segment>
            ):<></>
            }
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

ModalIncidenteServicio.propTypes = {
  usuario: PropTypes.object,
  incidente: PropTypes.object,
  causas: PropTypes.object.isRequired,
  efectos: PropTypes.object.isRequired,
  onFinalizarIncidente: PropTypes.func.isRequired,
  onFetchCausas: PropTypes.func.isRequired,
  onFetchEfectos: PropTypes.func.isRequired,
  onIncidenteServicio: PropTypes.func.isRequired,
};

export default ModalIncidenteServicio