import Apoyos from "../modelos/Apoyos"

import {
    REQUEST_APOYOS,
    REQUEST_APOYOS_SUCCESS,
    REQUEST_APOYOS_FAILED,
    REQUEST_CREATE_APOYO,
    REQUEST_CREATE_APOYO_SUCCESS,
    REQUEST_CREATE_APOYO_FAILED,
} from "../constants/action_types";

const initialState = {
    all: [],
    selected: [],
    fetching: false,
    error: null,
    received_date: null,
    creating: {
        sending: false,
        error: null,
        openModal: false,
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_APOYOS:
            return {
                ...state,
                fetching: true,
                error: null
            }
        case REQUEST_APOYOS_SUCCESS: {
            return {
                ...state,
                all: action.payload.data.map(d => new Apoyos(d.data)),
                received_date: Date.now(),
                fetching: false
            }
        }
        case REQUEST_APOYOS_FAILED:
            return {
                ...initialState,
                error: {
                    cause: action.payload
                },
                received_date: Date.now(),
                fetching: false
            }
        case REQUEST_CREATE_APOYO:
            return {
                ...state,
                creating: {
                    sending: true,
                    error: null
                }
            }
        case REQUEST_CREATE_APOYO_SUCCESS:
            return {
                ...state,
                all: [
                    ...state.all,
                    new Apoyos(action.payload.data.data)
                ],
                creating: {
                    sending: false,
                    error: null
                }
            }
        case REQUEST_CREATE_APOYO_FAILED:
            return {
                ...state,
                creating: {
                    sending: false,
                    error: true
                }
            }
        default:
            return state
    }
}