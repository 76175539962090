import React, { Component } from "react"
import PropTypes from "prop-types";
import moment from "moment";
import { Table, Segment, Grid, List } from 'semantic-ui-react'

import { isObjEmpty } from '../../helpers'

class ListadoApoyos extends Component {


  render() {
    const apoyos = this.props.data;
    const validandoObjeto = isObjEmpty(apoyos)

    const rowItems = apoyos.map((apoyo, index) =>

      <Table.Row key={index} >
        <Table.Cell singleLine> {index + 1} </Table.Cell>
        <Table.Cell> {moment(apoyo.fecha_creacion).format('YYYY-MM-DD')} </Table.Cell>
        <Table.Cell> {apoyo.operador !== null ? apoyo.operador.NOMBRE : 'Sin dato'} </Table.Cell>
        <Table.Cell> {apoyo.bitacora !== null ? apoyo.bitacora.ID : 'Sin dato'} </Table.Cell>
        <Table.Cell>
          {apoyo.ruta !== null ? apoyo.ruta.NOMBRE : 'Sin dato'}
          <p>{apoyo.bitacora !== null ? apoyo.bitacora.HORA_ARRANQUE : 'Sin dato'}</p>
        </Table.Cell>
        <Table.Cell> {apoyo.operador !== null ? apoyo.operador.ID_UNIDAD : 'Sin dato'} </Table.Cell>
        <Table.Cell> {apoyo.nota !== null ? apoyo.nota : 'Sin dato'} </Table.Cell>
        <Table.Cell> {apoyo.usuario !== null ? apoyo.usuario.NOMBRE : 'Sin dato'} </Table.Cell>
      </Table.Row>
    );

    const listItems = apoyos.map((apoyo, index) =>
      <List.Item key={index + 1}>
        <List.Content>
          <List.List>
            <List.Item>
              <List.Content>
                <List.Header>No.</List.Header>
                <List.Description>{index + 1}</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>Fecha</List.Header>
                <List.Description>{moment(apoyo.fecha).format('YYYY-MM-DD')}</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>Operador</List.Header>
                <List.Description>{apoyo.operador !== null ? apoyo.operador.NOMBRE : 'Sin dato'}</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>No. Bitácora</List.Header>
                <List.Description>{apoyo.bitacora !== null ? apoyo.bitacora.ID : 'Sin dato'}</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>Ruta</List.Header>
                <List.Description>
                  {apoyo.ruta !== null ? apoyo.ruta.NOMBRE : 'Sin dato'}
                  <p>{apoyo.bitacora !== null ? apoyo.bitacora.HORA_ARRANQUE : 'Sin dato'}</p>
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>Unidad</List.Header>
                <List.Description>{apoyo.operador !== null ? apoyo.operador.ID_UNIDAD : 'Sin dato'}</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>Comentarios</List.Header>
                <List.Description>{apoyo.nota !== null ? apoyo.nota : 'Sin dato'}</List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <List.Header>Capturó</List.Header>
                <List.Description>{apoyo.usuario !== null ? apoyo.usuario.NOMBRE : 'Sin dato'}</List.Description>
              </List.Content>
            </List.Item>

          </List.List>
        </List.Content>
      </List.Item>
    );

    return (
      <Segment textAlign={"left"} container="true">
        {validandoObjeto === false &&
          <>
            <h3>Listado de apoyos</h3>
            <Grid padded>
              <Grid.Row only='computer tablet'>
                <Grid.Column style={{ paddingLeft: 0, paddingRight: 0 }}>
                  {apoyos &&
                    <Table celled padded>
                      <Table.Header style={{ fontSize: "14px" }}>
                        <Table.Row>
                          <Table.HeaderCell textAlign="center">No.</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">Fecha</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">Operador</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">No. Bitácora</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">Ruta</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">Unidad</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">Comentarios</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">Capturó</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body style={{ fontSize: "12px" }}>
                        {rowItems}
                      </Table.Body>
                    </Table>
                  }
                </Grid.Column>
              </Grid.Row>

              <Grid.Row only='mobile'>
                <Grid.Column style={{ paddingLeft: 0, paddingRight: 0 }}>
                  {apoyos &&
                    <List divided relaxed>
                      {listItems}
                    </List>
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>

          </>

        }
      </Segment>
    )
  }
}

ListadoApoyos.propTypes = {
  apoyos: PropTypes.object,
}

export default ListadoApoyos
