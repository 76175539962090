import React from "react";
import moment from "moment";
import {
  Button,
  Form,
  Grid,
  Header,
  Radio,
  Segment,
  Table,
  TextArea,
} from "semantic-ui-react";

import BaseComponent from "../BaseComponent";

import PropTypes from "prop-types";
import {
  extraerOpcionesOperadores,
  extraerOpcionesProveedores,
  extraerOpcionesRutas,
  extraerOpcionesUnidades,
} from "../../helpers";

class FormaCapturarRevisiones extends BaseComponent {
  constructor(props) {
    super(props);

    this.initialValues = {
      // LLEVA LOS VALORES DE LOS INPUS
      accionesDerivadas: '',
      aforo: false,
      asientosReclinables: false,
      botonPanico: false,
      camaraFatiga: false,
      camaras: false,
      cinturonSeguridad: false,
      cristales: false,
      defensaParrilla: false,
      dineroEfectivo: false, // No se caprura aquí
      disponibilidadCelular: false, // No se captura aquí
      dvr: false,
      extintor: false,
      faros: false,
      farosCalaberas: false,
      fecha: moment().format("YYYY-MM-DD"),
      firmaRecibos: false, //No se captura aquí
      fundasPreferenciales: false,
      gatoHidraulico: false,
      golpes: false,
      guiaLetrero: false, //Guia de funcionamiento (letrero iluminado)
      hologramaEner: false,
      hologramaEox: false,
      hologramaOxxo: false,
      kardexMantenimiento: false, //No se captura aquí
      kilometraje: 0,
      kitAuxilio: false,
      kitLimpieza: false,
      latitud: 0.0,
      letrero: false,
      licenciaVigente: false,
      limpiezaExterior: 1,
      limpiezaInterior: 1,
      limpiezaInteriorBool: false, //Piso, paredes, techo, vidrios, cortinas y asientos limpios y en buenas condiciones
      llanta: false, //Llanta de refacción y cruceta
      llenadoCombustible: false,
      longitud: 0.0,
      manibela: false,
      multas: false,
      neumaticos: false, 
      nota: '',
      notaMantenimiento: '',
      odometro: false,
      operadorSubcontratadoId: 1,
      operadorSumaId: 0,
      pantallaControl: false,
      parches: false,
      polizaSeguros: false,
      portabultos: false,
      proveedorId: 0,
      quejaClientes: false,
      rayones: false,
      reporteBase: false, //No se captura aquí
      retrovisores: false,
      rutaId: 0,
      tarjetaCirculacion: false,
      tipoRevision: 1,
      unidadId: 0,
      unidadLimpia: false, //Unidad limpia y sin ralladuras o raspones
      uniforme: false,
      usuarioId: this.props.login.user.id,
      permisoEstatal: false,
      verificacion: false,
      proteccionBateria: false,
      candados: false,
      llavesUnidad: false,
      taponCombustible: false,
      pulidoEncerado: false,
      defensaDelantera: false,
      defensaTrasera: false,
      costadoDerecho: false,
      costadoIzquierdo: false,
      rotulosExteriores: false,
      rotulosInteriores: false
    };

    this.state = {
      ...this.initialValues,
    };
  }

  onChangeValue = (event, data) => {
    if (data) {
      switch (data.type) {
        case "checkbox":
          this.setState({ [data.name]: data.checked }); //Obtenemos el valor del checkbox seleccionado
          return;
        default:
          this.setState({ [data.name]: data.value }); //Obtenemos el valor seleccionado en el select
      }
    } else {
      switch (event.target.type) {
        case "checkbox":
          this.setState({ [event.target.name]: event.target.checked });
          return;
        default:
          this.setState({ [event.target.name]: event.target.value });
      }
    }
  };

  onSubmit = () => {
    const {
      accionesDerivadas,
      aforo,
      asientosReclinables,
      botonPanico,
      camaraFatiga,
      camaras,
      cinturonSeguridad,
      cristales,
      defensaParrilla,
      dineroEfectivo,
      disponibilidadCelular,
      dvr,
      extintor,
      faros,
      //farosCalaberas,
      fecha,
      firmaRecibos,
      fundasPreferenciales,
      gatoHidraulico,
      golpes,
      guiaLetrero,
      hologramaEner,
      hologramaEox,
      hologramaOxxo,
      kardexMantenimiento,
      kilometraje,
      kitAuxilio,
      kitLimpieza,
      latitud,
      letrero,
      licenciaVigente,
      limpiezaExterior,
      limpiezaInterior,
      limpiezaInteriorBool,
      llanta,
      llenadoCombustible,
      longitud,
      manibela,
      multas,
      neumaticos, 
      nota,
      notaMantenimiento,
      odometro,
      operadorSubcontratadoId,
      operadorSumaId,
      pantallaControl,
      parches,
      polizaSeguros,
      portabultos,
      proveedorId,
      quejaClientes,
      rayones,
      reporteBase,
      retrovisores,
      rutaId,
      tarjetaCirculacion,
      tipoRevision,
      unidadId,
      unidadLimpia,
      uniforme,
      usuarioId,
      permisoEstatal,
      verificacion,
      proteccionBateria,
      candados,
      llavesUnidad,
      taponCombustible,
      pulidoEncerado,
      defensaDelantera,
      defensaTrasera,
      costadoDerecho,
      costadoIzquierdo,
      rotulosExteriores,
      rotulosInteriores
    } = this.state;

    const params = {
      accionesDerivadas:accionesDerivadas,
      aforo:aforo,
      asientosReclinables:asientosReclinables,
      botonPanico:botonPanico,
      camaraFatiga:camaraFatiga,
      camaras:camaras,
      cinturonSeguridad:cinturonSeguridad,
      cristales:cristales,
      defensaParrilla:defensaParrilla,
      dineroEfectivo:dineroEfectivo,
      disponibilidadCelular:disponibilidadCelular,
      dvr:dvr,
      extintor:extintor,
      faros:faros,
      //farosCalaberas:farosCalaberas,
      fecha:fecha,
      firmaRecibos:firmaRecibos,
      fundasPreferenciales:fundasPreferenciales,
      gatoHidraulico:gatoHidraulico,
      golpes:golpes,
      guiaLetrero:guiaLetrero,
      hologramaEner:hologramaEner,
      hologramaEox:hologramaEox,
      hologramaOxxo:hologramaOxxo,
      kardexMantenimiento:kardexMantenimiento,
      kilometraje:kilometraje,
      kitAuxilio:kitAuxilio,
      kitLimpieza:kitLimpieza,
      latitud:latitud,
      letrero:letrero,
      licenciaVigente:licenciaVigente,
      limpiezaExterior:limpiezaExterior,
      limpiezaInterior:limpiezaInterior,
      limpiezaInteriorBool:limpiezaInteriorBool,
      llanta:llanta,
      llenadoCombustible:llenadoCombustible,
      longitud:longitud,
      manibela:manibela,
      multas:multas,
      neumaticos:neumaticos, 
      nota:nota,
      notaMantenimiento:notaMantenimiento,
      odometro:odometro,
      operadorSubcontratadoId:operadorSubcontratadoId,
      operadorSumaId:operadorSumaId,
      pantallaControl:pantallaControl,
      parches:parches,
      polizaSeguros:polizaSeguros,
      portabultos:portabultos,
      proveedorId:proveedorId,
      quejaClientes:quejaClientes,
      rayones:rayones,
      reporteBase:reporteBase,
      retrovisores:retrovisores,
      rutaId:rutaId,
      tarjetaCirculacion:tarjetaCirculacion,
      tipoRevision:tipoRevision,
      unidadId:unidadId,
      unidadLimpia:unidadLimpia,
      uniforme: uniforme,
      usuarioId: usuarioId,
      permisoEstatal: permisoEstatal,
      verificacion: verificacion, 
      proteccionBateria: proteccionBateria, 
      candados: candados, 
      llavesUnidad: llavesUnidad, 
      taponCombustible: taponCombustible,
      pulidoEncerado: pulidoEncerado,
      defensaDelantera: defensaDelantera,
      defensaTrasera: defensaTrasera,
      costadoDerecho: costadoDerecho,
      costadoIzquierdo: costadoIzquierdo,
      rotulosExteriores: rotulosExteriores,
      rotulosInteriores: rotulosInteriores
    };
    this.props.onRequestCreateRevisiones(params);
  };

  onReset = (e) => {
    e.preventDefault();

    this.setState({
      ...this.initialValues,
    });

    this.props.endCreateRevisiones();
  };

  render() {
    const {
      login: { user },
      operadores,
      proveedores,
      rutas,
      unidades,
    } = this.props;

    const valueSelected = this.state.proveedorId;
    let selectOperador;

    if (valueSelected) {
      if (valueSelected === 1) {
        selectOperador = (
          <Form.Group widths="equal">
            <Form.Select
              fluid={true}
              search
              selection
              name="operadorSumaId"
              label="Operador"
              placeholder="Selecciona un operador"
              loading={operadores.fetching}
              value={this.state.operadorSumaId}
              options={operadores.all.map(extraerOpcionesOperadores)}
              onChange={this.onChangeValue}
              required
            />

            <Form.Select
              style={{ margin: "0 0 1em" }}
              fluid={true}
              search
              selection
              name="unidadId"
              label="Unidad"
              placeholder="Selecciona una unidades"
              loading={unidades.fetching}
              value={this.state.unidadId}
              options={unidades.all.map(extraerOpcionesUnidades)}
              onChange={this.onChangeValue}
              required
            />

            <Form.Select
              style={{ margin: "0 0 1em" }}
              fluid={true}
              search
              selection
              name="rutaId"
              label="Rutas"
              placeholder="Selecciona una ruta"
              loading={rutas.fetching}
              value={this.state.rutaId}
              options={rutas.all.map(extraerOpcionesRutas)}
              onChange={this.onChangeValue}
              required
            />
            <Form.Input
              id="kilometraje"
              name="kilometraje"
              fluid={true}
              type="number"
              label="Kilometraje"
              placeholder="Ingresa el kilometraje"
              value={this.state.kilometraje}
              required
              autoComplete="off"
              onChange={this.onChangeValue}
            />
          </Form.Group>
        );
      } else {
        selectOperador = (
          <Form.Group widths="equal">
            <Form.Select
              style={{ margin: "0 0 1em" }}
              fluid={true}
              search
              selection
              name="rutaId"
              label="Rutas"
              placeholder="Selecciona una ruta"
              loading={rutas.fetching}
              value={this.state.rutaId}
              options={rutas.all.map(extraerOpcionesRutas)}
              onChange={this.onChangeValue}
              required
            />

            <Form.Input
              fluid={true}
              id="kilometraje"
              name="kilometraje"
              type="number"
              label="Kilometraje"
              placeholder="Ingresa el kilometraje"
              value={this.state.kilometraje}
              autoComplete="off"
              onChange={this.onChangeValue}
              required
            />
          </Form.Group>
        );
      }
    }

    return (
      <Segment container="true">
        <Grid padded>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Header as="h3">Responsable: &nbsp; {user.nombre}</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <Header as="h4">Puesto: &nbsp; {this.props.login.user.attributes.puesto ? this.props.login.user.attributes.puesto:'Sin información'}</Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Form
          onSubmit={this.onSubmit}
          name="formulario"
          method="post"
          style={{ textAlign: "left", fontWeight: 200 }}
        >
          <Form.Group widths="equal">
            <Form.Input
              fluid={true}
              label="Fecha"
              name={"fecha"}
              type="date"
              value={this.state.fecha}
              onChange={this.onChangeValue}
            />
            <Form.Select
              fluid={true}
              search
              selection
              name="proveedorId"
              label="Proveedor"
              placeholder="Selecciona un proveedor"
              loading={proveedores.fetching}
              value={this.state.proveedorId}
              options={proveedores.all.map(extraerOpcionesProveedores)}
              onChange={this.onChangeValue}
              required
            />
          </Form.Group>
          {selectOperador}
          <Grid padded>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-evenly'}}>
                      <Form.Field as="h4">Tipo de revisión</Form.Field>
                      <Form.Field style={{ fontWeight: 200 }}>
                        <Radio
                          label="Entrega de unidad"
                          name="tipoRevision"
                          value={1}
                          checked={this.state.tipoRevision === 1}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field style={{ fontWeight: 200 }}>
                        <Radio
                          label="Recepción de unidad"
                          name="tipoRevision"
                          value={2}
                          checked={this.state.tipoRevision === 2}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field style={{ fontWeight: 200 }}>
                        <Radio
                          label="Revisión solicitada por Inplant"
                          name="tipoRevision"
                          value={3}
                          checked={this.state.tipoRevision === 3}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field style={{ fontWeight: 200 }}>
                        <Radio
                          label="Revisión periódica"
                          name="tipoRevision"
                          value={4}
                          checked={this.state.tipoRevision === 4}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={6}>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Form.Field widths="equal">
                      <h4>Generales</h4>
                        <Form.Group
                          widths="equal"
                          style={{ flexDirection: "column", fontWeight: 200 }}
                        >
                          <Form.Checkbox
                            name="gatoHidraulico"
                            label="Gato hidráulico"
                            checked={this.state.gatoHidraulico}
                            onChange={this.onChangeValue}
                            style={{ margin: "5px 0" }}
                          />
                          <Form.Checkbox
                            name="manibela"
                            label="Manivela"
                            checked={this.state.manibela}
                            onChange={this.onChangeValue}
                            style={{ margin: "5px 0" }}
                          />
                          <Form.Checkbox
                            name="llanta"
                            label="Llanta de refacción y cruceta"
                            checked={this.state.llanta}
                            onChange={this.onChangeValue}
                            style={{ margin: "5px 0" }}
                          />
                          <Form.Checkbox
                            name="kitLimpieza"
                            label="Kit de limpieza"
                            checked={this.state.kitLimpieza}
                            onChange={this.onChangeValue}
                            style={{ margin: "5px 0" }}
                          />
                          <Form.Checkbox
                            name="golpes"
                            label="Golpes"
                            checked={this.state.golpes}
                            onChange={this.onChangeValue}
                            style={{ margin: "5px 0" }}
                          />
                          <Form.Checkbox
                            name="rayones"
                            label="Rayones"
                            checked={this.state.rayones}
                            onChange={this.onChangeValue}
                            style={{ margin: "5px 0" }}
                          />
                          <Form.Checkbox
                            name="llenadoCombustible"
                            label="Gasolina 1/2 tanque"
                            checked={this.state.llenadoCombustible}
                            onChange={this.onChangeValue}
                            style={{ margin: "5px 0" }}
                          />
                          <Form.Checkbox
                            name="extintor"
                            label="Extintor"
                            checked={this.state.extintor}
                            onChange={this.onChangeValue}
                            style={{ margin: "5px 0" }}
                          />
                          <Form.Checkbox
                            name="kitAuxilio"
                            label="Botiquín"
                            checked={this.state.kitAuxilio}
                            onChange={this.onChangeValue}
                            style={{ margin: "5px 0" }}
                          />
                          <Form.Checkbox
                            name="uniforme"
                            label="Uniforme"
                            checked={this.state.uniforme}
                            onChange={this.onChangeValue}
                            style={{ margin: "5px 0" }}
                          />
                          <Form.Checkbox
                            name="fundasPreferenciales"
                            label="Asientos fundas preferenciales"
                            checked={this.state.fundasPreferenciales}
                            onChange={this.onChangeValue}
                            style={{ margin: "5px 0" }}
                          />
                          <Form.Checkbox
                            name="cinturonSeguridad"
                            label="Cinturones de seguridad"
                            checked={this.state.cinturonSeguridad}
                            onChange={this.onChangeValue}
                            style={{ margin: "5px 0" }}
                          />
                        </Form.Group>
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
            { valueSelected === 1 ?
            <Grid.Column mobile={16} tablet={8} computer={5}>
              <Table>
                <Table.Body>
                  <Table.Row>
                  <Table.Cell>
                    <Form.Field as="h4">Equipamieto</Form.Field>
                    <Form.Field widths="equal">
                      <Form.Group
                        widths="equal"
                        style={{ flexDirection: "column"}}
                      >
                        <Form.Checkbox
                          name="aforo"
                          label="Aforo"
                          checked={this.state.aforo}
                          onChange={this.onChangeValue}
                          style={{marginBottom: '10px'}}
                        />
                        <Form.Checkbox
                          name="letrero"
                          label="Letrero"
                          checked={this.state.letrero}
                          onChange={this.onChangeValue}
                          style={{marginBottom: '10px'}}
                        />
                        <Form.Checkbox
                          name="camaras"
                          label="Cámaras"
                          checked={this.state.camaras}
                          onChange={this.onChangeValue}
                          style={{marginBottom: '10px'}}
                        />
                        <Form.Checkbox
                          name="dvr"
                          label="DVR"
                          checked={this.state.dvr}
                          onChange={this.onChangeValue}
                          style={{marginBottom: '10px'}}
                        />
                        <Form.Checkbox
                          name="parches"
                          label="Parches QR"
                          checked={this.state.parches}
                          onChange={this.onChangeValue}
                          style={{marginBottom: '10px'}}
                        />
                        <Form.Checkbox
                          name="botonPanico"
                          label="Botón de pánico"
                          checked={this.state.botonPanico}
                          onChange={this.onChangeValue}
                          style={{marginBottom: '10px'}}
                        />
                        <Form.Checkbox
                          name="camaraFatiga"
                          label="Cámara de fatiga"
                          checked={this.state.camaraFatiga}
                          onChange={this.onChangeValue}
                          style={{marginBottom: '10px'}}
                        />
                        <Form.Checkbox
                          name="asientosReclinables"
                          label="Asientos reclinables"
                          checked={this.state.asientosReclinables}
                          onChange={this.onChangeValue}
                          style={{marginBottom: '10px'}}
                        />
                        <Form.Checkbox
                          name="portabultos"
                          label="Postabultos"
                          checked={this.state.portabultos}
                          onChange={this.onChangeValue}
                          style={{marginBottom: '10px'}}
                        />
                      </Form.Group>
                    </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              { valueSelected === 1 ?
              <Table>
                <Table.Body>
                  <Table.Row>
                  <Table.Cell>
                    <Form.Field as="h4">Accesorios</Form.Field>
                        <Form.Field widths="equal">
                          <Form.Group
                            widths="equal"
                            style={{ flexDirection: "column"}}
                          >
                          <Form.Checkbox
                            name="proteccionBateria"
                            label="Protecciones de baterías y tableros"
                            checked={this.state.proteccionBateria}
                            onChange={this.onChangeValue}
                          />
                          <Form.Checkbox
                            name="candados"
                            label="Candados"
                            checked={this.state.candados}
                            onChange={this.onChangeValue}
                          />
                          <Form.Checkbox
                            name="llavesUnidad"
                            label="Llaves de unidad"
                            checked={this.state.llavesUnidad}
                            onChange={this.onChangeValue}
                          />
                          <Form.Checkbox
                              name="taponCombustible"
                              label="Tapón de tanque de combustible"
                              checked={this.state.taponCombustible}
                              onChange={this.onChangeValue}
                          />
                        </Form.Group>
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>:<></>
              }
            </Grid.Column>:<></>
            }
            <Grid.Column mobile={16} tablet={8} computer={5}>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Form.Field as="h4">Documentación</Form.Field>
                        <Form.Field widths="equal">
                          <Form.Group
                            widths="equal"
                            style={{ flexDirection: "column"}}
                          >
                            <Form.Checkbox
                              name="tarjetaCirculacion"
                              label="Tarjeta de circulación"
                              checked={this.state.tarjetaCirculacion}
                              onChange={this.onChangeValue}
                            />
                            <Form.Checkbox
                              name="licenciaVigente"
                              label="Licencia vigente"
                              checked={this.state.licenciaVigente}
                              onChange={this.onChangeValue}
                            />
                            <Form.Checkbox
                              name="polizaSeguros"
                              label="Póliza de seguros"
                              checked={this.state.polizaSeguros}
                              onChange={this.onChangeValue}
                            />
                            <Form.Checkbox
                              name="permisoEstatal"
                              label="Permiso Estatal"
                              checked={this.state.permisoEstatal}
                              onChange={this.onChangeValue}
                            />
                            <Form.Checkbox
                              name="verificacion"
                              label="Verificación vehicular"
                              checked={this.state.verificacion}
                              onChange={this.onChangeValue}
                            />
                          </Form.Group>
                        </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              { valueSelected === 1 ?
              <Table>
                <Table.Body>
                  <Table.Row>
                  <Table.Cell>
                    <Form.Field as="h4">Hologramas combustible</Form.Field>
                        <Form.Field widths="equal">
                          <Form.Group
                            widths="equal"
                            style={{ flexDirection: "column"}}
                          >
                          {/* <Form.Checkbox
                            name="hologramaOxxo"
                            label="Oxxo gas"
                            checked={this.state.hologramaOxxo}
                            onChange={this.onChangeValue}
                          /> */}
                          <Form.Checkbox
                            name="hologramaEox"
                            label="EOX"
                            checked={this.state.hologramaEox}
                            onChange={this.onChangeValue}
                          />
                          <Form.Checkbox
                            name="hologramaEner"
                            label="Ener"
                            checked={this.state.hologramaEner}
                            onChange={this.onChangeValue}
                        />
                        </Form.Group>
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>:<></>
              }
            </Grid.Column>

            {/* Exterior */}
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as="h4">Limpieza exterior</Form.Field>
                      <Form.Field>
                        <Radio
                          label="Malo"
                          name="limpiezaExterior"
                          value={1}
                          checked={this.state.limpiezaExterior === 1}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="Regular"
                          name="limpiezaExterior"
                          value={2}
                          checked={this.state.limpiezaExterior === 2}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="Bueno"
                          name="limpiezaExterior"
                          value={3}
                          checked={this.state.limpiezaExterior === 3}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="Excelente"
                          name="limpiezaExterior"
                          value={4}
                          checked={this.state.limpiezaExterior === 4}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as='h5' style={{minWidth: '70%'}}>Unidad limpia y sin ralladuras o raspones</Form.Field>
                      <Form.Field>
                        <Radio
                          label="Si"
                          name="unidadLimpia"
                          value={true}
                          checked={this.state.unidadLimpia === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="No"
                          name="unidadLimpia"
                          value={false}
                          checked={this.state.unidadLimpia === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as='h5' style={{minWidth: '70%'}}>Guía de funcionamiento (letrero iluminado)</Form.Field>
                      <Form.Field>
                        <Radio
                          label="Si"
                          name="guiaLetrero"
                          value={true}
                          checked={this.state.guiaLetrero === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="No"
                          name="guiaLetrero"
                          value={false}
                          checked={this.state.guiaLetrero === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as='h5' style={{minWidth: '70%'}}>Cristales en buenas condiciones</Form.Field>
                      <Form.Field>
                        <Radio
                          label="Si"
                          name="cristales"
                          value={true}
                          checked={this.state.cristales === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="No"
                          name="cristales"
                          value={false}
                          checked={this.state.cristales === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as='h5' style={{minWidth: '70%'}}>Neumáticos buenas condiciones</Form.Field>
                      <Form.Field>
                        <Radio
                          label="Si"
                          name="neumaticos"
                          value={true}
                          checked={this.state.neumaticos === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="No"
                          name="neumaticos"
                          value={false}
                          checked={this.state.neumaticos === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as='h5' style={{minWidth: '70%'}}>Funcionamiento faros y calaveras</Form.Field>
                      <Form.Field>
                        <Radio
                          label="Si"
                          name="faros"
                          value={true}
                          checked={this.state.faros === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="No"
                          name="faros"
                          value={false}
                          checked={this.state.faros === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as='h5' style={{minWidth: '70%'}}>Retrovisores en buenas condiciones</Form.Field>
                      <Form.Field>
                        <Radio
                          label="Si"
                          name="retrovisores"
                          value={true}
                          checked={this.state.retrovisores === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="No"
                          name="retrovisores"
                          value={false}
                          checked={this.state.retrovisores === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as='h5' style={{minWidth: '70%'}}>Sin golpes defensa o parrilla</Form.Field>
                      <Form.Field>
                        <Radio
                          label="Si"
                          name="defensaParrilla"
                          value={true}
                          checked={this.state.defensaParrilla === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="No"
                          name="defensaParrilla"
                          value={false}
                          checked={this.state.defensaParrilla === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as='h5' style={{minWidth: '70%'}}>Limpieza, pulido y encerado</Form.Field>
                      <Form.Field>
                        <Radio
                          label="Si"
                          name="pulidoEncerado"
                          value={true}
                          checked={this.state.pulidoEncerado === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="No"
                          name="pulidoEncerado"
                          value={false}
                          checked={this.state.pulidoEncerado === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as='h5' style={{minWidth: '70%'}}>Defensa delantera</Form.Field>
                      <Form.Field>
                        <Radio
                          label="Si"
                          name="defensaDelantera"
                          value={true}
                          checked={this.state.defensaDelantera === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="No"
                          name="defensaDelantera"
                          value={false}
                          checked={this.state.defensaDelantera === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as='h5' style={{minWidth: '70%'}}>Defensa trasera</Form.Field>
                      <Form.Field>
                        <Radio
                          label="Si"
                          name="defensaTrasera"
                          value={true}
                          checked={this.state.defensaTrasera === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="No"
                          name="defensaTrasera"
                          value={false}
                          checked={this.state.defensaTrasera === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as='h5' style={{minWidth: '70%'}}>Costado derecho</Form.Field>
                      <Form.Field>
                        <Radio
                          label="Si"
                          name="costadoDerecho"
                          value={true}
                          checked={this.state.costadoDerecho === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="No"
                          name="costadoDerecho"
                          value={false}
                          checked={this.state.costadoDerecho === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as='h5' style={{minWidth: '70%'}}>Costado izquierdo</Form.Field>
                      <Form.Field>
                        <Radio
                          label="Si"
                          name="costadoIzquierdo"
                          value={true}
                          checked={this.state.costadoIzquierdo === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="No"
                          name="costadoIzquierdo"
                          value={false}
                          checked={this.state.costadoIzquierdo === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as='h5' style={{minWidth: '70%'}}>Rotulos exteriores</Form.Field>
                      <Form.Field>
                        <Radio
                          label="Si"
                          name="rotulosExteriores"
                          value={true}
                          checked={this.state.rotulosExteriores === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="No"
                          name="rotulosExteriores"
                          value={false}
                          checked={this.state.rotulosExteriores === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>

            {/* Interior */}
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as="h4">Limpieza interior</Form.Field>
                      <Form.Field>
                        <Radio
                          label="Malo"
                          name="limpiezaInterior"
                          value={1}
                          checked={this.state.limpiezaInterior === 1}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="Regular"
                          name="limpiezaInterior"
                          value={2}
                          checked={this.state.limpiezaInterior === 2}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="Bueno"
                          name="limpiezaInterior"
                          value={3}
                          checked={this.state.limpiezaInterior === 3}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label="Excelente"
                          name="limpiezaInterior"
                          value={4}
                          checked={this.state.limpiezaInterior === 4}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as="h5" style={{minWidth: '70%'}}>Piso, paredes, techo, vidrios, cortinas y asientos limpios y en buenas condiciones</Form.Field>
                      <Form.Field style={{minWidth: '15%'}}>
                        <Radio
                          label="Si"
                          name="limpiezaInteriorBool"
                          value={true}
                          checked={this.state.limpiezaInteriorBool === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field  style={{minWidth: '15%'}}>
                        <Radio
                          label="No"
                          name="limpiezaInteriorBool"
                          value={false}
                          checked={this.state.limpiezaInteriorBool === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as="h5" style={{minWidth: '70%'}}>Cuenta con pantalla y control</Form.Field>
                      <Form.Field  style={{minWidth: '15%'}}>
                        <Radio
                          label="Si"
                          name="pantallaControl"
                          value={true}
                          checked={this.state.pantallaControl === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field  style={{minWidth: '15%'}}>
                        <Radio
                          label="No"
                          name="pantallaControl"
                          value={false}
                          checked={this.state.pantallaControl === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as="h5" style={{minWidth: '70%'}}>Odómetro funcionando</Form.Field>
                      <Form.Field  style={{minWidth: '15%'}}>
                        <Radio
                          label="Si"
                          name="odometro"
                          value={true}
                          checked={this.state.odometro === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field  style={{minWidth: '15%'}}>
                        <Radio
                          label="No"
                          name="odometro"
                          value={false}
                          checked={this.state.odometro === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell style={{display: 'flex', justifyContent: 'space-between'}}>
                      <Form.Field as="h5" style={{minWidth: '70%'}}>Rotulos interiores</Form.Field>
                      <Form.Field  style={{minWidth: '15%'}}>
                        <Radio
                          label="Si"
                          name="rotulosInteriores"
                          value={true}
                          checked={this.state.rotulosInteriores === true}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                      <Form.Field  style={{minWidth: '15%'}}>
                        <Radio
                          label="No"
                          name="rotulosInteriores"
                          value={false}
                          checked={this.state.rotulosInteriores === false}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Form.Field>
                        <TextArea
                          id="nota"
                          placeholder="Notas"
                          name="nota"
                          value={this.state.nota}
                          style={{ minHeight: 170, maxHeight: 300 }}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>

            {/* Acciones */}
            { valueSelected === 1 ?
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Form.Field>
                        <TextArea
                          id="accionesDerivadas"
                          name="accionesDerivadas"
                          value={this.state.accionesDerivadas}
                          placeholder="Acciones derivadas a la entrega de unidades"
                          style={{ minHeight: 150, maxHeight: 300 }}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>:<></>
            }
            {/* Notas */}
            <Grid.Column mobile={16} tablet={16} computer={16}>
            <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <Form.Field>
                        <TextArea
                          id="notaMantenimiento"
                          name="notaMantenimiento"
                          value={this.state.notaMantenimiento}
                          placeholder="Notas de mantenimiento"
                          style={{ minHeight: 150, maxHeight: 300 }}
                          onChange={this.onChangeValue}
                        />
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
            {/* Botones*/}
            <Grid.Column mobile={16} tablet={8} computer={8} floated={"right"}>
              <Grid.Row>
                <Form.Group unstackable>
                  <Button
                    id="btn-formulario"
                    type="submit"
                    color="orange"
                    fluid={true}
                  >
                    Guardar
                  </Button>
                  <Button onClick={this.onReset} fluid={true}>
                    Cerrar
                  </Button>
                </Form.Group>
              </Grid.Row>
            </Grid.Column>
          </Grid>
          <br />
        </Form>
      </Segment>
    );
  }
}

FormaCapturarRevisiones.propTypes = {
  revisiones: PropTypes.object,
};

export default FormaCapturarRevisiones;
