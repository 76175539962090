import React, { useEffect } from 'react'
import {Button, Form, Modal, Segment, TextArea, Message} from "semantic-ui-react";
import PropTypes from 'prop-types'

import BaseComponent from "../BaseComponent";
import InputLista from '../InputLista/InputLista';
import axios from 'axios';
import { ENDPOINT_GET_INCIDENTE_CAUSA } from '../../constants/endpoints';

class ModalCancelarServicio extends BaseComponent {

    constructor(props) {
        super(props);

        this.state = {
            motivo: "",
            valid: false,
            incidentes:[],
            incidenteSeleccionado:null
        }
    }

    onSubmit = () => {
        const id = this.props.cancelacion.bitacora.id
        const idUsuario = this.props.usuario.id

        this.props.onCancelarServicio(id, {
            'detalle': this.state.motivo,
            'incidente':this.state.incidenteSeleccionado.code,
            'usuario_id': idUsuario
        })
    }

    render() {
        const {cancelacion: {bitacora, sending, error, done}} = this.props
        const {onFinalizarCancelacion} = this.props

        if (!bitacora) {
            return null
        }
        if(!this.state.incidentes.length)
        {
            axios.get(ENDPOINT_GET_INCIDENTE_CAUSA).then((res)=>{
                console.log("res",res.data.data);
                this.setState({ incidentes:res.data.data });
            })
        }
        console.log("incidentes",this.state.incidenteSeleccionado);
        return (
            <Modal open={true} size='large'>
                <Modal.Header>
                    <p>Cancelar servicio (Id: {bitacora.id})</p>
                    <p>{bitacora.getNombreRuta()}</p>
                    <p>{bitacora.getOperador() ? bitacora.getOperador().nombre : "Sin definir"}</p>
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {error && <Message error header={error.mensaje} list={error.detalles}/>}

                        {
                            !done &&
                            <Segment>
                                <Form form={'formaCancelarServicio'}>
                                    <Form.Field required>
                                        <label>Motivo</label>
                                        <TextArea label="Motivo" name="motivo" rows={3} onChange={this.onChangeValue}/>
                                    </Form.Field>
                                    <Form.Field required>
                                        <label>Incidente</label>
                                        <InputLista value={this.state.incidenteSeleccionado} onChange={(e)=>{this.setState({ incidenteSeleccionado : e.value })}} options={this.state.incidentes} placeholder={"Seleccione el incidente"}></InputLista>
                                    </Form.Field>

                                    <Form.Group widths="equal">
                                        <Form.Field>
                                            <Button.Group>
                                                <Button onClick={() => onFinalizarCancelacion()}>Cancelar</Button>
                                                <Button.Or text='O'/>
                                                <Button color={"orange"} onClick={this.onSubmit}
                                                        disabled={!this.state.motivo.length || sending || !this.state.incidenteSeleccionado}
                                                        loading={sending}>Enviar</Button>
                                            </Button.Group>
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </Segment>
                        }

                        {
                            done &&
                            <Segment>
                                <Message success header={done.mensaje} list={done.detalles}/>

                                <Form form={'cancelarSuccess'}>
                                    <Form.Group widths="equal">
                                        <Form.Field>
                                            <Button.Group>
                                                <Button positive
                                                        onClick={() => onFinalizarCancelacion()}>Cerrar</Button>
                                            </Button.Group>
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </Segment>
                        }

                    </Modal.Description>
                </Modal.Content>
            </Modal>
        )
    }
}

ModalCancelarServicio.propTypes = {
    cancelacion: PropTypes.object.isRequired,
    onCancelarServicio: PropTypes.func.isRequired,
    onFinalizarCancelacion: PropTypes.func.isRequired
}

export default ModalCancelarServicio
