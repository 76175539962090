import React, {Component} from 'react'
import {Message, Grid, Segment} from 'semantic-ui-react'
import PropTypes from 'prop-types'
import axios from 'axios'

import FormaProgramacionServicios from '../../containers/ProgramacionServicios/FormaProgramacionServiciosContainer'

import { ENDPOINT_OPERADORES_NO_DISPONIBLES, ENDPOINT_UNIDADES_NO_DISPONIBLES } from '../../constants/endpoints';

import { validarStorage } from "../../helpers";

class ProgramacionServicios extends Component {
    constructor(props){
        super(props)
        this.state = {
            operadorLibre: null,
            useForce: null
        }
    }

    componentWillMount() {
        this.getOperadores();
        this.getUnidades();
    }

    getOperadores = () => {
        const url = `${ENDPOINT_OPERADORES_NO_DISPONIBLES}`;

        axios.get(url)
            .then(response => {
                if(response.status === 200){
                    this.setState({
                        operadores: response.data.data
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    getUnidades = () => {
        const url = `${ENDPOINT_UNIDADES_NO_DISPONIBLES}`;

        axios.get(url)
            .then(response => {
                if(response.status === 200){
                    this.setState({
                        unidades: response.data.data
                    })
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                })
                console.log('Error: ', err);
            })
    }

    render() {
        const {programacion, bitacoras, login: {user}} = this.props
        const { operadores, unidades } = this.state
        const permisos = validarStorage(user).permisos
        let operadorLibre = this.state.operadorLibre;
        if(programacion.no_cruces){
            operadorLibre = programacion.no_cruces > 0 ? false : null
        }

        let useForce = this.state.useForce;
        if(programacion.use_force && programacion.use_force !== false){
            useForce = true
        }else{
            useForce = false
        }

        let error_cause, operador_libre, con_force;

        if(programacion.error){
           console.log(programacion)
            error_cause =   <Segment inverted color='red'>
                            <h1>{programacion.error.cause?.data.mensaje}</h1>
                            <hr/>
                            <h4>Detalles</h4>
                            <ul>
                                {programacion.error.cause?.data.detalles.map((x, idx) => (<li key={idx}>{x}</li>))}
                            </ul>
                            <hr/>
                            <h3>Solicita a tu supervisor esta programación de servicio</h3>
                        </Segment>
        }

        if (operadorLibre === false && useForce === false) {
            operador_libre =   <Segment style={{background: "#db2828", color: "#fff"}}>
                            <h3>El operador ya cuenta con una bitacora con ese turno</h3> 
                            <h3>Cruces de rutas: {programacion.validation.cruces}</h3>
                            <h4>Seleccione otro operador</h4>
                        </Segment>
        }
       
        if(operadorLibre === false && useForce === true){
            con_force =   <Segment style={{background: "green", color: "#fff"}}>
                            <h3>Programación forzada</h3>
                            <h3>Cruces de rutas: {programacion.validation.cruces}</h3>
                            <h4>Verifica que se haya creado la bitácora</h4>
                        </Segment>
        }

        if (permisos?.programarServicios === true) {
            return (
                <Grid.Column mobile={16} tablet={16} computer={16}>
                    <h3 style={{textAlign: "left"}}>Programación servicios</h3>

                    { error_cause }
                    
                    {operador_libre}
                    
                    {con_force}

                    <FormaProgramacionServicios 
                        onFormSubmit={this.onFormSubmit}
                        arrayOperadores={operadores}
                        arrayUnidades={unidades}
                        programacion={programacion}
                        bitacoras={bitacoras.listado}
                    />
                </Grid.Column>
            )
        } else {
            return (
                <Grid.Column mobile={16} tablet={16} computer={16}>
                    <Message warning>
                        <Message.Header>No cuenta con permisos para realizar programaciones</Message.Header>
                        <p>Si requieres acceso, favor de validar con Sistemas</p>
                    </Message>
                </Grid.Column>
            )
        }
    }
}

ProgramacionServicios.propTypes = {
    programacion: PropTypes.object.isRequired,
    bitacoras: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
}

export default ProgramacionServicios