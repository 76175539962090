import React, { Component } from "react";
import { Segment, Form, Select, Button, Message, List, Divider, Modal } from "semantic-ui-react";
import axios from "axios";

import { ENDPOINT_CLIENTES, ENDPOINT_RUTAS, ENDPOINT_COLABORADORES, ENDPOINT_BUSCAR_CONTACTOS, ENDPOINT_ACTUALIZAR_CONTACTOS } from '../../constants/endpoints'
import ModalContacto from "./ModalContacto";
import ListaContactos from "./ListaContactos";

class BuscarContacto extends Component {
    constructor(props){
        super(props)

        this.initialValues = ({
            loading: false,
            idCliente: null,
            idColaborador: null,
            empresa: '',
            mostrarEmpresa: false,
            abrirModal: false,
            abrir: false,
            sinDatos: false,
            cliente: [],
            params: {}
        })

        this.state = ({
            ...this.initialValues
        })
    }

    getClientes(){
        const url = `${ENDPOINT_CLIENTES}?activo=true&id_categoria=1&or(id=5)&sort(+nombre_empresa)`;
        
        this.setState({
            loading: true
        })

        return(
            axios.get(url)
                .then((response) => {
                    if(response.status === 200){

                        const cliente = response.data.map(cliente => {
                            return { key: cliente.data.id, text: cliente.data.attributes.nombreEmpresa, value: cliente.data.id }
                        })

                        return this.setState({
                            cliente: cliente,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        )
    }

    getColaboradores(){
        const url = `${ENDPOINT_COLABORADORES}`;
        
        this.setState({
            loading: true
        })

        return(
            axios.post(url)
                .then((response) => {
                    if(response.status === 200){

                        const colaborador = response.data.data.map(colaborador => {
                            return { key: colaborador.ID_USUARIO, text: colaborador.NOMBRE, value: colaborador.ID_USUARIO }
                        })

                        return this.setState({
                            colaborador: colaborador,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        )
    }

    getContactos(){
        const { idCliente } = this.state
        const url = `${ENDPOINT_BUSCAR_CONTACTOS}?id_cliente=${idCliente}`;

        return(
            axios.get(url)
                .then((response) => {
                    if(response.status === 200 && response.data.data){
                        return this.setState({
                            listaContactos: response.data.data,
                            loading: false,
                            sinDatos: false
                        });
                    }

                    if(response.status === 200 && !response.data.data){
                        return this.setState({
                            sinDatos: true,
                            loading: false,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        )
    }

    getRutas(valor){
        const url = `${ENDPOINT_RUTAS}?id_cliente=${valor}&sort(+nombre)&activa=true`;

        this.setState({
            loading: true
        })

        return(
            axios.get(url)
                .then((response) => {
                    if(response.status === 200){
                        const ruta = response.data.map(ruta => {
                            return { key: ruta.data.id, text: ruta.data.attributes.nombre, value: ruta.data.id }
                        })

                        return this.setState({
                            ruta: ruta,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
            })
        )
    }

    getNombreEmpresa(){
        const { idCliente } = this.state

        const url = `${ENDPOINT_CLIENTES}?activo=true&id_categoria=1&or(id=5)&sort(+nombre_empresa)&id=${idCliente}`;

        return(
            axios.get(url)
                .then((response) => {
                    if(response.status === 200){
                        sessionStorage.setItem("empresa", response.data[0].data.attributes.nombreEmpresa);
                        const empresa = sessionStorage.getItem("empresa");

                        return this.setState({
                            empresa : empresa,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        )
    }

    componentDidMount(){
        this.getClientes()
        //this.getColaboradores()
    }

    handleChangeValue = (e, data) => {
        this.setState({ [data.name]: data.value})
    }

    buscarPorCliente = (e) => {
        this.setState({
            loading: true,
            listaContactos: [],
            mostrarEmpresa: true
        })

        this.getContactos()
        this.getNombreEmpresa(this.state.idCliente)
    }

    buscarPorColaborador = () => {
        const { idColaborador } = this.state

        this.setState({
            loading: true,
        })

        const url = `${ENDPOINT_BUSCAR_CONTACTOS}?id_colaborador=${idColaborador}`;

        return(
            axios.get(url)
                .then((response) => {
                    if(response.status === 200){
                        return this.setState({
                            listaClientes: response.data,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    setTimeout(() => {
                        this.setState({ ...this.initialValues});
                      }, 3000)
                })
        )
    }

    abrirModal = (item) => {
        const { idCliente } = this.state
        this.getRutas(idCliente)

        this.setState({
            abrirModal: true,
            itemEdicion: item
        })
    }

    cerrarModal = () => {
        this.setState({
            abrirModal: false
        })
    }

    desactivar = (datos) => {
        this.setState({
            loading: true,
        })

        const { idCliente} = this.state;
        const activo = 0

        const url = `${ENDPOINT_ACTUALIZAR_CONTACTOS}?id_colaborador=${datos.ID_COLAB}&externo=${datos.EXTERNO}&id_cliente=${idCliente}&orden=${datos.ORDEN ? datos.ORDEN:0}&ruta=${datos.RUTA ? datos.RUTA:0}&activo=${activo}&id=${datos.ID}`;

        return(
            axios.post(url)
                .then((response) => {
                    if(response.status === 200){
                        return this.setState({
                            desactivado: response.data.success,
                            loading: false,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    this.getContactos()
                })
        )
    }

    activar = (datos) => {
        this.setState({
            loading: true,
        })

        const { idCliente } = this.state;
        const activo = 1

        const url = `${ENDPOINT_ACTUALIZAR_CONTACTOS}?id_colaborador=${datos.ID_COLAB}&externo=${datos.EXTERNO}&id_cliente=${idCliente}&orden=${datos.ORDEN ? datos.ORDEN:0}&ruta=${datos.RUTA ? datos.RUTA:0}&activo=${activo}&id=${datos.ID}`;

        return(
            axios.post(url)
                .then((response) => {
                    if(response.status === 200){
                        return this.setState({
                            activado: response.data.success,
                            loading: false,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    this.getContactos()
                })
        )
    }

    desactivarExterno = (datos) => {
        this.setState({
            loading: true,
        })

        const { idCliente } = this.state
        const activo = 0

        const url = `${ENDPOINT_ACTUALIZAR_CONTACTOS}?externo=${datos.EXTERNO}&activo=${activo}&nombre=${datos.NOMBRE}&celular=${datos.CELULAR}&correo=${datos.CORREO_ELECTRONICO}&id_cliente=${idCliente}&puesto=${datos.PUESTO}&orden=${datos.ORDEN ? datos.ORDEN:0}&ruta=${datos.RUTA ? datos.RUTA:0}&id=${datos.ID}`;

        return(
            axios.post(url)
                .then((response) => {
                    if(response.status === 200){
                        return this.setState({
                            editado: response.data.success,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    this.getContactos()
                })
        )
    }

    activarExterno = (datos) => {
        this.setState({
            loading: true,
        })

        const { idCliente } = this.state
        const activo = 1

        const url = `${ENDPOINT_ACTUALIZAR_CONTACTOS}?externo=${datos.EXTERNO}&activo=${activo}&nombre=${datos.NOMBRE}&celular=${datos.CELULAR}&correo=${datos.CORREO_ELECTRONICO}&id_cliente=${idCliente}&puesto=${datos.PUESTO}&orden=${datos.ORDEN ? datos.ORDEN:0}&ruta=${datos.RUTA ? datos.RUTA:0}&id=${datos.ID}`;

        return(
            axios.post(url)
                .then((response) => {
                    if(response.status === 200){
                        return this.setState({
                            editado: response.data.success,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    this.getContactos()
                })
        )
    }

    editarContactos = (datos) => {
console.log(datos)
        this.setState({
            abrirModal: false
        })
console.log('enviando')
        const { idCliente, externo, nombre, telefono, correo, puesto, horario, orden, idRuta } = this.state

        const name = nombre ? nombre : datos.NOMBRE;
        const phone = telefono ? telefono : datos.CELULAR;
        const email = correo ? correo : datos.CORREO_ELECTRONICO;
        const position = puesto ? puesto : datos.PUESTO;
        const workTime = horario ? horario : datos.HORARIO;
        const order = orden ? orden : datos.ORDEN;
        const route = idRuta ? idRuta : datos.RUTA;
        const external = externo ? externo : datos.EXTERNO;
        const id = datos.ID
        const ruta = route === null ? 0:route;
        const tiempo = workTime === null ? '':workTime;

        if(idCliente || external || name || phone || email || position || tiempo || order || ruta || id){

            const url = `${ENDPOINT_ACTUALIZAR_CONTACTOS}?externo=${external}&nombre=${name}&celular=${phone}&correo=${email}&id_cliente=${idCliente}&puesto=${position}&orden=${order}&ruta=${ruta}&id=${id}&horario=${tiempo}`;

            return(
                axios.post(url)
                    .then((response) => {
                        if(response.status === 200){
                            console.log('editado')
                            return this.setState({
                                editado: response.data.success,
                                loading: false
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .then(() => {
                        this.getContactos()
                        const empresa = sessionStorage.getItem("empresa");

                        this.setState({
                            empresa: empresa
                        });
                    })
            )
        }
    }

    resetState = () => {
        return this.setState({ 
            // ...this.initialValues, 
            idCliente: null,
            listaContactos: [], 
            sinDatos: false
        });
    }


  render() {
    const { cliente, colaborador, idCliente, idColaborador, 
        sinDatos, listaContactos, empresa, mostrarEmpresa,
        abrirModal, itemEdicion } = this.state;
        let { value, ruta } = this.state;
        let arrayActivos = []
        let arrayInactivos = []

        if(listaContactos){
            listaContactos.map((item) => {
                if(item.ACTIVO === 1){
                    return arrayActivos.push(item)
                }
    
                if(item.ACTIVO === 0){
                    return arrayInactivos.push(item)
                }
            })
        }

        arrayActivos.sort((a, b) => {
            return a.EXTERNO - b.EXTERNO;
        });

        arrayInactivos.sort((a, b) => {
            return a.EXTERNO - b.EXTERNO;
        });

    return (
        <>
        <Segment.Group horizontal>
            <Segment>
                <h3>Buscar contactos por cliente</h3>
                <Form className='form-evaluacion-especial'>
                    <Form.Group widths='equal' className='form-group-inputs'>
                        <Form.Field
                            label='Empresa a la que se asignara el contacto'
                            control={Select}
                            options={cliente}
                            placeholder='Cliente'
                            search
                            value={idCliente}
                            name='idCliente'
                            onChange={this.handleChangeValue}
                            loading={this.state.loading}
                        />
                    </Form.Group>
                    <Button loading={this.state.loading} className='form-button' color='orange' onClick={this.buscarPorCliente}>Buscar</Button>
                    <Button loading={this.state.loading} className='form-button' onClick={this.resetState}>Limpiar</Button>
                </Form>  
            </Segment>
            <Segment>
            <>
                { (empresa && mostrarEmpresa && arrayActivos.length > 0) ?
                    <h3>{empresa}</h3>
                    :<></>
                }

                { (empresa && mostrarEmpresa && arrayActivos.length > 0) ?
                    <h3>Contactos Activos</h3>
                    :<></>
                }

                <List style={{textAlign: 'left'}}>
                { listaContactos &&
                    arrayActivos.map((item) => {
                        const interno = []
                        const externo = []
                        if(item.EXTERNO === 0){
                            interno.push(item)
                        }else{
                            externo.push(item)
                        }

                        if(item.EXTERNO === 0){
                            return (
                                <>
                                <ListaContactos
                                    key={item.key}
                                    item={item}
                                    onAbrirModal={this.abrirModal}
                                    onDesactivar={this.desactivar}
                                    loading={this.state.loading}
                                />
                                <Divider />
                                </>
                            )
                        }else{
                            return (
                                <>
                                <ListaContactos
                                    key={item.key}
                                    item={item}
                                    onAbrirModal={this.abrirModal}
                                    onDesactivarExterno={this.desactivarExterno}
                                    loading={this.state.loading}
                                />
                                <Divider />
                                </>
                            )
                        }

                    })
                }
                </List> 

                { (empresa && mostrarEmpresa && arrayInactivos.length > 0) ?
                    <h3>Contactos Inactivos</h3>
                    :<></>
                }

                <List style={{textAlign: 'left'}}>
                { listaContactos &&
                    arrayInactivos.map((item) => {
                        const interno = []
                        const externo = []
                        if(item.EXTERNO === 0){
                            interno.push(item)
                        }else{
                            externo.push(item)
                        }

                        if(item.EXTERNO === 0){
                            return (
                                <>
                                <ListaContactos
                                    key={item.key}
                                    item={item}
                                    onAbrirModal={this.abrirModal}
                                    onActivar={this.activar}
                                    loading={this.state.loading}
                                />
                                <Divider />
                                </>
                            )
                        }else{
                            return (
                                <>
                                <ListaContactos
                                    key={item.key}
                                    item={item}
                                    onAbrirModal={this.abrirModal}
                                    onActivarExterno={this.activarExterno}
                                    loading={this.state.loading}
                                />
                                <Divider />
                                </>
                            )
                        }

                    })
                }
                </List>

                
                { sinDatos ?
                    <Message info>
                        <Message.Header> Sin información registrada</Message.Header>
                    </Message>
                    :
                    <></>
                }

                {
                    <Modal open={abrirModal ? true : false} size={'large'}>
                        <ModalContacto 
                            itemEdicion={itemEdicion} 
                            onEditarContactos={this.editarContactos}
                            onHandleChangeValue={this.handleChangeValue}
                            onCerrarModal={this.cerrarModal}
                            ruta={ruta}
                        />
                    </Modal>
                }
            </>
            </Segment>
        </Segment.Group>
        </>
    );
  }
}

export default BuscarContacto;