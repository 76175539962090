import moment from "moment";

import { Form, Segment, Grid, Message } from "semantic-ui-react";
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import BaseComponent from "../BaseComponent";

import {
  extraerOpcionesClientes,
  extraerOpcionesRutas,
  validarStorage,
  tipoUsuario
} from "../../helpers";

class FormaCargarHorariosRuta extends BaseComponent {

  constructor(props) {
    super(props)

    this.state = {
      cliente: -1,
      ruta: -1,
      desde: moment().format("YYYY-MM-DD"),
      hasta: moment().endOf("year").format("YYYY-MM-DD"),
      received_date: null
    }
  }

  triggerClienteChange = (event, data) => {

    const { onInvalidarRutas, onRequestRutas } = this.props

    onInvalidarRutas()

    this.setState({
      ruta: -1
    })

    onRequestRutas([`id_cliente=${data.value}`, 'sort(+nombre)', 'activa=true'])

    this.onChangeValue(event, data)
  }

  triggerRutaChange = (event, data) => {
    this.onChangeValue(event, data)
    console.log(data);
    this.props.onRequestHorariosRuta(data.value)
  }

  onSubmit = (event) => {
    event.preventDefault()
    const { ruta, desde, hasta } = this.state
    const { user } = this.props
    const userId = user.id

    this.props.onUpdateHorariosRuta(ruta, desde, hasta, userId)
  }


  render() {
    const { clientes, rutas, horariosFetching, user } = this.props
    const { cliente, ruta, desde, hasta, successResponse } = this.state
    const submitDisabled = (horariosFetching || ruta === -1)
    const permisos = validarStorage(user).permisos

    let acceso = false;
    tipoUsuario.map((item, key) => {
      if (item.tipo == 'Monitoristas') {
        console.log("item", item.ids);
        item.ids.map((id, index) => {
          if (JSON.parse(sessionStorage.getItem("usuario")).id == id) {
            console.log("no tiene axeso");
            acceso = false
          }
          else {
            console.log("no tiene axeso");
            acceso = true
          }
        })
      }
    })

    return (
      <div>
        {permisos?.ajustarEsquemas === true && permisos?.verEsquemas === false ?
          <Segment color='orange' container="true">
            <h3 style={{ textAlign: "left" }}>Esquemas</h3>
            <Form form={'formaCargarHorarios'} onSubmit={this.onSubmit}>

              {permisos?.programarServicios === true &&
                <Form.Group widths={"equal"}>
                  <Form.Input fluid name='desde' label='Desde' type='date' value={desde}
                    onChange={this.onChangeValue}
                  />

                  <Form.Input fluid name='hasta' label='Hasta' type='date' value={hasta}
                    onChange={this.onChangeValue}
                  />
                </Form.Group>
              }

              <Form.Group widths={"equal"}>
                <Form.Select name='cliente' label='Cliente' fluid search selection
                  loading={clientes.fetching}
                  options={clientes.all.map(extraerOpcionesClientes)} value={cliente}
                  onChange={this.triggerClienteChange}
                />

                <Form.Select name='ruta' label='Ruta' fluid search selection loading={rutas.fetching}
                  options={rutas.all.map(extraerOpcionesRutas)} value={ruta}
                  onChange={this.triggerRutaChange}
                />
              </Form.Group>

              {/* {permisos.programarServicios === true &&
                <Form.Field>
                  <label>&nbsp;</label>
                  <Button color={"orange"} type="submit" disabled={submitDisabled}
                    loading={horariosFetching}>Actualizar horarios</Button>
                </Form.Field>
              } */}

            </Form>
          </Segment>
          :
          <Segment color='orange' container="true">
            {
              acceso ?
                <>
                  <h3 style={{ textAlign: "left" }}>Consulta de Esquemas</h3>
                  <Form form={'formaCargarHorarios'} onSubmit={this.onSubmit}>

                    <Form.Group widths={"equal"}>
                      <Form.Input fluid name='desde' label='Desde' type='date' value={desde}
                        onChange={this.onChangeValue}
                      />

                      <Form.Input fluid name='hasta' label='Hasta' type='date' value={hasta}
                        onChange={this.onChangeValue}
                      />
                    </Form.Group>

                    <Form.Group widths={"equal"}>
                      <Form.Select name='cliente' label='Cliente' fluid search selection
                        loading={clientes.fetching}
                        options={clientes.all.map(extraerOpcionesClientes)} value={cliente}
                        onChange={this.triggerClienteChange}
                      />

                      <Form.Select name='ruta' label='Ruta' fluid search selection loading={rutas.fetching}
                        options={rutas.all.map(extraerOpcionesRutas)} value={ruta}
                        onChange={this.triggerRutaChange}
                      />
                    </Form.Group>

                  </Form>
                </> :
                <>
                  <Message warning>
                    <Message.Header>No cuenta con permisos para realizar programaciones</Message.Header>
                    <p>Si requieres acceso, favor de validar con Sistemas</p>
                  </Message>
                </>
            }
          </Segment>

        }

        {(permisos?.ajustarEsquemas === false && permisos?.verEsquemas === false) &&
          <Grid.Column mobile={16} tablet={16} computer={16} style={{ display: 'none' }}>
            <Message warning>
              <Message.Header>No cuenta con permisos para ajustar esquemas</Message.Header>
              <p>Si requiere un ajuste comuniquese con su supervisor</p>
            </Message>
          </Grid.Column>
        }
      </div>
    )
  }
}

FormaCargarHorariosRuta.propTypes = {
  clientes: PropTypes.object.isRequired,
  rutas: PropTypes.object.isRequired,
  horariosFetching: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  onInvalidarRutas: PropTypes.func.isRequired,
  onRequestRutas: PropTypes.func.isRequired,
  onUpdateHorariosRuta: PropTypes.func.isRequired,
}

export default FormaCargarHorariosRuta
