import React from 'react'
import { Container, Loader, Dimmer, Divider, Grid, Button, Segment, Form, TextArea, Message } from "semantic-ui-react";
import axios from 'axios'

import PropTypes from 'prop-types'
import { ENDPOINT_APOYO, ENDPOINT_CREAR_APOYO } from '../../constants/endpoints';

import { downloadExcel, validarStorage, extraerOpcionesOperadores, tipoUsuario } from '../../helpers'
import BaseComponent from '../BaseComponent';
import ListaApoyos from '../Apoyos/ListaApoyos'

class AdministrarApoyos extends BaseComponent {

  constructor(props) {
    super(props);

    this.initialValues = {
      usuario: this.props.login.user.id,
      operador: 0,
      bitacora: '',
      nota: '',
      loading: false
    }

    this.state = {
      ...this.initialValues
    }
  }

  componentDidMount() {
    this.props.requestOperadores(['activo=true', 'sort(+nombre)'])
  }

  onChangeValue = (event, data) => {
    if (data) {
      this.setState({
        [data.name]: data.value
      })
    } else {
      this.setState({
        [event.target.name]: event.target.value
      })
    }
  }

  getList = (e) => {
    e.preventDefault();
    this.setState({
      active: true
    })

    const url = `${ENDPOINT_APOYO}`

    axios.get(url)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data.success === true) {
            return this.setState({
              data: response.data.data.incidente,
              active: false
            });

          } else {
            console.log('Error');
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        return this.setState({
          active: false
        });
      })
  }

  onSubmit = () => {
    const { usuario, operador, bitacora, nota } = this.state

    this.setState({
      loading: true
    })

    const params = {
      id_usuario: usuario,
      id_operador: operador,
      id_bitacora: parseInt(bitacora),
      nota: nota
    }

    const url = `${ENDPOINT_CREAR_APOYO}?id_usuario=${params.id_usuario}&id_operador=${params.id_operador}&id_bitacora=${params.id_bitacora}&nota=${params.nota}`

    axios.post(url)
      .then((response) => {
        if (response.status === 200) {
          return this.setState({
            response: response.data,
            loading: false
          });
        } else {
          console.log('Error');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .then(() => {
        return this.setState({
          ...this.initialValues
        });
      })
  }

  onReset = (e) => {
    e.preventDefault();
    this.setState({
      ...this.initialValues,
    })
  }

  render() {
    const { data, response } = this.state
    const downloadFile = downloadExcel;

    const { login, operadores } = this.props;
    const permisos = validarStorage(login.user).permisos

    let acceso = false;
    tipoUsuario.map((item, key) => {
      if (item.tipo == 'Monitoristas') {
        console.log("item", item.ids);
        item.ids.map((id, index) => {
          if (JSON.parse(sessionStorage.getItem("usuario")).id == id) {

            acceso = false
          }
          else {
            acceso = true
          }
        })
      }
    })


    return (

      acceso ?
        <>
          <Container fluid style={{ padding: "10px", textAlign: 'left' }}>
            <Dimmer active={this.state.active} inverted>
              <Loader inverted>Cargando...</Loader>
            </Dimmer>

            <Grid columns={2} stackable textAlign='left'>
              <Grid.Row verticalAlign='middle'>
                <Grid.Column>
                  <h3>Registro de apoyo operadores</h3>
                  <p style={{ fontWeight: '500', color: 'rgb(94, 94, 94)' }}>Aquí pordrás registrar los comentarios que te hacen los operadores cuando se les solicita apoyar. Es importante que registres los tres campos solicitados.</p>
                </Grid.Column>
                <Divider hidden />
              </Grid.Row>
            </Grid>

            <Segment color='orange' container="true" textAlign={"left"} >
              <Form onSubmit={this.onSubmit} name="formulario" method="post" >
                <Form.Group widths='equal' >
                  < Form.Select fluid search selection name='operador'
                    label='Operador al que se solicita el apoyo'
                    placeholder='Selecciona un operador'
                    loading={operadores.fetching}
                    value={this.state.operador}
                    options={operadores.all.map(extraerOpcionesOperadores)}
                    onChange={this.onChangeValue}
                    required
                  />
                  <Form.Input fluid
                    label='Número de bitácora que requiere apoyo'
                    placeholder='Ingresa el número de bitácora'
                    name={'bitacora'}
                    type='number'
                    value={this.state.bitacora}
                    onChange={this.onChangeValue}
                    required
                  />
                </Form.Group>

                <Form.Group widths='equal' >
                  <Form.Field >
                    <TextArea id="comentarios"
                      name="nota"
                      value={this.state.nota}
                      placeholder='Registra los comentarios que desees'
                      style={{ minHeight: 150, maxHeight: 300 }}
                      onChange={this.onChangeValue}
                      required
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group unstackable fluid="true" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button id="btn-formulario"
                    type='submit'
                    color='orange'
                    fluid loading={this.state.loading} style={{ width: '180px' }}> Guardar
                  </Button>
                  <Button onClick={this.onReset} fluid style={{ width: '180px' }}>Limpiar</Button>
                  <Button onClick={this.getList} color='teal' fluid style={{ width: '180px' }}>Ver / Actualizar lista</Button>
                </Form.Group>
              </Form>

              {response && response.data.success === true ?
                <Message positive>
                  <Message.Header> Apoyo registrado correctamente </Message.Header>
                </Message>
                :
                <></>
              }

              {response && response.data.success === false ?
                <Message error>
                  <Message.Header>{response.data.message}</Message.Header>
                  <p>Ingresa un número de bitácora valido</p>
                </Message>
                :
                <></>
              }

            </Segment>

            {permisos?.reporteApoyos === true && data &&
              <Grid.Column textAlign='center'>
                <p style={{ fontWeight: '500', color: 'rgb(94, 94, 94)' }}>Descargar reporte en Excel</p>
                <Button color='blue' style={{ margin: '0 3px 0 0' }} onClick={() => downloadFile(data, 'apoyos')}>Descargar</Button>
              </Grid.Column>
            }

            {data &&
              <ListaApoyos data={data} />
            }

          </Container>
        </> :
        <>
          <Message warning>
            <Message.Header>No cuenta con permisos para realizar programaciones</Message.Header>
            <p>Si requieres acceso, favor de validar con Sistemas</p>
          </Message>
        </>


    )
  }
}

AdministrarApoyos.propTypes = {
  requestApoyos: PropTypes.func.isRequired
}

export default AdministrarApoyos