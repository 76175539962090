import React, { Component } from "react";
import { Form, Button, Select, Modal, Icon, TextArea } from "semantic-ui-react";

class ModalContacto extends Component {
    constructor(props){
        super(props)

        this.initialValues = ({
            loading: false,
            abrirModal: false,
            abrir: false,
            ruta: []
        })

        this.state = ({
            ...this.initialValues
        })
    }

  render() {

    const { itemEdicion, onEditarContactos, onHandleChangeValue, onCerrarModal, ruta } = this.props;
    // let { value, idRuta } = this.state;
    let { idRuta } = this.state;

    return (
        <>
        <Modal.Header>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <h3>Editar datos de contactos  </h3>
                <Icon name='close' color='orange' onClick={onCerrarModal}/>
            </div>
        </Modal.Header>
        <Modal.Content>
            { itemEdicion && !itemEdicion.ID_COLAB ?
                <>
                <Form className='form-evaluacion-especial'>
                    <Form.Group widths='equal' className='form-group-inputs'>
                        <Form.Input 
                            fluid 
                            label='Nombre' 
                            placeholder={itemEdicion.NOMBRE}
                            name='nombre'
                            type='text'
                            required
                            onChange={onHandleChangeValue}
                        />
                        <Form.Input 
                            fluid 
                            label='Puesto' 
                            placeholder={itemEdicion.PUESTO}
                            name='puesto'
                            type='text'
                            required
                            onChange={onHandleChangeValue}
                        />
                    </Form.Group>
                    <Form.Group widths='equal' className='form-group-inputs'>
                        <Form.Input 
                            fluid 
                            label='Teléfono' 
                            placeholder={itemEdicion.CELULAR} 
                            name='telefono'
                            type='text'
                            required
                            onChange={onHandleChangeValue}
                        />
                        <Form.Input 
                            fluid 
                            label='Correo' 
                            placeholder={itemEdicion.CORREO_ELECTRONICO}
                            name='correo'
                            type='email'
                            required
                            onChange={onHandleChangeValue}
                        />
                        <Form.Input 
                            fluid 
                            label='Prioridad de contacto' 
                            placeholder={itemEdicion.ORDEN}
                            name='orden'
                            type='number'
                            required
                            onChange={onHandleChangeValue}
                        />
                        <Form.Field
                            label={`Ruta asignada ${itemEdicion.RUTA ? itemEdicion.RUTA:'Sin ruta asignada'}`}
                            control={Select}
                            options={ruta}
                            placeholder='Cambiar ruta asignada'
                            search
                            value={idRuta}
                            name='idRuta'
                            onChange={onHandleChangeValue}
                        />
                    </Form.Group>
                </Form>
                </>
                :
                <>
                <Form className='form-evaluacion-especial'>
                    <div style={{
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}
                    >
                        <ul style={{listStyle: 'none', padding: '0'}}>
                            <li><span style={{fontWeight: '900' }}>Nombre: </span> {itemEdicion.NOMBRE}</li>
                            <li><span style={{fontWeight: '900' }}>Puesto: </span> {itemEdicion.PUESTO}</li>
                            <li><span style={{fontWeight: '900' }}>Celular: </span> {itemEdicion.CELULAR}</li>
                            <li><span style={{fontWeight: '900' }}>Correo: </span> {itemEdicion.CORREO_ELECTRONICO}</li>
                            <li><span style={{fontWeight: '900' }}>Prioridad de contacto: </span> {itemEdicion.ORDEN}</li>
                            <li><span style={{fontWeight: '900' }}>Ruta asignada: </span> {itemEdicion.RUTA ? itemEdicion.RUTA:'Sin ruta asignada'}</li>
                            <li><span style={{fontWeight: '900' }}>Horario: </span> {itemEdicion.HORARIO }</li>
                        </ul>
                        <Form.Field style={{width:'50%'}}>
                            <label>Horario y días que labora</label>
                            <TextArea
                                label="Ingresa el horario y días que labora"
                                name="horario"
                                rows={3}
                                onChange={onHandleChangeValue}
                            />
                        </Form.Field>
                    </div>
                </Form>
                </>
            }
        </Modal.Content>
        <Modal.Actions>
            <Button color='orange' onClick={() => {onEditarContactos(itemEdicion)}}>Guardar</Button>
        </Modal.Actions>
        </>
    );
  }
}

export default ModalContacto;