import BaseModel from "./BaseModel";
import Usuario from "./Usuario";
import Operador from "./Operador";
import Unidad from './Unidad'

class Apoyo extends BaseModel {
    constructor(data) {
        super(data);
        this.usuario = this.getAttribute('usuario')
        this.operador = this.getAttribute('operador')
        this.bitacora = this.getAttribute('bitacora')
        this.fecha = this.getAttribute('fecha')
        this.nota = this.getAttribute('nota')
    }

    /**
     * @returns {Usuario}
     */
    getUsuario () {
        if(!this.usuario)
            this.usuario = this.getRelationData('usuario') ? new Usuario(this.getRelationData('usuario')) : null;
        return this.usuario
    }

    /**
     * @returns {Operador}
     */
    getOperador () {
        if(!this.operador)
            this.operador = this.getRelationData('operador') ? new Operador(this.getRelationData('operador')) : null;
        return this.operador
    }

    /**
     * @returns {Unidad}
     */
        getUnidad () {
        if (!this.unidad)
        this.unidad = this.getRelationData('unidad') ? new Unidad(this.getRelationData('unidad')) : { id: 0, tipo: 'Sin definir'}
        return this.unidad
    }
}

export default Apoyo