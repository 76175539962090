export const REQUEST_OPERADORES = "REQUEST_OPERADORES"
export const REQUEST_OPERADORES_SUCCESS = "REQUEST_OPERADORES_SUCCESS"
export const REQUEST_OPERADORES_FAILED = "REQUEST_OPERADORES_FAILED"
export const INVALIDAR_OPERADORES = "INVALIDAR_OPERADORES"


export const REQUEST_CLIENTES = 'REQUEST_CLIENTES'
export const REQUEST_SUCCESS_CLIENTES = 'REQUEST_SUCCESS_CLIENTES'
export const REQUEST_FAILED_CLIENTES = 'REQUEST_FAILED_CLIENTES'
export const INVALIDAR_CLIENTES = 'INVALIDAR_CLIENTES'


export const REQUEST_ESTRUCTURAS = "REQUEST_ESTRUCTURAS"
export const REQUEST_ESTRUCTURAS_SUCCESS = "REQUEST_ESTRUCTURAS_SUCCESS"
export const REQUEST_ESTRUCTURAS_FAILED = "REQUEST_ESTRUCTURAS_FAILED"
export const INVALIDATE_ESTRUCTURAS = "INVALIDATE_ESTRUCTURAS"


export const REQUEST_SUBCONTRATADOS = "REQUEST_SUBCONTRATADOS"
export const REQUEST_SUBCONTRATADOS_SUCCESS = "REQUEST_SUBCONTRATADOS_SUCCESS"
export const REQUEST_SUBCONTRATADOS_FAILED = "REQUEST_SUBCONTRATADOS_FAILED"
export const INVALIDAR_SUBCONTRATADOS = "INVALIDAR_SUBCONTRATADOS"


export const REQUEST_CAUSAS = "REQUEST_CAUSAS"
export const REQUEST_CAUSAS_SUCCESS = "REQUEST_CAUSAS_SUCCESS"
export const REQUEST_CAUSAS_FAILED = "REQUEST_CAUSAS_FAILED"


export const REQUEST_EFECTOS = "REQUEST_EFECTOS"
export const REQUEST_EFECTOS_SUCCESS = "REQUEST_EFECTOS_SUCCESS"
export const REQUEST_EFECTOS_FAILED = "REQUEST_EFECTOS_FAILED"


export const REQUEST_PROGRAMAR_SERVICIOS = "REQUEST_PROGRAMAR_SERVICIOS"
export const REQUEST_PROGRAMAR_SERVICIOS_SUCCESS = "REQUEST_PROGRAMAR_SERVICIOS_SUCCESS"
export const REQUEST_PROGRAMAR_SERVICIOS_FAILED = "REQUEST_PROGRAMAR_SERVICIOS_FAILED"


export const REQUEST_PROVEEDORES = "REQUEST_PROVEEDORES"
export const REQUEST_PROVEEDORES_SUCCESS = "REQUEST_PROVEEDORES_SUCCESS"
export const REQUEST_PROVEEDORES_FAILED = "REQUEST_PROVEEDORES_FAILED"
export const INVALIDATE_PROVEEDORES = "INVALIDATE_PROVEEDORES"


export const REQUEST_RUTAS = "REQUEST_RUTAS"
export const REQUEST_RUTAS_SUCCESS = "REQUEST_RUTAS_SUCCESS"
export const REQUEST_RUTAS_FAILED = "REQUEST_RUTAS_FAILED"
export const INVALIDAR_RUTAS = "INVALIDAR_RUTAS"


export const REQUEST_POSICION_UNIDADES = "REQUEST_POSICION_UNIDADES"
export const REQUEST_POSICION_UNIDADES_SUCCESS = "REQUEST_POSICION_UNIDADES_SUCCESS"
export const REQUEST_POSICION_UNIDADES_FAILED = "REQUEST_POSICION_UNIDADES_FAILED"


export const REQUEST_SERVICIOS = 'REQUEST_SERVICIOS'
export const REQUEST_SUCCESS_SERVICIOS = 'REQUEST_SUCCESS_SERVICIOS'
export const REQUEST_FAILED_SERVICIOS = 'REQUEST_FAILED_SERVICIOS'
export const INVALIDAR_SERVICIOS = 'INVALIDAR_SERVICIOS'
export const AGREGAR_A_SELECCION = 'AGREGAR_A_SELECCION'
export const REMOVER_DE_SELECCION = 'REMOVER_DE_SELECCION'
export const LIMPIAR_SELECCION = 'LIMPIAR_SELECCION'


export const REQUEST_EXCEPCION_SERVICIO = 'REQUEST_EXCEPCION_SERVICIO'
export const REQUEST_EXCEPCION_SERVICIO_SUCCESS = 'REQUEST_EXCEPCION_SERVICIO_SUCCESS'
export const REQUEST_EXCEPCION_SERVICIO_FAILED = 'REQUEST_EXCEPCION_SERVICIO_FAILED'


export const REQUEST_TRANSFERIR_SERVICIOS = "REQUEST_TRANSFERIR_SERVICIOS"
export const REQUEST_TRANSFERIR_SERVICIOS_SUCCESS = "REQUEST_TRANSFERIR_SERVICIOS_SUCCESS"
export const REQUEST_TRANSFERIR_SERVICIOS_FAILED = "REQUEST_TRANSFERIR_SERVICIOS_FAILED"

export const INICIAR_TRANSFERENCIA_SERVICIO = 'INICIAR_TRANSFERENCIA_SERVICIO'
export const FINALIZAR_TRANSFERENCIA_SERVICIO = 'FINALIZAR_TRANSFERENCIA_SERVICIO'

export const INICIAR_RESCATE_SERVICIO = 'INICIAR_RESCATE_SERVICIO'
export const FINALIZAR_RESCATE_SERVICIO = 'FINALIZAR_RESCATE_SERVICIO'
export const REQUEST_RESCATE_SERVICIO = "REQUEST_RESCATE_SERVICIO"
export const REQUEST_RESCATE_SERVICIO_SUCCESS = "REQUEST_RESCATE_SERVICIO_SUCCESS"
export const REQUEST_RESCATE_SERVICIO_FAILED = "REQUEST_RESCATE_SERVICIO_FAILED"

export const INICIAR_CANCELACION_SERVICIO = 'INICIAR_CANCELACION_SERVICIO'
export const FINALIZAR_CANCELACION_SERVICIO = 'FINALIZAR_CANCELACION_SERVICIO'
export const REQUEST_CANCELAR_SERVICIO = "REQUEST_CANCELAR_SERVICIO"
export const REQUEST_CANCELAR_SERVICIO_SUCCESS = "REQUEST_CANCELAR_SERVICIO_SUCCESS"
export const REQUEST_CANCELAR_SERVICIO_FAILED = "REQUEST_CANCELAR_SERVICIO_FAILED"


export const INICIAR_INCIDENTE_SERVICIO = "INICIAR_INCIDENTE_SERVICIO"
export const FINALIZAR_INCIDENTE_SERVICIO = "FINALIZAR_INCIDENTE_SERVICIO"
export const REQUEST_INCIDENTE_SERVICIO = "REQUEST_INCIDENTE_SERVICIO"
export const REQUEST_INCIDENTE_SERVICIO_SUCCESS = "REQUEST_INCIDENTE_SERVICIO_SUCCESS"
export const REQUEST_INCIDENTE_SERVICIO_FAILED = "REQUEST_INCIDENTE_SERVICIO_FAILED"


export const REQUEST_BITACORAS = "REQUEST_BITACORAS";
export const REQUEST_BITACORAS_SUCCESS = "REQUEST_BITACORAS_SUCCESS";
export const REQUEST_BITACORAS_FAILED = "REQUEST_BITACORAS_FAILED";
export const REQUEST_INVALIDAR_BITACORAS = "REQUEST_INVALIDAR_BITACORAS";


export const INICIAR_DESCONFIRMAR_BITACORA = 'INICIAR_DESCONFIRMAR_BITACORA'
export const FINALIZAR_DESCONFIRMAR_BITACORA = 'FINALIZAR_DESCONFIRMAR_BITACORA'
export const REQUEST_DESCONFIRMAR_BITACORA = "REQUEST_DESCONFIRMAR_BITACORA"
export const REQUEST_DESCONFIRMAR_BITACORA_SUCCESS = "REQUEST_DESCONFIRMAR_BITACORA_SUCCESS"
export const REQUEST_DESCONFIRMAR_BITACORA_FAILED = "REQUEST_DESCONFIRMAR_BITACORA_FAILED"


export const REQUEST_SERVICIOS_PROGRAMADOS = "REQUEST_SERVICIOS_PROGRAMADOS";
export const REQUEST_SERVICIOS_PROGRAMADOS_SUCCESS = "REQUEST_SERVICIOS_PROGRAMADOS_SUCCESS";
export const REQUEST_SERVICIOS_PROGRAMADOS_FAILED = "REQUEST_SERVICIOS_PROGRAMADOS_FAILED";


export const REQUEST_DISPOSITIVOS = "REQUEST_DISPOSITIVOS";
export const REQUEST_DISPOSITIVOS_SUCCESS = "REQUEST_DISPOSITIVOS_SUCCESS";
export const REQUEST_DISPOSITIVOS_FAILED = "REQUEST_DISPOSITIVOS_FAILED";


export const INICIAR_MONITOR = 'INICIAR_MONITOR'
export const DETENER_MONITOR = 'DETENER_MONITOR'


export const INICIADO = 'Iniciado'
export const DETENIDO = 'Detenido'
export const INICIAR_RELOJ = 'INICIAR_RELOJ'
export const DETENER_RELOJ = 'DETENER_RELOJ'
export const TICK = 'TICK'


export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS'
export const REQUEST_LOGIN_FAILED = 'REQUEST_LOGIN_FAILED'
export const INVALIDAR_LOGIN = 'INVALIDAR_LOGIN'


export const REQUEST_PRECIOS_UPDATE = 'REQUEST_PRECIOS_UPDATE'
export const REQUEST_PRECIOS_UPDATE_SUCCESS = 'REQUEST_PRECIOS_UPDATE_SUCCESS'
export const REQUEST_PRECIOS_UPDATE_FAILED = 'REQUEST_PRECIOS_UPDATE_FAILED'


export const REQUEST_HORARIOS_RUTA = 'REQUEST_HORARIOS_RUTA'
export const REQUEST_HORARIOS_RUTA_UPDATE = 'REQUEST_HORARIOS_RUTA_UPDATE'
export const REQUEST_HORARIOS_RUTA_SUCCESS = 'REQUEST_HORARIOS_RUTA_SUCCESS'
export const REQUEST_HORARIOS_RUTA_FAILED = 'REQUEST_HORARIOS_RUTA_FAILED'


export const REQUEST_SANITIZACIONES = "REQUEST_SANITIZACIONES"
export const REQUEST_SANITIZACIONES_SUCCESS = "REQUEST_SANITIZACIONES_SUCCESS"
export const REQUEST_SANITIZACIONES_FAILED = "REQUEST_SANITIZACIONES_FAILED"
export const INVALIDAR_SANITIZACIONES= "INVALIDAR_SANITIZACIONES"


export const REQUEST_UNIDADES = "REQUEST_UNIDADES"
export const REQUEST_UNIDADES_SUCCESS = "REQUEST_UNIDADES_SUCCESS"
export const REQUEST_UNIDADES_FAILED = "REQUEST_UNIDADES_FAILED"

export const INICIAR_CAMBIO_INDIVIDUAL_PRECIO_SERVICIO = 'INICIAR_CAMBIO_INDIVIDUAL_PRECIO_SERVICIO'
export const FINALIZAR_CAMBIO_INDIVIDUAL_PRECIO_SERVICIO = 'FINALIZAR_CAMBIO_INDIVIDUAL_PRECIO_SERVICIO'
export const REQUEST_CAMBIO_INDIVIDUAL_PRECIO_SERVICIO = "REQUEST_CAMBIO_INDIVIDUAL_PRECIO_SERVICIO"
export const REQUEST_CAMBIO_INDIVIDUAL_PRECIO_SERVICIO_SUCCESS = "REQUEST_CAMBIO_INDIVIDUAL_PRECIO_SERVICIO_SUCCESS"
export const REQUEST_CAMBIO_INDIVIDUAL_PRECIO_SERVICIO_FAILED = "REQUEST_CAMBIO_INDIVIDUAL_PRECIO_SERVICIO_FAILED"

export const INICIAR_CAMBIO_INDIVIDUAL_HORARIO_SERVICIO = 'INICIAR_CAMBIO_INDIVIDUAL_HORARIO_SERVICIO'
export const FINALIZAR_CAMBIO_INDIVIDUAL_HORARIO_SERVICIO = 'FINALIZAR_CAMBIO_INDIVIDUAL_HORARIO_SERVICIO'
export const REQUEST_CAMBIO_INDIVIDUAL_HORARIO_SERVICIO = "REQUEST_CAMBIO_INDIVIDUAL_HORARIO_SERVICIO"
export const REQUEST_CAMBIO_INDIVIDUAL_HORARIO_SERVICIO_SUCCESS = "REQUEST_CAMBIO_INDIVIDUAL_HORARIO_SERVICIO_SUCCESS"
export const REQUEST_CAMBIO_INDIVIDUAL_HORARIO_SERVICIO_FAILED = "REQUEST_CAMBIO_INDIVIDUAL_HORARIO_SERVICIO_FAILED"

export const REQUEST_AUDITORIAS = 'REQUEST_AUDITORIAS'
export const REQUEST_AUDITORIAS_SUCCESS = 'REQUEST_AUDITORIAS_SUCCESS'
export const REQUEST_AUDITORIAS_FAILED = 'REQUEST_AUDITORIAS_FAILED'
export const START_CREATE_AUDITORIA = "START_CREATE_AUDITORIAS"
export const END_CREATE_AUDITORIA = "END_CREATE_AUDITORIAS"
export const REQUEST_CREATE_AUDITORIA = "REQUEST_CREATE_AUDITORIAS";
export const REQUEST_CREATE_AUDITORIA_SUCCESS = "REQUEST_CREATE_AUDITORIAS_SUCCESS";
export const REQUEST_CREATE_AUDITORIA_FAILED = "REQUEST_CREATE_AUDITORIAS_FAILED";

export const REQUEST_REVISIONES = 'REQUEST_REVISIONES'
export const REQUEST_REVISIONES_SUCCESS = 'REQUEST_REVISIONES_SUCCESS'
export const REQUEST_REVISIONES_FAILED = 'REQUEST_REVISIONES_FAILED'
export const START_CREATE_REVISIONES = "START_CREATE_REVISIONES"
export const END_CREATE_REVISIONES = "END_CREATE_REVISIONES"
export const REQUEST_CREATE_REVISIONES = "REQUEST_CREATE_REVISIONES";
export const REQUEST_CREATE_REVISIONES_SUCCESS = "REQUEST_CREATE_REVISIONES_SUCCESS";
export const REQUEST_CREATE_REVISIONES_FAILED = "REQUEST_CREATE_REVISIONES_FAILED";

export const REQUEST_APOYOS = 'REQUEST_APOYOS'
export const REQUEST_APOYOS_SUCCESS = 'START_CREATE_APOYOS_SUCCESS'
export const REQUEST_APOYOS_FAILED = 'START_CREATE_APOYOS_FAILED'
export const REQUEST_CREATE_APOYO = 'START_CREATE_APOYO'
export const REQUEST_CREATE_APOYO_SUCCESS = 'START_CREATE_APOYO_SUCCESS'
export const REQUEST_CREATE_APOYO_FAILED = 'START_CREATE_APOYO_FAILED'