import BaseModel from "./BaseModel";
class Usuario extends BaseModel {
  constructor(data) {
    super(data);

    this.nombre = this.getAttribute("nombre");
    this.email = this.getAttribute("email");
    this.acceso = this.getAttribute("acceso");
    this.activo = this.getAttribute("activo");
    this.iniciales = this.getAttribute("iniciales");
    this.supervisor = this.getAttribute("supervisor");

    //this.id = 4298;
  }
}

export default Usuario;

/*

puedeVerFacturacion() {
  return this.id === 3746 || this.id === 3826 || this.id === 3810 || this.id === 3794 || this.id === 1 || this.id === 3923 || //** DESARROLLO
         this.id === 3972 || this.id === 1445 || this.id === 3702
}

puedeCambiarPrecios() {
  return this.id === 3746 || this.id === 3826 || this.id === 3810 || this.id === 3794 || this.id === 1 || this.id === 3923 || //** DESARROLLO
         this.id === 3972 || this.id === 1445
}


puedeProgramarServicios() {
  return this.id === 3746 || this.id === 3826 || this.id === 3810 || this.id === 3691 || this.id === 3794 || this.id === 1 || this.id === 3923 || //** DESARROLLO
         this.id === 3690 || this.id === 1605 || this.id === 3889 ||                      //** JEFES LOGISTICA
         this.id === 3689 || this.id === 3869 || this.id === 3927 ||                      //** PROGRAMADORES
         this.id === 3743 ||                                                              //** COORDINACION MONITORISTAS
         this.id === 3840 || this.id === 3906 || this.id === 3907 || this.id === 3973 ||  //** MONITORISTAS
         this.id === 3825 || this.id === 3908 || this.id === 3930 || this.id === 3958 ||
         this.id === 3969

}

puedeAjustarBitacora() {
  return this.id === 3746 || this.id === 3826 || this.id === 3810 || this.id === 3794 || this.id === 1 || this.id === 3923 || //** DESARROLLO
         this.id === 3690 || this.id === 1605 || this.id === 3889 ||    //** JEFES LOGISTICA
         this.id === 3869 || this.id === 3927 || this.id === 3689 ||     //** PROGRAMADORES
         this.id === 1478 || this.id === 2624 ||                         //** ATENCIÓN A CLIENTES
         this.id === 3705 || this.id === 3921 || this.id === 3844 || this.id === 3836 ||  //** INPLANT
         this.id === 3837 || this.id === 3706

}

puedeAjustaEsquemas() {
  return this.id === 3746 || this.id === 3826 || this.id === 3810 || this.id === 3794 || this.id === 1 || this.id === 3923 ||     //** DESARROLLO
         this.id === 3690   //** JEFES LOGISTICA
}

puedeVerEsquemas() {
  return this.id === 3927 || this.id === 3889 || this.id === 1605 //** JEFE LOGISTICA Y AUXILIAR LOGISTICA
}

puedeEditarIncidente() {
  return this.id === 3746 || this.id === 3826 || this.id === 3810 || this.id === 3794 || this.id === 1 || this.id === 3923 ||     //** DESARROLLO
         this.id === 3690   //** JEFES LOGISTICA
}

puedeObtenerReporteApoyos() {
  return this.id === 3746 || this.id === 3826 || this.id === 3810 || this.id === 3794 || this.id === 1 || this.id === 3923 ||     //** DESARROLLO
         this.id === 3690 || this.id === 3927 || this.id === 3889 || this.id === 1605 //** LOGISTICA
}


puedeForzarProgramacionTransferencias() {
  return this.id === 3746 || this.id === 3826 || this.id === 3810 || this.id === 3794 || this.id === 1 || this.id === 3923 || //** DESARROLLO
         this.id === 3690
}

puedeDesconfirmarBitacora() {
  return this.id === 3746 || this.id === 3826 || this.id === 3810 || this.id === 3794 || this.id === 1 || this.id === 3923 || //** DESARROLLO
         this.id === 3690
}

puedeVerAuditorias(){
  return this.id === 3746 || this.id === 3826 || this.id === 3810 || this.id === 3794 || this.id === 1 || this.id === 3923 || //** DESARROLLO
         this.id === 3821 || this.id === 3813 || this.id === 3804 || this.id === 3937 || //** AUDITORES
         this.id === 3960
}
}

export default Usuario

/*
==> DESARROLLO <==
3746 CLAUDIA ALEJANDRA DURÁN OLMEDO
3826 GABRIEL VAZQUEZ RUIZ
3810 ENRIQUE BENAVIDES GONZÁLEZ
3691 FERNANDA ELIZABETH ALVAREZ MUÑOZ
3794 JOSE ALFONSO MENDEZ SALAS
3923 LUIS ARMANDO QUINTANA OLIVARES

===> DIRECCION <==
1	EDUARDO PADILLA MEJIA

==> ADMINISTRATIVOS <==
3972 MIGUEL ULISES RAMIREZ IBARRA
1445 FERNANDO NAVARRO VIRGEN
3702 MA.GUADALUPE TORRES SANCHEZ
3966	MARGARITA CAMARENA BARAJAS

==> JEFE ATENCIÓN A CLIENTES <==
1478	VERONICA DEL CAMPO
2624	YAZMIN DANAHE GARIBAY

==> AUDITORES <=
3821	JESUS ALEJANDRO JARQUIN HERRERA
3813	JORGE HUMBERTO GUARDADO SANCHEZ
3804	JESUS CISNEROS CRUZ
3937	JOSE RAMON FRANCO DIAZ
3960	HECTOR ADRIAN GONZALEZ TORRES

==> INPLANT <==
3705	ALMA ANGÉLICA JACOBO IBARRA
3921	KENIA VIVIANA BARAJAS SANCHEZ
3844	LUIS ANGEL DE LA TORRE GUTIERREZ
3836	MARIA GUADALUPE GONZALEZ RODRIGUEZ
3837	SHARON RIESTRA BATISTA
3706	WENDY CASTILLO SAAVEDRA

==> JEFE LOGISTICA <==
3690 ISMAEL LAUREAN GUIJOZA
3889 LUIS ALONSO RENTERIA HERRERA
1605 RAMON ALEJANDRO LIRA CASTILLO

===> AUXILIAR DE LOGISTICA <===
3927 ESMERALDA GUADALUPE PEÑA GARCÍA

==> PROGRAMADOR DE RUTA <==
3689 MARIA LUISA ARTIGA MALDONADO
3869 MARIA FERNANDA FUENTES ALVARADO

  ==> COORDINACIÓN MONITORISTAS <==
3743 CLARA ESTHER ELIAS GUTIERREZ

==> MONITORISTAS <==
3907 OSCAR ALBERTO NUÑEZ PEREZ
3906 PAOLA ANGELINA DEL RIO ROJAS
3825 GABRIELA ELIZABETH MARTINEZ GARCIA
3930 LAURA ELIZABETH CORONA RODRIGUEZ
3840 JUDITH HERRERA ROSALES
3908 MONICA DANIELA ROMERO BARRAGAN
3958 VERONICA DEL REFUGIO VELARDE CHAVEZ
3969 STEPHANIE GUADALUPE DÍAZ SÁNCHEZ
3973 MIRIAM ALEJANDRA GARCÍA CARRILLO
4001 PRISCILA ADAIA LOPEZ CONTRERAS

==> BAJAS <==
365  Alejandra Martínez
390  Gustavo Orozco Vázquez
3839 Christian Yaeht Cedeño Vargas
3841 Juan Carlos Aceves Cárdenas
401  Miguel Machuca López
80   Juan Jose Cortes Guzmán
3929 OSCAR ALEJANDRO PEREZ MARQUEZ
330  CLAUDIAN LIZBETH OSEGUERA JIMÉNEZ
3838	NESTOR OROZCO CORREA
*/
