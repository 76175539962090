import React from "react";
import { Table, Image, Grid, List } from "semantic-ui-react";
import iconoProgramado from "../../styles/iconos/programado.png";
import iconoSinConfirmar from "../../styles/iconos/sin_confirmar.png";
import iconoCancelado from "../../styles/iconos/cancelado.png";
import iconoTerminado from "../../styles/iconos/terminado.png";
import iconoMensaje_1 from "../../styles/iconos/mensaje_1.png";
import iconoMensaje_2 from "../../styles/iconos/mensaje_2.png";
import iconoConfirmado from "../../styles/iconos/confirmado.png";
import iconoEnRuta from "../../styles/iconos/en_ruta.png";
import iconoAliado from "../../styles/iconos/aliado.png";
import iconoNotificacion from "../../styles/iconos/notificacion.png";
import iconoComentario from "../../styles/iconos/comentario.png";
import iconoUnidadKm from "../../styles/iconos/unidad_y_km.png";
import iconoVerMapa from "../../styles/iconos/ver_mapa.png";

const InformacionMonitoreo = () => {
  return (
    <Grid padded>
      <Grid.Row only="computer tablet">
        <Grid.Column style={{ paddingLeft: "0", paddingRight: "0" }}>
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="1">
                  Código de colores
                </Table.HeaderCell>
                <Table.HeaderCell>Significado</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Image src={iconoProgramado} size="small" />
                </Table.Cell>
                <Table.Cell>
                  <p>
                    Servicio que se asignó a un operador pero que no está dentro
                    del tiempo límite de inicio y no se ha confirmado en la
                    aplicación.
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Image src={iconoSinConfirmar} size="small" />
                </Table.Cell>
                <Table.Cell>
                  <p>
                    Servicio que está en 30 min o menos de iniciar y no se ha
                    confirmado en la aplicación.
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Image src={iconoMensaje_1} size="small" />
                </Table.Cell>
                <Table.Cell>
                  <p style={{ textAlign: "left" }}>
                    Cuando un servicio este sin confirmar y dentro de los 30
                    min. previos a iniciar su ruta aparecerá este botón con el
                    cual podrás enviar un mensaje por whatsApp al operador que
                    esta asignado para ese servicio. Solo necesitas dar click y
                    el mensaje se enviará.
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Image src={iconoMensaje_2} size="small" />
                </Table.Cell>
                <Table.Cell>
                  <p style={{ textAlign: "left" }}>
                    Cuando mandes un mensaje al operador para que confirme la
                    bitácora aparecerá la cantidad mensaje que se han enviado al
                    operador solicitando que confirme su servicio en la
                    aplicación.
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Image src={iconoEnRuta} size="small" />
                </Table.Cell>
                <Table.Cell>
                  <p>
                    Servicio que ya fue confirmado en la aplicación y está
                    dentro de su tiempo de ruta.
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Image src={iconoConfirmado} size="small" />
                </Table.Cell>
                <Table.Cell>
                  <p>
                    Servicio que ya fue confirmado en la aplicación y que aun no
                    comienza su tiempo de ruta.
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Image src={iconoTerminado} size="small" />
                </Table.Cell>
                <Table.Cell>
                  <p>
                    Servicio que ya se dió por terminado en la aplicación por
                    parte del operador.
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Image src={iconoCancelado} size="small" />
                </Table.Cell>
                <Table.Cell>
                  <p>Servicio ha sido cancelado.</p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Image src={iconoUnidadKm} size="small" />
                </Table.Cell>
                <Table.Cell>
                  <p>
                    El servicio lo realiza uno de nuestros operadores. Muestra
                    la unidad y los kilómetros que lleva en ruta.
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Image src={iconoAliado} size="small" />
                </Table.Cell>
                <Table.Cell>
                  <p>El servicio lo realiza uno de nuestros aliados.</p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Image src={iconoNotificacion} size="small" />
                </Table.Cell>
                <Table.Cell>
                  <p>
                    Este botón te permite enviar una notificación por WhatsApp a
                    nuestros clientes.
                  </p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Image src={iconoComentario} size="small" />
                </Table.Cell>
                <Table.Cell>
                  <p>Se muestra cuando el servicio tiene algun comentario.</p>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Image src={iconoVerMapa} size="small" />
                </Table.Cell>
                <Table.Cell>
                  <p>Este botón muestra la unidad en el mapa.</p>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row only="mobile">
        <Grid.Column style={{ paddingLeft: "0", paddingRight: "0" }}>
          <List divided relaxed>
            <List.Item>
              <List.Content>
                <List.Header>
                  <h3>Código de colores</h3>
                </List.Header>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Image src={iconoProgramado} size="small" centered />
                <List.Description>
                  <p style={{ textAlign: "left" }}>
                    Servicio que se asignó a un operador pero que no está dentro
                    del tiempo límite de inicio y no se ha confirmado en la
                    aplicación.
                  </p>
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Image src={iconoSinConfirmar} size="small" centered />
                <List.Description>
                  <p style={{ textAlign: "left" }}>
                    Servicio que está en 30 min o menos de iniciar y no se ha
                    confirmado en la aplicación.
                  </p>
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Image src={iconoMensaje_1} size="small" centered />
                <List.Description>
                  <p style={{ textAlign: "left" }}>
                    Cuando un servicio este sin confirmar y dentro de los 30
                    min. previos a iniciar su ruta aparecerá este botón con el
                    cual podrás enviar un mensaje por whatsApp al operador que
                    esta asignado para ese servicio. Solo necesitas dar click y
                    el mensaje se enviará.
                  </p>
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Image src={iconoMensaje_2} size="small" centered />
                <List.Description>
                  <p style={{ textAlign: "left" }}>
                    Cuando mandes un mensaje al operador para que confirme la
                    bitácora aparecerá la cantidad mensaje que se han enviado al
                    operador solicitando que confirme su servicio en la
                    aplicación.
                  </p>
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Image src={iconoEnRuta} size="small" centered />
                <List.Description>
                  <p style={{ textAlign: "left" }}>
                    Servicio que ya fue confirmado en la aplicación y está
                    dentro de su tiempo de ruta.
                  </p>
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Image src={iconoConfirmado} size="small" centered />
                <List.Description>
                  <p style={{ textAlign: "left" }}>
                    Servicio que ya fue confirmado en la aplicación y que aun no
                    comienza su tiempo de ruta.
                  </p>
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Image src={iconoTerminado} size="small" centered />
                <List.Description>
                  <p style={{ textAlign: "left" }}>
                    Servicio que ya se dió por terminado en la aplicación por
                    parte del operador.
                  </p>
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Image src={iconoCancelado} size="small" centered />
                <List.Description>
                  <p style={{ textAlign: "left" }}>
                    Servicio ha sido cancelado.
                  </p>
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Image src={iconoUnidadKm} size="small" centered />
                <List.Description>
                  <p style={{ textAlign: "left" }}>
                    El servicio lo realiza uno de nuestros operadores. Muestra
                    la unidad y los kilómetros que lleva en ruta.
                  </p>
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Image src={iconoAliado} size="small" centered />
                <List.Description>
                  <p style={{ textAlign: "left" }}>
                    El servicio lo realiza uno de nuestros aliado.
                  </p>
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Image src={iconoNotificacion} size="small" centered />
                <List.Description>
                  <p style={{ textAlign: "left" }}>
                    Este botón te permite enviar una notificación por WhatsApp a
                    nuestros clientes.
                  </p>
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Image src={iconoComentario} size="small" centered />
                <List.Description>
                  <p style={{ textAlign: "left" }}>
                    Se muestra cuando el servicio tiene algun comentario.
                  </p>
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Image src={iconoVerMapa} size="small" centered />
                <List.Description>
                  <p style={{ textAlign: "left" }}>
                    Este botón muestra la unidad en el mapa.
                  </p>
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default InformacionMonitoreo;
