import React, { Component } from "react";
import { Container, Tab } from "semantic-ui-react";
import BuscarContacto from "./BuscarContacto";
import CrearContacto from "./CrearContacto";


class Contactos extends Component {
    constructor(props){
        super(props)

        this.state = ({
            
        })
    }

  render() {
    const panes = [
        { menuItem: 'Buscar contacto', render: () => 
        <Tab.Pane>
            <BuscarContacto />
        </Tab.Pane> 
        },
        { menuItem: 'Crear contacto', render: () => 
        <Tab.Pane>
            <CrearContacto />
        </Tab.Pane> 
        },
      ]
    return (
      <Container fluid style={{ padding: "10px" }}>
        <Tab panes={panes} />
      </Container>
    );
  }
}

export default Contactos;