import React from 'react'
import {Segment, Statistic, Icon, Message} from 'semantic-ui-react'
import PropTypes from 'prop-types'

const Resultados = ({transferencias, login}) => {

    if (transferencias.received_date && !transferencias.all.length && !transferencias.fetching) {
        return (
            <Message info>
                <Message.Header>No se realizó la transferencia</Message.Header>
                <Message.List>No se realizó ningún cambio debido a uno de estos tres factores:
                    <Message.Item>El operador seleccionado ya cuenta con el servicio</Message.Item>
                    <Message.Item>El operador que tiene el servicio ya lo confirmó</Message.Item>
                    <Message.Item>El horario del servicio choca con el horario de otro servicio</Message.Item>
                    <Message.Item>Las fechas seleccionadas no corresponden a los días</Message.Item>
                </Message.List>
            </Message>
        )
    }

    return (
        transferencias.all.length || transferencias.fetching
            ? <Segment loading={transferencias.fetching}>
                <Statistic.Group widths="one">
                    <Statistic>
                        <Statistic.Label>Transferencia exitosa realizada por</Statistic.Label>
                        <Statistic.Label>{login.user.nombre}</Statistic.Label>
                        <Statistic.Value>
                            <Icon name='exchange' color='green'/>
                            {transferencias.all.length}
                        </Statistic.Value>
                        {transferencias.all.length > 1 ?
                            <Statistic.Label>Servicios</Statistic.Label> : <Statistic.Label>Servicio</Statistic.Label> 
                        }
                    </Statistic>
                </Statistic.Group>
            </Segment>
            : <Segment/>
    )
}

Resultados.propTypes = {
    transferencias: PropTypes.object.isRequired
}

export default Resultados