import React from "react";
import { Segment, Tab } from "semantic-ui-react";

import IndicadoresGeneral from "./IndicadoresGeneral";
import IndicadoresSuma from "./IndicadoresSuma";

const IndicadoresMonitor = ({
  stats,
  confirmados,
  sinConfirmarSuma,
  terminados,
}) => {
  const panes = [
    {
      menuItem: "Indicadores Suma",
      render: () => (
        <IndicadoresSuma
          suma={stats?.suma}
          confirmados={confirmados}
          sinConfirmar={sinConfirmarSuma}
          terminados={terminados}
        />
      ),
    },
    {
      menuItem: "Indicadores General",
      render: () => (
        <IndicadoresGeneral
          suma={stats?.suma}
          confirmados={confirmados}
          sinConfirmar={sinConfirmarSuma}
          terminados={terminados}
          aliados={stats.aliados}
        />
      ),
    },
  ];

  return (
    <Segment textAlign="center" color="orange">
      <Tab
        menu={{ color: "orange", pointing: true, attached: true }}
        panes={panes}
        defaultActiveIndex={0}
      />
    </Segment>
  );
};

export default IndicadoresMonitor;
