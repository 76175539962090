import React from "react";
import { Statistic, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";

import { porcentaje } from "../../helpers";

const IndicadoresSuma = ({ suma, confirmados, sinConfirmar, terminados }) => {
  const total = suma + terminados;

  return (
    <Segment textAlign="center">
      <Statistic size="mini">
        <Statistic.Label>Total</Statistic.Label>
        {total ? (
          <Statistic.Value>{total}</Statistic.Value>
        ) : (
          <Statistic.Value>0</Statistic.Value>
        )}
      </Statistic>
      <Statistic size="mini">
        <Statistic.Label>Confirmados</Statistic.Label>
        {confirmados ? (
          <Statistic.Value>
            {confirmados} ({porcentaje(confirmados, suma)}%)
          </Statistic.Value>
        ) : (
          <Statistic.Value>0 (0%)</Statistic.Value>
        )}
      </Statistic>
      <Statistic size="mini">
        <Statistic.Label>Sin confirmar</Statistic.Label>
        {sinConfirmar ? (
          <Statistic.Value>
            {sinConfirmar} ({porcentaje(sinConfirmar, suma)}%)
          </Statistic.Value>
        ) : (
          <Statistic.Value>0 (0%)</Statistic.Value>
        )}
      </Statistic>
      <Statistic size="mini">
        <Statistic.Label>Finalizados</Statistic.Label>
        {terminados ? (
          <Statistic.Value>
            {terminados} ({porcentaje(terminados, suma)}%)
          </Statistic.Value>
        ) : (
          <Statistic.Value>0 (0%)</Statistic.Value>
        )}
      </Statistic>
    </Segment>
  );
};

IndicadoresSuma.propTypes = {
  confirmados: PropTypes.number,
  sinConfirmar: PropTypes.number,
  terminados: PropTypes.number,
};

export default IndicadoresSuma;
