import React, { Component } from "react";
import { Tab, Container } from "semantic-ui-react";

import ContactoInterno from "./ContactoInterno";
import ContactoExterno from "./ContactoExterno";


class CrearContacto extends Component {
    constructor(props){
        super(props)

        this.initialValues = ({

        })

        this.state = ({
            ...this.initialValues
        })
    }

  render() {
    const panes = [
        { menuItem: 'Crear contato interno', render: () => 
        <Tab.Pane>
            <ContactoInterno />
        </Tab.Pane> 
        },
        { menuItem: 'Crear contacto externo', render: () => 
        <Tab.Pane>
            <ContactoExterno />
        </Tab.Pane> 
        },
      ]

    return (
        <Container fluid style={{ padding: "10px" }}>
            <h3>Crear nuevo contacto para cliente</h3>
            <Tab panes={panes} />
        </Container>
    );
  }
}

export default CrearContacto;