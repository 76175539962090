import {
    REQUEST_APOYOS,
    REQUEST_APOYOS_SUCCESS,
    REQUEST_APOYOS_FAILED,
    REQUEST_CREATE_APOYO,
    REQUEST_CREATE_APOYO_SUCCESS,
    REQUEST_CREATE_APOYO_FAILED,
} from "../constants/action_types";

export const requestApoyos = (params) => {
    return {
        type: REQUEST_APOYOS,
        payload: { params }
    }
}

export const onRequestApoyosSuccess = (response) => {
    return {
        type: REQUEST_APOYOS_SUCCESS,
        payload: response
    }
}

export const onRequestApoyosFailed = (response) => {
    return {
        type: REQUEST_APOYOS_FAILED,
        payload: response
    }
}

export const requestCreateApoyo = (params) => {
    return {
        type: REQUEST_CREATE_APOYO,
        payload: { params }
    }
}

export const onRequestCreateApoyoSuccess = (response) => {
    return {
        type: REQUEST_CREATE_APOYO_SUCCESS,
        payload: response
    }
}

export const onRequestCreateApoyoFailed = (response) => {
    return {
        type: REQUEST_CREATE_APOYO_FAILED,
        payload: response
    }
}