import React, { Component } from "react";
import { Label, Icon, Button } from "semantic-ui-react";
import axios from "axios";
import { ENDPOINT_WHATSAPP } from "../constants/endpoints";

class Counter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      successMessage: false,
      seguimiento: 0,
    };
  }

  sendMessage = () => {
    const nombreOperador = this.props.servicio.nombreOperador;
    const numeroOperador = this.props.servicio.celularOperador;
    const idOperador = this.props.servicio.idOperador;
    const tiempoInicial = this.props.servicio.tiempoInicial.split(" ");
    const bitacoraId = this.props.servicio.id;
    const numeroSoporte = 3311040101;

    const local_storage = sessionStorage.getItem("usuario");
    let userId = JSON.parse(local_storage).id;

    const params = [
      `usuario_id =${userId}`,
      `bitacora_id=${bitacoraId}`,
      `chofer_id=${idOperador}`,
      `asunto=Bitácora sin confirmar`,
      `mensaje=${nombreOperador} tienes una bitácora sin confirmar que comienza a las ${tiempoInicial[1]}.Es urgente que confirmes este servicio desde la aplicacion SUMA drivers desde tu celular, si tienes algún problema con tu aplicación favor de contactar al área de soporte al número ${numeroSoporte}.`,
      //`movil=3311040101`,
      `movil=${numeroOperador}`,
    ];

    const url =
      `${ENDPOINT_WHATSAPP}` + (params.length ? `?${params.join("&")}` : "");

    axios
      .post(url)
      .then((response) => {
        if (response.data.success === true) {
          return this.setState({
            successMessage: response.data.success,
            seguimiento: response.data.seguimiento,
          });
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  render() {
    const { successMessage, seguimiento } = this.state;

    return (
      <Button.Group>
        {!successMessage && (
          <Button
            style={{ fontSize: "13px" }}
            icon
            compact
            color="orange"
            size="mini"
            onClick={this.sendMessage}
          >
            <Icon name="whatsapp" />
            Enviar mensaje al operador
          </Button>
        )}
        {successMessage === true && (
          <Button as="div" labelPosition="right" onClick={this.sendMessage}>
            <Button
              style={{ fontSize: "13px" }}
              icon
              compact
              color="orange"
              size="mini"
            >
              <Icon name="whatsapp" />
              Mensajes enviados
            </Button>
            <Label as="a" basic pointing="left" style={{ color: "#808080" }}>
              {seguimiento}
            </Label>
          </Button>
        )}
      </Button.Group>
    );
  }
}
export default Counter;

/*
axios.post(url)

                .then(response => {

                    if(response.data.success === true){
                        const labels = this.setState((state) => {
                            return {counter: state.counter + 1};
                        });
                        return labels
                    }

                    if(response.data.success === false){
                        const labels = this.setState((state) => {
                            return {counter: state.counter = 0};
                        });
                        return labels
                    }
                })
                .catch(err => {
                console.log('Error: ', err);
            })
*/

/*
<Label.Group>
                {
                    !successMessage &&
                    <Label style={{cursor: 'pointer'}} color='orange' onClick={ this.sendMessage }>
                        <Icon name='whatsapp' />
                        Enviar mensaje al operador
                    </Label>
                }
                {
                    successMessage === true &&
                    <Label style={{cursor: 'pointer'}} color='orange' onClick={ this.sendMessage }>
                        <Icon name='whatsapp' />
                        Mensajes enviados
                        <Label.Detail>{seguimiento}</Label.Detail>
                    </Label>
                }
            </Label.Group>
*/
