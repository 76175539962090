import React, { Component } from "react";
import { Container, Divider, Tab } from "semantic-ui-react";
import ActivarEncuesta from "./ActivarEncuesta";
import LinkForm from "./LinkForm";
import ResultadosForm from "./ResultadosForm";

class Encuestas extends Component {
    constructor(props){
        super(props)

        this.state = ({
            
        })
    }

  render() {
    const panes = [
        { menuItem: 'Crear link para encuesta', render: () => 
        <Tab.Pane>
            <LinkForm />
        </Tab.Pane> 
        },
        { menuItem: 'Activar encuesta por QR', render: () => 
        <Tab.Pane>
            <ActivarEncuesta />
        </Tab.Pane> 
        },
        { menuItem: 'Resultados de encuestas', render: () => 
        <Tab.Pane>
            <ResultadosForm />
        </Tab.Pane>
        },
        { menuItem: 'Módulo de Encuestas - Guía', render: () => 
        <Tab.Pane>
            <h3>Guía para generar o activar una encuesta y obtener sus resultados</h3>
            <ul>
                <li style={{textAlign: 'left'}}>
                    <h4>Genera un link para encuesta directa</h4>
                    <p>- Ingresa a la sección <strong>Crear link</strong>.</p>
                    <p>- Selecciona el cliente a quien necesitas aplicar la encuesta.</p>
                    <p>- Da clic en el botón <strong>Crear link</strong>.</p>
                    <p>- Copia el link y compartelo con el cliente.</p>
                    <p><strong>NOTA:</strong> Este link solo es necesario generarlo en caso de aplicar la encuesta fuera de la aplicación web <strong> Servicio Evaluacion de Operadores</strong> que se utiliza mediante el QR colocado en los asientos de las unidades.</p>
                </li>
                <Divider />
                <li style={{textAlign: 'left'}}>
                    <h4>Activar encuesta para aplicarla mediante el código QR en los asientos de las unidades</h4>
                    <p>- Ingresa a la sección <strong>Activar encuesta</strong>.</p>
                    <p>- Selecciona el cliente en donde se aplicará la encuesta.</p>
                    <p>- Da clic en el botón <strong>Activar</strong>.</p>
                    <p>- Notifica al cliente que la encuesta estará activa durante el trayecto de las rutas.</p>
                    <p>- Una vez terminado el tiempo de aplicación de la encuesta ingresa nuevamente a la sección <strong>Activar encuesta</strong>, selecciona al cliente y da clic en el botón <strong>Desactivar</strong>.</p>
                    <p><strong>NOTA: </strong>Es importante que recuerdes desactivar la encuesta de lo contrario se mostrará la opción para contestar dentro de la aplicación.</p>
                </li>
                <Divider />
                <li style={{textAlign: 'left'}}>
                    <h4>Descargar resultados de encuestas</h4>
                    <p>- Ingresa a la sección <strong>Resultados de encuestas</strong>.</p>
                    <p>- Selecciona el nombre del cliente y el rango de fecha que quieres consultar y da clic en el botón Ver resultados.</p>
                    <p>- Da clic en el botón <strong>Descargar resultados</strong>.</p>
                    <p><strong>NOTA: </strong>Será un archivo en formato Excel.</p>
                </li> 
            </ul>
        </Tab.Pane>
        },
      ]
    return (
      <Container fluid style={{ padding: "10px" }}>
        <Tab panes={panes} />
      </Container>
    );
  }
}

export default Encuestas;
