import React from 'react'
import moment from 'moment'
import _ from "lodash";
import axios from 'axios';
import { Segment, Form, Button, TextArea, Statistic, Icon, Message, Radio } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import BaseComponent from "../BaseComponent";
import { ENDPOINT_OPERADORES_EXCEPCIONES } from '../../constants/endpoints'

import {
  extraerOpcionesProveedores,
  extraerOpcionesOperadores,
  extraerOpcionesOperadoresExcepciones,
  extraerOpcionesClientes,
  extraerOpcionesRutas,
  extraerOpcionesEstructuras,
  extraerIniciales
} from '../../helpers'

const optionsUnidades = [
  { key: '1', text: 'Autobus', value: 4 },
  { key: '2', text: 'Automovil', value: 5 },
  { key: '3', text: 'Camión chico', value: 6 },
  { key: '4', text: 'Camión grande', value: 3 },
  { key: '5', text: 'Camioneta', value: 1 },
  { key: '6', text: 'Minivan', value: 7 },
  { key: '7', text: 'Sprinter', value: 2 },
]


class FormaProgramarExcepcionServicio extends BaseComponent {
  constructor(props) {
    super(props)
    
    this.initialValues = {
      fecha: moment().format("YYYY-MM-DD"),
      hora_inicio: "00:00:00",
      hora_final: "00:00:00",
      proveedor: -1,
      cliente: -1,
      ruta: -1,
      estructura: -1,
      operador: -1,
      motivo: '',
      usuarioId: 0,
      fechaValidada: null,
      tipo_unidad: null,
      tipo_bitacora: 1,
      requerido: true,
      tipoUnidadRuta: null,
      tipoUnidadInfo: null,
      idTipoUnidad: -1,
      textTipoUnidad: '',
      mostrarMensaje: false
    }

    this.state = {
      ...this.initialValues
    }
  }

  componentDidMount() {
    this.props.requestClientes(['activo=true', 'id_categoria=1', 'or(id=5)', 'sort(+nombre_empresa)'])

    //this.props.requestClientes(['activo=true', 'id_categoria=8', 'or(id=1)', 'sort(+empresa)'])

    this.props.requestProveedores(['activo=true', 'id_categoria=8', 'or(id=1)', 'sort(+empresa)']);

    this.props.requestOperadores(['activo=true', 'sort(+nombre)'])
  }

  getOperadoresExepciones = (fecha, tipoUnidad) => {
    const formatoFecha = new Date(fecha).toLocaleDateString().split("/").reverse().join("/")
    const url = `${ENDPOINT_OPERADORES_EXCEPCIONES}?tipoUnidad=${tipoUnidad}&fecha=${formatoFecha}`;

    axios.get(url)
    .then((response) => {
      if (response.status === 200) {
        if (response.data.success === true) {

          const respuesta = response.data.data
          respuesta.sort( (a, b) => {
            if (a.NOMBRE.toLowerCase() < b.NOMBRE.toLowerCase()) {
              return -1;
            }
            if (a.NOMBRE.toLowerCase() > b.NOMBRE.toLowerCase()) {
              return 1;
            }
            return 0;
          });

          return this.setState({
            operadoresExcepciones: respuesta,
          });
        } else {
          console.log('Error');
        }
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }

  triggerClienteChange = (event, data) => {

    const { invalidarRutas, invalidarEstructuras, requestRutas } = this.props

    invalidarRutas()
    invalidarEstructuras()

    this.setState({
      ruta: -1,
      estructura: -1
    })

    requestRutas([`id_cliente=${data.value}`, 'sort(+nombre)', 'activa=true'])

    this.setState({ invalidDays: this.invalidDays })
    this.setState({ ...this.days })

    this.onChangeValue(event, data)
    this.setState({
      hora_inicio: "00:00:00",
      hora_final: "00:00:00",
      proveedor: -1,
      ruta: -1,
      estructura: -1,
      operador: -1,
      motivo: '',
      tipo_unidad: null,
      tipo_bitacora: 1,
      requerido: true,
      tipoUnidadRuta: null,
      tipoUnidadInfo: null,
      idTipoUnidad: -1,
      textTipoUnidad: ''
    })
  }

  triggerRutaChange = (event, data) => {

    this.props.invalidarEstructuras()

    this.setState({
      estructura: -1
    })

    this.props.requestEstructuras([`id_ruta=${data.value}`, 'sort(+hora_inicio)', 'activa=true'])

    this.setState({ invalidDays: this.invalidDays })
    this.setState({ ...this.days })

    this.onChangeValue(event, data)
    this.setState({
      hora_inicio: "00:00:00",
      hora_final: "00:00:00",
      proveedor: -1,
      estructura: -1,
      operador: -1,
      motivo: '',
      tipo_unidad: null,
      tipo_bitacora: 1,
      requerido: true,
      tipoUnidadRuta: null,
      tipoUnidadInfo: null,
      idTipoUnidad: -1,
      textTipoUnidad: ''
    })
  }

  triggerProveedorChange = (event, data) => {
    this.onChangeValue(event, data);

    this.setState({
      operador: -1,
    });

    if (data.value !== 1) {
      return this.props.requestOperadoresSubcontratados(['activo=true', 'sort(+nombre)', `id_proveedor=${data.value}`])
    }

    if ((this.state.proveedor === 1 || data.value === 1) && 
    (this.state.idTipoUnidad === -1 && this.state.tipo_unidad !== null)) {
      return this.getOperadoresExepciones(this.state.fecha, this.state.tipo_unidad)
    }

    if ((this.state.proveedor === 1 || data.value === 1) && 
      (this.state.idTipoUnidad !== -1 && this.state.tipo_unidad === null)) {
      return this.getOperadoresExepciones(this.state.fecha, this.state.idTipoUnidad)
    }
  }

  triggerEstructuraChange = (event, data) => {

    const { estructuras } = this.props

    const estructura = estructuras.all.filter(function (e) {
      return e.id === data.value
    })

    optionsUnidades.map(option => {
      if(option.text === estructura[0].tipoUnidad){
        return this.setState({
          idTipoUnidad: option.value,
          textTipoUnidad: option.text,
        })
      }
    })

    this.setState({
      hora_inicio: estructura[0].horaInicio.substr(11, 5),
      hora_final: estructura[0].horaFin.substr(11, 5),
      tipoUnidadInfo: estructura[0].tipo,
      tipoUnidadRuta: estructura[0].tipoUnidad,
      show: true
    })

    this.onChangeValue(event, data)
  }

  onSubmit = () => {

    const user = this.props.login.user.id
    const desde = moment().subtract(72, "h").format("YYYY-MM-DD");
    const hasta = moment().add(1, 'months').format("YYYY-MM-DD");
    const validarFecha = moment(this.state.fecha).isBetween(desde, hasta);

    const fechaValidada = validarFecha === true ? true : false

    const {
      fecha,
      hora_inicio,
      hora_final,
      proveedor,
      estructura,
      operador,
      motivo,
      tipo_bitacora,
      tipo_unidad,
      requerido,
      idTipoUnidad,
      //cubre
    } = this.state

    const payload = {
      fecha: fecha,
      id_estructura: estructura, // Unidad origen y tipo unidad origen
      id_proveedor: proveedor,
      hora_inicio: hora_inicio + ":00",
      hora_final: hora_final + ":00",
      id_operador: operador,
      motivo: `${motivo} - ${extraerIniciales(this.props.login.user.nombre)}`,
      tipo_bitacora: tipo_bitacora, // Nueva
      usuarioId: user,
      tipo_unidad: tipo_unidad ? tipo_unidad : idTipoUnidad, // Nueva
      requerido: requerido, // Nueva
      //cubre: cubre // Nueva
    }

    if ((fechaValidada === false && user === 3690) || fechaValidada === true) { // Permiso para Isma
      this.setState({ 
        fechaValidada: true, 
        motivo: motivo, 
        tipo_unidad: tipo_unidad, 
        usuarioId: user, 
        requerido: requerido, 
        //cubre: cubre 
      })
      this.props.excepcionServicio(payload)
      this.setState({show: false, mostrarMensaje: true})
    } else {
      this.setState({ fechaValidada: false })
    }

    // if (fechaValidada === true) {
    //   this.setState({ 
    //     fechaValidada: true, 
    //     motivo: motivo, 
    //     tipo_unidad: tipo_unidad, 
    //     usuarioId: user, 
    //     requerido: requerido, 
    //     //cubre: cubre 
    //   })
    //   this.props.excepcionServicio(payload)
    //   this.setState({show: false, mostrarMensaje: true})
    // } else {
    //   this.setState({ fechaValidada: false })
    // }

    setTimeout(() => this.setState({...this.initialValues }), 15000);
  }

  onChangeVal = (event, data) => {
    if (data) {
      switch (data.type) {
        default:
          this.setState({ 
            [data.name]: data.value, 
            proveedor: -1, 
            operador: -1, 
            textTipoUnidad: "",
            idTipoUnidad: -1,
            operadoresExcepciones: []
          })
          return
      }
    } else {
      switch (event.target.type) {
        default:
          this.setState({ 
            [event.target.name]: event.target.value, 
            proveedor: -1, 
            operador: -1, 
            textTipoUnidad: "",
            idTipoUnidad: -1,
            operadoresExcepciones: []
          })
          return
      }
    }
  }

  render() {
    const {
      fecha,
      proveedor,
      cliente,
      ruta,
      estructura,
      operador,
      operadoresExcepciones,
      hora_inicio,
      hora_final,
      motivo,
      requerido,
      //cubre,
      tipo_bitacora,
      fechaValidada,
      tipo_unidad,
      usuarioId,
      tipoUnidadInfo,
      tipoUnidadRuta,
      idTipoUnidad,
      textTipoUnidad,
      show,
      mostrarMensaje
    } = this.state

    const {
      clientes,
      rutas,
      estructuras,
      proveedores,
      operadores,
      subcontratados,
      programacion
    } = this.props

    let informacionCompleta = null
    let camposFaltantes = null

    if (programacion.excepcion.error) {
     informacionCompleta = programacion.excepcion.error.response.data.mensaje
     camposFaltantes = programacion.excepcion.error.response.data.detalles
    }

    const opcionesOperadores = (proveedor === 1) ? operadoresExcepciones : subcontratados
console.log(fecha);
    return (
      <Segment color='orange' container="true">
        {
          show === true && estructuras &&  tipoUnidadRuta && tipoUnidadInfo ?
            <Message info>
              <Message.Header>La ruta seleccionada esta creada con los siguientes datos, consideralos al crear la excepción</Message.Header>
              <p style={{fontWeight: "bold"}}>
                Tipo de unidad: <span style={{fontWeight: "200"}}> {tipoUnidadRuta}</span>
                &nbsp; | &nbsp;
                Tipo de servicio: <span style={{fontWeight: "200"}}> {tipoUnidadInfo}</span>
              </p>
            </Message>
            :
            <></>
        }
        <Form form={'formaProgramacion'} onSubmit={this.onSubmit}>
          <Form.Group widths='equal'>
            <Form.Input name='fecha' label='Fecha' type='date' value={fecha} onChange={this.onChangeValue} />
            <Form.Select name='cliente' label='Cliente' fluid search selection loading={clientes.fetching}
              options={clientes.all.map(extraerOpcionesClientes)} value={cliente}
              onChange={this.triggerClienteChange} />

            <Form.Select name='ruta' label='Ruta' fluid search selection loading={rutas.fetching}
              options={rutas.all.map(extraerOpcionesRutas)} value={ruta}
              onChange={this.triggerRutaChange} />
            <Form.Select name='estructura' label='Estructura' fluid search selection
              loading={estructuras.fetching}
              options={estructuras.all.map(extraerOpcionesEstructuras)} value={estructura}
              onChange={this.triggerEstructuraChange} />
          </Form.Group>

          <Form.Group widths='equal'>
            <Form.Input name='hora_inicio' label='Inicio' type='time' value={hora_inicio}
              onChange={this.onChangeValue} />
            <Form.Input name='hora_final' label='Final' type='time' value={hora_final}
              onChange={this.onChangeValue} />
              { tipoUnidadRuta && !tipo_unidad ?
                <Form.Select widths='eight' name='tipo_unidad' label='Tipo de unidad' fluid options={optionsUnidades}
                value={idTipoUnidad}
                placeholder={tipoUnidadRuta}
                onChange={this.onChangeVal} />
                :
                <Form.Select widths='eight' name='tipo_unidad' label='Tipo de unidad' fluid options={optionsUnidades}
                value={tipo_unidad}
                placeholder='Selecciona un tipo de unidad'
                onChange={this.onChangeVal} />
              }
          </Form.Group>

          <Form.Group widths='equal'>
            <Form.Select name='proveedor' label='Proveedor' fluid search selection
              loading={proveedores.fetching}
              options={proveedores.all.map(extraerOpcionesProveedores)} value={proveedor}
              onChange={this.triggerProveedorChange} />

                { proveedor === 1 && operadoresExcepciones ?
                  <Form.Select name='operador' label='Operador' fluid search selection
                  // loading={opcionesOperadores.fetching}
                  options={operadoresExcepciones.map(extraerOpcionesOperadoresExcepciones)} value={operador}
                  onChange={this.onChangeValue} />:<></>
                }
                {
                  proveedor !== 1 && subcontratados ?
                  <Form.Select name='operador' label='Operador' fluid search selection
                  loading={opcionesOperadores.fetching}
                  options={opcionesOperadores.all.map(extraerOpcionesOperadores)} value={operador}
                  onChange={this.onChangeValue} />:<></>
                }
            
            {/* <Form.Select name='operador' label='Operador' fluid search selection
              loading={opcionesOperadores.fetching}
              options={opcionesOperadores.all.map(extraerOpcionesOperadoresExcepciones)} value={operador}
              onChange={this.onChangeValue} /> */}

            <Form.Field required>
              <label>Motivo</label>
              <TextArea label="Motivo" name="motivo" value={motivo} rows={1} onChange={this.onChangeValue}
                required />
            </Form.Field>
          </Form.Group>
          
          <Form.Group style={{ justifyContent: 'space-evenly' }}>
            <Form.Group style={{ flexDirection: 'column' }}>
              <label style={{ fontWeight: 600 }}>Tipo de servicio</label>
              <Form.Field
                control={Radio}
                type='radio'
                label='Sencillo'
                name='tipo_bitacora'
                value={1}
                //value={'Sencillo'}
                checked={tipo_bitacora === 1}
                onChange={this.onChangeValue}
              />
              <Form.Field
                control={Radio}
                type='radio'
                label='Medio'
                name='tipo_bitacora'
                value={2}
                //value={'Medio'}
                checked={tipo_bitacora === 2}
                onChange={this.onChangeValue}
              />
            </Form.Group>
            <Form.Group style={{ flexDirection: 'column' }}>
              <label style={{ fontWeight: 600 }}>Requerido por el cliente</label>
              <Form.Field
                control={Radio}
                type='radio'
                label='Si'
                name='requerido'
                value={true}
                checked={requerido === true}
                onChange={this.onChangeValue}
              />

              <Form.Field
                control={Radio}
                type='radio'
                label='No'
                name='requerido'
                value={false}
                checked={requerido === false}
                onChange={this.onChangeValue}
              />
            </Form.Group>
            {/* <Form.Group style={{ flexDirection: 'column' }}>
              <label style={{ fontWeight: 600 }}>Cubre</label>
              <Form.Field
                control={Radio}
                type='radio'
                label='Si'
                name='cubre'
                value={true}
                checked={cubre === true}
                onChange={this.onChangeValue}
              />
              <Form.Field
                control={Radio}
                type='radio'
                label='No'
                name='cubre'
                value={false}
                checked={cubre === false}
                onChange={this.onChangeValue}
              />
            </Form.Group> */}

            <Form.Field width={8}>
              <label>&nbsp;&nbsp;</label>
              <Button color={"orange"} fluid type="submit" disabled={programacion.excepcion.sending}
                loading={programacion.excepcion.sending}>Programar</Button>
            </Form.Field>
          </Form.Group>
        </Form>

        <hr />

        {
          informacionCompleta !== null ?
            <Message info>
              <Message.Header>
                Excepción no generada
              </Message.Header>
              <p>Llena todos los campos para que la excepción se genere de manera correcta</p>
              <p>Datos faltantes:</p>
              <ul>
                {
                  camposFaltantes.map(function (elemento, index) {
                    return <li key={index}>{elemento}</li>
                  })
                }
              </ul>
            </Message>
            :
            <></>
        }

        {
          fechaValidada === false ?
            <Message error>
              <Message.Header>
                Error en fecha
              </Message.Header>
              <p>Verifica la fecha que estas ingresando</p>
              <ul>
                <li>No puedes crear una excepción con más de 48hrs de atraso</li>
              </ul>
            </Message>
            :
            <></>
        }

        {
          programacion.excepcion.response && 
          programacion.excepcion.response.data && mostrarMensaje === true ?
            <Statistic.Group>
              <Statistic>
                <Statistic.Value>
                  <Icon name='check' color='green' />
                  1
                </Statistic.Value>
                <Statistic.Label>
                  Excepción generada exitosamente
                </Statistic.Label>
              </Statistic>
            </Statistic.Group>:<></>
        }

        {
          programacion.excepcion.response && 
          programacion.excepcion.response.success === false ?
            <Message error>
              <Message.Header>
              {programacion.excepcion.response.details}
              </Message.Header>
              {programacion.excepcion.response.message}
            </Message>
            :
            <></>
        }
      </Segment>
    )
  }
}

FormaProgramarExcepcionServicio.propTypes = {
  clientes: PropTypes.object.isRequired,
  rutas: PropTypes.object.isRequired,
  estructuras: PropTypes.object.isRequired,
  proveedores: PropTypes.object.isRequired,
  operadores: PropTypes.object.isRequired,
  subcontratados: PropTypes.object.isRequired,
  programacion: PropTypes.object.isRequired,
  invalidarRutas: PropTypes.func.isRequired,
  invalidarEstructuras: PropTypes.func.isRequired,
  requestRutas: PropTypes.func.isRequired,
  requestClientes: PropTypes.func.isRequired,
  requestOperadores: PropTypes.func.isRequired,
  requestEstructuras: PropTypes.func.isRequired,
  requestOperadoresSubcontratados: PropTypes.func.isRequired,
  excepcionServicio: PropTypes.func.isRequired,
}

export default FormaProgramarExcepcionServicio
