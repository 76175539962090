import moment from 'moment'
import React from 'react'
import PropTypes from 'prop-types'
import {Statistic, Icon} from 'semantic-ui-react'

const getTiempo = tiempo => (tiempo.format('DD-MM-YYYY h:mm a'))
const getDesde = (tiempo, horas, format = 'h:mm a') => (
    moment(tiempo.toISOString()).subtract(horas, 'h').format(format))
const getHasta = (tiempo, horas, format = 'h:mm a') => (
    moment(tiempo.toISOString()).add(horas, 'h').format(format))

const Reloj = ({tiempo, horasDesde, horasHasta}) => {
    return (
        <Statistic size='tiny'>
            <Statistic.Value className='value'>
                <Icon name='calendar alternate outline' /> {getTiempo(tiempo)}
            </Statistic.Value>
            <Statistic.Label className='label'>De {getDesde(tiempo, horasDesde)} a {getHasta(tiempo, horasHasta)}</Statistic.Label>
        </Statistic>
    )
}

Reloj.propTypes = {
    tiempo: PropTypes.object.isRequired,
    horasDesde: PropTypes.number.isRequired,
    horasHasta: PropTypes.number.isRequired
}

export default Reloj