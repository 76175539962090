// export const API_ADDRESS = "http://tapi.sumaenlinea.mx";
export const API_ADDRESS = "https://api.sumaenlinea.mx";
// export const API_ADDRESS = "https://api-sumaenlinea.ddns.net:81"

export const ENDPOINT_BITACORAS = `${API_ADDRESS}/bitacoras`;
export const ENDPOINT_UPDATE_HORARIO = `${API_ADDRESS}/bitacoras/updateHorario`;
export const ENDPOINT_OPERADORES = `${API_ADDRESS}/operadores`;
export const ENDPOINT_OPERADORES_NO_DISPONIBLES = `${API_ADDRESS}/operadores/no_disponibles`;
export const ENDPOINT_OPERADORES_DISPONIBLES = `${API_ADDRESS}/operadores/disponibles`;
export const ENDPOINT_OPERADORES_EXCEPCIONES = `${API_ADDRESS}/operadores/excepciones`;
export const ENDPOINT_CLIENTES = `${API_ADDRESS}/clientes`;
export const ENDPOINT_COLABORADORES = `${API_ADDRESS}/colaboradores/lista`;
export const ENDPOINT_ESTRUCTURAS = `${API_ADDRESS}/estructuras`;
export const ENDPOINT_SUBCONTRATADOS = `${API_ADDRESS}/subcontratados`;
export const ENDPOINT_PROGRAMAR_SERVICIOS = `${API_ADDRESS}/programar-servicios`;
export const ENDPOINT_PROVEEDORES = `${API_ADDRESS}/proveedores`;
export const ENDPOINT_RUTAS = `${API_ADDRESS}/rutas`;
export const ENDPOINT_TRANSFERENCIAS = `${API_ADDRESS}/transferencias`;
export const ENDPOINT_SERVICIOS_PROGRAMADOS = `${API_ADDRESS}/servicios-programados`;
export const ENDPOINT_UNIDADES = `${API_ADDRESS}/unidades`;
export const ENDPOINT_UNIDADES_NO_DISPONIBLES = `${API_ADDRESS}/unidades/no_disponibles`;
export const ENDPOINT_LOGIN = `${API_ADDRESS}/login`;
export const ENDPOINT_DISPOSITIVOS = `${API_ADDRESS}/dispositivos_r`;
export const ENDPOINT_CAUSAS = `${API_ADDRESS}/causas`;
export const ENDPOINT_EFECTOS = `${API_ADDRESS}/efectos`;
export const ENDPOINT_HORARIOS = `${API_ADDRESS}/obtener-horarios`;
//export const ENDPOINT_HORARIOS_UPDATE = `${API_ADDRESS}/actualizar-horarios`; //V1
export const ENDPOINT_HORARIOS_UPDATE = `${API_ADDRESS}/actualizar-horarios-estructura`; //V2
export const ENDPOINT_PRECIOS_UPDATE = `${API_ADDRESS}/actualizar-precios`;
export const ENDPOINT_DETALLE_PROGRAMADOS = `${API_ADDRESS}/detalle-programados`;
export const ENDPOINT_SANITIZACIONES = `${API_ADDRESS}/sanitizaciones`;
export const ENDPOINT_AUDITORIAS = `${API_ADDRESS}/auditorias`;
export const ENDPOINT_REVISIONES = `${API_ADDRESS}/revisiones`;
export const ENDPOINT_WHATSAPP = `${API_ADDRESS}/bitacoras/sendWhatsapp`;
export const ENDPOINT_LISTA_CONFIRMADOS = `${API_ADDRESS}/bitacoras/confirmacion/app/buscar`;
export const ENDPOINT_VALIDAR_INCIDENTE = `${API_ADDRESS}/bitacoras/incidente/get`;
export const ENDPOINT_UPDATE_INCIDENTE = `${API_ADDRESS}/bitacoras/incidente/update`;
export const ENDPOINT_INCIDENTE_INCLEMENCIAS_CONDICIONES = `${API_ADDRESS}/incidentes/inclemencias_condiciones`;
export const ENDPOINT_CREAR_APOYO = `${API_ADDRESS}/operadores/apoyo/add`;
export const ENDPOINT_APOYO = `${API_ADDRESS}/operadores/apoyo`;
export const ENDPOINT_SERVICIOS_POR_TERMINAR = `${API_ADDRESS}/servicios-programados-por-finalizar`;
export const ENDPOINT_ACTUALIZAR_PRECIOS = `${API_ADDRESS}/estructuras/actualizar/precios`;
export const ENDPOINT_BUSCAR_CONTACTOS = `${API_ADDRESS}/contactos/mostrar`;
export const ENDPOINT_CONTACTOS_AGREGAR = `${API_ADDRESS}/contactos/insertar`;
export const ENDPOINT_ACTUALIZAR_CONTACTOS = `${API_ADDRESS}/contactos/actualizar`;
export const ENDPOINT_ENCUESTA = `${API_ADDRESS}/Encuesta`;
export const ENDPOINT_ENCUESTA_OPERADORES = `${API_ADDRESS}/encuesta/activar`;
export const ENDPOINT_ENCUESTA_REPORTE = `${API_ADDRESS}/encuesta/reporte`;
export const ENDPOINT_REGISTRO_MOVIMIENTOS = `${API_ADDRESS}/registro_movimientos`;
export const ENDPOINT_GET_INCIDENTE_CAUSA = `${API_ADDRESS}/bitacoras/getIncidencias`