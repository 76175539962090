import {connect} from "react-redux";

import {
    requestApoyos,
    requestCreateApoyo,
} from "../../actions/apoyos";

import AdministrarApoyos from "../../components/Apoyos/AdministrarApoyos"
import {requestProveedores} from "../../actions/proveedores";
import {requestOperadores} from "../../actions/operadores";
import {requestUnidades} from "../../actions/unidades";

const mapStateToProps = ({apoyos, login, operadores, proveedores, unidades}) => {
    return {
        apoyos,
        login,
        operadores,
        proveedores,
        unidades,
    }
}

const mapActionsToProps = {
    requestApoyos,
    requestCreateApoyo,
    requestProveedores,
    requestOperadores,
    requestUnidades,
}

export default connect(mapStateToProps, mapActionsToProps)(AdministrarApoyos)